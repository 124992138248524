import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FaCheckCircle, FaPrint, FaExclamationCircle, FaEye } from 'react-icons/fa';
import './style.css'

export default function ViewModal({
  file
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  
  return  (
  <span className="comment-box view-file has-file" onClick={toggle}>
      <FaEye className="check-circle-doc" color='#2572bc' />
      <Modal
        returnFocusAfterClose={false}
        isOpen={modal}
        toggle={toggle}
        className={"modal-xl modal-dialog-centered comments-wrap"}
      >
        <ModalHeader toggle={toggle}>फाइल हेर्नुहोस् </ModalHeader>
        <ModalBody>
        <div className="manual-wrapper">
            <iframe src={file}></iframe>
          </div>
        </ModalBody>
    </Modal>
    </span>
  )
}
