import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import downloadFile from "../../utils/downloadFile";


const FilePreviewModal = ({ filrUrl }) => {

  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen)
  const docName = filrUrl.substr(filrUrl.lastIndexOf('/') + 1);


  return (
    <div>
      <span role="button" onClick={toggleModal} className="h5" style={{ color: '#06f' }}>कागजात हेर्नुहोस</span>
      <Modal isOpen={isOpen} size="lg" toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          कार्यपालिकाबाट अनुमोदन भएको कागजात
        </ModalHeader>
        <ModalBody className="d-flex flex-column">
          <div className="mb-2 d-flex justify-content-end align-items-baseline">
            <a role="button" className="mr-2" href={filrUrl} target="_blank">Open In New Tab</a>
            <button className="btn btn-primary btn-sm" onClick={() => {
              downloadFile(filrUrl, docName)
            }}>Download File</button>
          </div>
          <div className="w-100 h-auto flex-grow-1">
            <iframe className="w-100 h-100" src={filrUrl} />
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default FilePreviewModal
