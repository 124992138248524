import React, { Component } from 'react';
import './style.css';

class Support extends Component {
   
    render() {
        return (
          <div className="manual-wrapper">
            <iframe src="https://docs.google.com/document/d/1XzQm_p68MJ3oPmGKAiU3-_z3wrSLsn1OyBVbzjo3TZI/edit#"></iframe>
          </div>
        );
    }
}

export default (Support);
