import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import * as authAction from '../../actions/authAction';
import logo from '../../assets/images/logo.svg';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import Api from '../../services/api';
import { changeIndexToNepali } from '../../utils';
import { ToastContainer, toast } from 'react-toastify';
import MainLoader from '../../components/MainLoader';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    NavLink,
} from 'reactstrap';
import './style.css';
import { compose } from 'redux';

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            fy: null,
            fyList: null,
            error: '',
            dropdown: null,
            isLoading: false,
            isExpire: false,
            expireCode: ''
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);
    }

    async componentDidMount() {
        // let dropdown = await Api.getDropdown();
        this.setState({ isLoading: true });
        let fyList = await Api.getFyList();
        let currentFy = null;
        fyList.response.map(fy => {
            if (fy.current_fiscal_year) {
                currentFy = fy.id;
            }
        });
        this.setState({ fyList: fyList.response, fy: currentFy, isLoading: false });
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onPasswordChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    onFyChange = (e) => {
        this.setState({
            fy: e.target.value
        })
    }

    onClickLogin = async (e) => {
        const { email, password, fy, dropdown } = this.state;
        const { onLogin } = this.props;
        let cred = {
            email,
            password,
            fiscal_year_id: fy,
        };
        this.setState({ isLoading: true });
        if (!fy) {
            toast.error('कृपया आर्थिक वर्ष छान्नुहोस्', {
                position: toast.POSITION.TOP_CENTER,
            });
            this.setState({ isLoading: false });
            return;
        }
        let response = await Api.login(cred);
        this.setState({ isLoading: false });

        if (response.code === 423 && response.response) {
            this.setState({ isExpire: true, expireCode: response.response.id })
        } else if (response.code !== 200) {
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        } else {
            // response.dropdown = dropdown;
            onLogin(cred, response);
        }
    }

    render() {
        const { error, fyList, fy, isLoading } = this.state;
        const { user } = this.props;
        if (this.props.isAuthenticated) {
            return (
                <Redirect to={{
                    pathname: (user.role_level === 'district' || 'qa') ? '/dashboard' : user.role_level === 'palika' ? `/selfassessmentform/${user.gnid}` : '',
                    state: { from: this.props.location, showWelcome: true }
                }} />
            )
        }

        if (this.state.isExpire) {
            return (
                <Redirect to={{
                    pathname: `/reset-password/${this.state.expireCode}`,
                }} />
            )
        }

        return (
            <MainLoader active={isLoading}>
                <div className="login-container">
                    <div className="login-wrapper center-container">
                        {/* <img src={logo} alt="logo" className='login-logo'/> */}
                        <img src={logo} alt="logo" className="nav-logo-user" />
                        <div className="login-header">लगइन</div>
                        <div className="login-form">
                            <ToastContainer />
                            {error && <div className="error-message">{error}</div>}
                            <FormGroup>
                                <Label for="email">प्रयोगकर्ता नाम</Label>
                                <Input type="text" name="email" placeholder="प्रयोगकर्ता नाम" value={this.state.email || ""} onChange={this.onEmailChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">पासवर्ड</Label>
                                <Input type="password" name="password" placeholder="पासवर्ड" value={this.state.password || ""} onChange={this.onPasswordChange} />
                            </FormGroup>
                            {/* <input type="password" name="password" className="login-password" placeholder="पासवर्ड" value={this.state.password || ""} onChange={this.onPasswordChange} /> */}
                            <FormGroup>
                                <Label for="selectFy">आर्थिक वर्ष छान्नुहोस्</Label>
                                <Input type="select" name="selectFy" onChange={this.onFyChange} value={fy}>
                                    <option value="" disabled>आर्थिक वर्ष छान्नुहोस्</option>
                                    {
                                        fyList &&
                                        fyList.map((fy, index) => {
                                            return (
                                                <option key={fy.id} value={fy.id} >{fy.name}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                            {/* <NavLink to="/password/forgot" tag={RRNavLink}>पासवर्ड बिर्सिनुभयो?</NavLink> */}
                            <span></span>
                            <Button className="login-button" onClick={this.onClickLogin}>लगइन</Button>
                        </div>
                    </div>
                </div>
            </MainLoader>
        );
    }
}

const mapStateToProps = ({ auth }) => ({
    initialValues: auth.login,
    isAuthenticated: auth.isAuthenticated,
    user: auth.user
});

const mapDispatchToProps = (dispatch) => ({
    onLogin: (cred, body) => {
        dispatch(authAction.setLoginCred(cred));
        dispatch(authAction.setToken(body.response.api_token));
        dispatch(authAction.setUser(body.response));
        dispatch(authAction.hasLoggedin());
    },
    onLogout: () => {
        dispatch(authAction.logout());
    }
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
export default enhance(LoginContainer);

