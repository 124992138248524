import React, { Component } from "react";

import {
    Radar, RadarChart, PolarGrid, Legend,
    PolarAngleAxis, PolarRadiusAxis
} from 'recharts';

export default class RadarChartComponent extends Component {
    render() {
        const { data } = this.props;
        return (
            <RadarChart outerRadius={150} width={600} height={500} data={data}>
                <PolarGrid />
                <PolarAngleAxis dataKey="question" />
                <PolarRadiusAxis angle={90} domain={[0, 100]} />
                <Radar name="प्राप्ताङ्क" dataKey="percentage" stroke="#0ba711" fill="transparent" fillOpacity={0.6} />
                <Radar name="औसत" dataKey="average" stroke="#f9b077" fill="transparent" fillOpacity={0.6} />
                <Legend />

            </RadarChart>
        );
    }
}

