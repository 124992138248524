import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FaSpinner } from 'react-icons/fa';
import Api from '../../services/api';
import './style.css'
import { useHistory } from 'react-router-dom';


const ResetPassword = () => {

  const [formData, setFormData] = useState({
    current_password: '',
    password: '',
    password_confirmation: '',
  })

  const [error, setError] = useState('')
  const [reqLoading, setReqLoading] = useState(false)

  let code = window.location.pathname.split('/')[2];

  const handleFormChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    const data = { ...formData, [key]: value }
    setFormData(data)
  }

  const onClickSubmit = async () => {
    setReqLoading(true)
    if (formData.current_password == '') {
      setError('Current password is required!')
    } else if (formData.password == '') {
      setError('Password is required!')
    } else if (formData.password_confirmation == '') {
      setError('Confirm password is required!')
    } else {
      const response = await Api.resetExpirePassword(code, formData);

      if (response.code === 200) {
        // toast.success(response.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        setFormData({
          current_password: '',
          password: '',
          password_confirmation: '',
        })
        toast.success('Password has been reset successfully. Login with your new password.', {
          position: toast.POSITION.TOP_CENTER,
        });
        window.location.replace('/login')
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
    setReqLoading(false)
  }

  return (
    <div>
      <div className="reset-password-container">
        <div className="reset-password-wrapper center-container">
          <h2>Password Expired !!!</h2>
          {error && <div className="text-danger mb-2">{error}</div>}
          <div className="reset-password-form">
            <ToastContainer />
            <Row>
              <Col sm={12} >
                <FormGroup>
                  <Label for="name">Current Password</Label>
                  <Input type="password" name="current_password" placeholder="Current Password" value={formData.current_password} onChange={handleFormChange} />
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup>
                  <Label for="email">Password</Label>
                  <Input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleFormChange} />
                </FormGroup>
              </Col>

              <Col sm={12}>
                <FormGroup>
                  <Label for="phone">Confirm Password</Label>
                  <Input type="password" name="password_confirmation" placeholder="Confirm Password" value={formData.password_confirmation} onChange={handleFormChange} />
                </FormGroup>
              </Col>
            </Row>
            <div>
              <h4>Password Security Rules</h4>
              <ul>
                <li>Must be at least 8 characters in length</li>
                <li>Must contain at least one lowercase letter</li>
                <li>Must contain at least one uppercase letter</li>
                <li>Must contain at least one digit</li>
                <li>Must contain a special character</li>
              </ul>
            </div>
            <Button className="reset-password-button" disabled={reqLoading} onClick={onClickSubmit}>Reset &nbsp; {reqLoading && <FaSpinner className="rotating" />}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
