import store from '../store';
import * as authAction from '../actions/authAction';
import history from '../routes/history';

export default class ApiUtil {
    constructor(baseUrl) {
        this.baseUrl = baseUrl;
    }

    static log(url, msg) {
        console.warn(`Error in request to url ${url}`);
        console.warn(msg);
    }

    async get(url, params = {}) {
        let { auth: { token } } = store.getState();
        console.log(token)
        let headers = {};
        if (token) {
            headers.Authorization = headers.Authorization || 'Bearer ' + token;
        }
        headers = {
            ...headers,
            Accept: "application/json",
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
        var esc = encodeURIComponent;
        var query = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        // return fetch(`${this.baseUrl}${url}?${query}`, { 
        //     headers
        // });
        return new Promise(async (resolve, reject) => {
            try {
                let response = await fetch(`${this.baseUrl}${url}?${query}`, {
                    headers
                });
                if (response.ok) {
                    resolve(response);
                } else {
                    ApiUtil.log(response.url, response);
                    response.status === 401 && store.dispatch(authAction.logout()) && history.push('/login');
                    // if (showError) {
                    //     let { message } = await response.json();
                    // }
                    reject(response.statusText);
                }
            } catch (err) {
                console.log(err);
                ApiUtil.log(url, err);
                reject(err);
            }
        });
    }

    async post(url, { headers = {}, body }) {
        let { auth: { token } } = store.getState();
        if (token) {
            headers.Authorization = headers.Authorization || 'Bearer ' + token;
        }
        url = `${this.baseUrl}${url}`;
        let options = {
            headers: {
                ...headers,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body)
        };

        return fetch(url, options);
    }

    async put(url, { headers = {}, body }) {
        let { auth: { token } } = store.getState();
        if (token) {
            headers.Authorization = headers.Authorization || 'Bearer ' + token;
        }
        url = `${this.baseUrl}${url}`;
        let options = {
            headers: {
                ...headers,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify(body)
        };

        return fetch(url, options);
    }

    async delete(url, { headers = {}, body = {} }) {
        let { auth: { token } } = store.getState();
        if (token) {
            headers.Authorization = headers.Authorization || 'Bearer ' + token;
        }
        url = `${this.baseUrl}${url}`;
        let options = {
            headers: {
                ...headers,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
            body: JSON.stringify(body)
        };

        return fetch(url, options);
    }

    async futch(url, opts = {}, onProgress) {
        let { auth: { token } } = store.getState();

        url = `${this.baseUrl}${url}`;
        return new Promise((res, rej) => {
            let xhr = new XMLHttpRequest();
            xhr.open(opts.method || 'get', url);
            for (let k in opts.headers || {})
                xhr.setRequestHeader(k, opts.headers[k]);

            if (token) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
            xhr.onload = e => res(e.target);
            xhr.onerror = rej;
            if (xhr.upload && onProgress)
                xhr.upload.onprogress = (progressEvent) => {
                    const progress = progressEvent.loaded / progressEvent.total;
                    onProgress(progress);
                }; // event.loaded / event.total * 100 ; //event.lengthComputable
            xhr.send(opts.body);
        });
    }
}
