import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import adminIcon from '../../assets/images/admin.png'
import compose from 'recompose/compose';
import * as authAction from '../../actions/authAction';
import { connect } from 'react-redux';
import './style.css';
import '../../containers/style.css';

class MainNav extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      modal: false
    };
    this._renderUserMenu = this._renderUserMenu.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
  _onLogout = () => {
    const { history } = this.props;
    this.props.onLogout();
    history.push('/home');
  }

  _openNewTab = () => {
    let url = 'https://lisa.mofaga.gov.np/backend/files/LISA_Guideline_2078.pdf';
    window.open(url, '_blank');
  }
  _renderUserMenu() {
    const { user, history, fiscal_year_id } = this.props;
    let isAdmin = false;
    let isDistrictAdmin = false;
    if (user.role === 'Palika Admin (Focal Person)') {
      isAdmin = true;
    }
    if (user.role === 'District Admin') {
      isDistrictAdmin = true;
    }
    console.log(fiscal_year_id)
    return (
      <Navbar color="light" light expand="md">
        <NavbarBrand onClick={() => history.push(user.role_level === 'district' ? '/dashboard' : user.role_level === 'qa' ? '/dashboard' : `/selfassessmentform/${user.gnid}`)}><img src={logo} alt="logo" className="nav-logo-user" />
        </NavbarBrand>
        <div style={{ color: '#e12a2a' }}>
          <span style={{ color: '#676767', fontSize: 22 }}>{user.palika_name ? user.palika_name : user.district_name ? 'जि.स.स. ' + user.district_name : 'गुणस्तर परीक्षण - ' + user.name} <br /></span>
          <span style={{ color: '#2572bc' }}>स्थानीय तह संस्थागत क्षमता स्वमूल्याङ्कन<br /></span>
          Local Government Institutional Capacity Self-Assessment (LISA)

        </div>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/dashboard" tag={RRNavLink}>ड्यासबोर्ड</NavLink>
            </NavItem>
            {user.role_level === 'district' ?
              '' :
              <NavItem>
                <NavLink tag={RRNavLink} to={`/selfassessmentform/${user.gnid}`}>फारम</NavLink>
              </NavItem>
            }
            <NavItem>
              <NavLink to="/home" tag={RRNavLink}>नक्सा</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/local/status" tag={RRNavLink}>स्थिति</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/report" tag={RRNavLink}>रिपोर्ट</NavLink>
            </NavItem>
            <UncontrolledDropdown nav inNavbar className="user-nav-wrapper">
              <DropdownToggle nav caret>
                {/* <span className="email-circle">
                    {user.email}
                  </span> */}
                <img src={adminIcon} alt="user" class="rounded-circle"></img>
              </DropdownToggle>
              <DropdownMenu className='custom-drp' right>
                <div className='details-drp'>
                  <div className="role-circle">
                    {user.role}
                  </div>
                  <span className="email-circle">
                    {user.email}
                  </span>
                  <span> (आ.व. {user.fiscal_year_text})</span>
                </div>
                <DropdownItem>
                  <NavLink className="dropdown-list-child" to="/profile/show" tag={RRNavLink}>प्रोफाइल</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <NavLink className="dropdown-list-child" to="/manual" tag={RRNavLink}>म्यानुवल</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem>
                  <NavLink className="dropdown-list-child" to="/support" tag={RRNavLink}>सपोर्ट</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                {(isAdmin) &&
                  <>
                    <DropdownItem>
                      <NavLink className="dropdown-list-child" to="/user" tag={RRNavLink}>प्रयोगकर्ता</NavLink>
                    </DropdownItem  >
                    <DropdownItem divider />
                  </>
                }

                <DropdownItem onClick={this._openNewTab}>
                  <NavLink className="dropdown-list-child">कार्यविधि</NavLink>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={this._onLogout}>
                  <NavLink className="dropdown-list-child">लगआउट</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }

  _renderGeneralMenu() {
    const { history } = this.props;
    return (
      <Navbar color="light" light expand="md">
        <NavbarBrand onClick={() => history.push('/')}><img src={logo} alt="logo" className="nav-logo" />
        </NavbarBrand>
        <div style={{ color: '#e12a2a' }}>
          <span style={{ color: '#2572bc' }}>स्थानीय तह संस्थागत क्षमता स्वमूल्याङ्कन<br /></span>
          Local Government Institutional Capacity Self-Assessment (LISA)
        </div>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink to="/home" tag={RRNavLink}>नक्सा</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/local/status" tag={RRNavLink}>स्थिति</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/report" tag={RRNavLink}>रिपोर्ट</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/support" tag={RRNavLink}>सपोर्ट</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={this.toggleModal}>कार्यविधि</NavLink>
              <Button color="danger" className="uni-menu-item" onClick={() => history.push('/login')}>लगइन</Button>
            </NavItem>
            <Modal style={{ height: '80vh' }} centered size='lg' isOpen={this.state.modal} toggle={this.toggleModal} className='menu-modal'>
              <ModalHeader toggle={this.toggleModal}>कार्यविधि</ModalHeader>
              <ModalBody>
                <iframe style={{ width: '100%', height: '67vh' }} src="https://lisa.mofaga.gov.np/backend/files/LISA_Guideline_2078.pdf"></iframe>
              </ModalBody>
            </Modal>
          </Nav>
        </Collapse>
      </Navbar>

    );
  }

  render() {
    const { isAuthenticated, history } = this.props;
    return (
      isAuthenticated ? this._renderUserMenu() : this._renderGeneralMenu()

    );
  }
}

const mapStateToProps = ({ auth }) => ({
  initialValues: auth.login,
  user: auth.user,
  isAuthenticated: auth.isAuthenticated,
  fiscal_year_id: auth.login.fiscal_year_id
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => {
    dispatch(authAction.logout());
  }
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));


export default enhance(MainNav);