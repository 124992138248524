import React, { Component } from "react";
import './style.css';

export default class GradientLabel extends Component {
  render() {
    return (
      <div className='gradient-wrapper'>
        <span className='left-label'> 0 </span>
        <span className="gradient" />
        <span className='right-label'> 100 </span>
      </div>
    );
  }
}