import { saveAs } from 'file-saver';

const downloadFile = (file, fileName) => {
  if (file && fileName) {
    try {
      saveAs(file, fileName)
    } catch (error) {
      console.log(error)
    }
  }
}

export default downloadFile