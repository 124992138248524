import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import Map from './../../components/Map';
import RadarChart from './../../components/RadarChart';
import Api from '../../services/api';
import { FaCog, FaPaperclip } from 'react-icons/fa';
import { changeIndexToNepali, changeNepaliIndexToEnglish, colorScale } from '../../utils';
import { Line } from 'rc-progress';
import BarGraph from '../../components/BarChart';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ReactTable from "react-table";
import 'react-toastify/dist/ReactToastify.css';
import localMapJson from '../../assets/geoJson/local.json';
import GradientLabel from '../../components/GradientLabel';
import * as modalAction from '../../actions/modalAction';
import MainLoader from '../../components/MainLoader';
import DetailTable from '../../components/Table/DetailTable';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import './style.css';
import ReactExport from "react-export-excel";
import { Helmet } from "react-helmet"
import html2canvas from 'html2canvas';
import { FormGroup } from 'react-bootstrap';
import FilePreviewModal from '../../components/FilePreviewModal';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lg: {},
      data: {
        categories: [],
        geoJson: null,
        confirmModal: false,
      },
      isApproved: false,
      showDocModal: false,
      documents: [],
      isLoading: true,
      total: 0,
      mainCategories: [],
      subCategories: [],
      approvedDate: '',
      approvedFile: '',
      qaScore: '',
      gnId: '',
      newData: [],
      fiscalYear: [],
      currentFiscalYear: '',
      authenticated: false,
      userGnId: 0
    };
    this._renderCategoriesList = this._renderCategoriesList.bind(this);
    this.toggle = this.toggle.bind(this);
    this._getTotalScore = this._getTotalScore.bind(this)
    this._getTotalMarks = this._getTotalMarks.bind(this)
    this._getFiscalyear = this._getFiscalyear.bind(this)
    // this._verifyForm = this._verifyForm.bind(this);

    this.printRef = React.createRef();
  }

  _handleInitialFetching = async () => {
    let { subCategories, lg } = this.state;
    const { user } = this.props;
    let userLoginData = localStorage.getItem('user');
    let isLoggedIn = userLoginData !== undefined ? true : false;
    const { type, id } = this.props.match.params;
    let data, isApproved;
    let geoJson = localMapJson.response.filter(palika => {
      return id === palika.gnid;
    });
    geoJson = geoJson[0];
    lg = geoJson;
    try {
      data = await Api.getIndividualDashboard({
        fiscal_year_id: this.state.currentFiscalYear,
        gnid: id
      });
      isApproved = data.response.status >= 4;

    } catch (e) {
      isApproved = false;
    }
    if (isApproved) {
      let modifiedData = [];
      let totalGlobal = 0;
      let obtainedGlobal = 0;
      let totalMarks = 0

      data.response.detail.map(cat => {
        let totalMarks = parseFloat(cat.marks);
        let obtainedMarks = parseFloat(cat.score);
        modifiedData.push({
          question: cat.category,
          marks: totalMarks,
          score: obtainedMarks,
          percentage: cat.percentage,
          isCat: true,
          isMainCat: true,
        });
        totalGlobal += totalMarks;
        obtainedGlobal += obtainedMarks;
        let sortedQn = cat.questions.sort((a, b) => (parseFloat(changeNepaliIndexToEnglish(a.indicator))) - parseFloat(changeNepaliIndexToEnglish(b.indicator)));
        cat.questions.map(qn => {
          modifiedData.push(qn);
        });

        //set total subcategory score
        cat.subCategories.map(subCategory => {
          if (subCategories[subCategory.name]) {
            subCategories[subCategory.name] = { name: subCategory.name, marks: subCategories[subCategory.name].marks + parseFloat(subCategory.marks), score: subCategories[subCategory.name].score + parseFloat(subCategory.score) }
          } else {
            subCategories[subCategory.name] = { name: subCategory.name, marks: parseFloat(subCategory.marks), score: parseFloat(subCategory.score) }
          }
        });
      });
      let subCategoriesIndexArray = Object.keys(subCategories).map(sCatKey => subCategories[sCatKey]);
      subCategoriesIndexArray.map(sCat => {
        sCat.percentage = (sCat.score / sCat.marks * 100).toFixed(2);
      })

      modifiedData.splice(0, 0, {
        question: "जम्मा",
        marks: totalGlobal,
        score: obtainedGlobal,
        isCat: true
      });

      let columns = this._getColumns(modifiedData);
      let total = modifiedData[0];
      let mainCategories = modifiedData.filter(data => {
        return !!data.isMainCat;
      });
      this.setState({
        data: modifiedData,
        geoJson: geoJson.geometry,
        columns: columns,
        isLoading: false,
        total,
        mainCategories,
        detailCatData: data.response.detail,
        subCategories: subCategoriesIndexArray,
        approvedDate: data.response.status_approved_date,
        approvedFile: data.response.status_approved_file,
        qaScore: data.response.qa_score,
        gnId: id,
        lg,
        isApproved,
        newData: data.response.detail,
        authenticated: userLoginData,
        userGnId: userLoginData ? userLoginData.gnid : ''
      });
      console.log(data.response.detail)
    } else {
      this.setState({
        // data: modifiedData,
        geoJson: geoJson.geometry,
        // columns: columns,
        isApproved: isApproved,
        isLoading: false,
        lg,
        authenticated: userLoginData,
        userGnId: userLoginData ? userLoginData.gnid : ''
      });
    }
  }

  async componentDidMount() {
    const fiscal_year_id = this.props.fiscal_year_id
    let fiscal = localStorage.getItem('fiscalYear')
    this._getFiscalyear();
    await this.setState({
      currentFiscalYear: fiscal_year_id ? fiscal_year_id : fiscal
    })
    this._handleInitialFetching();
  }

  _getFiscalyear = async () => {
    let fyList = await Api.getFyList();
    this.setState({ fiscalYear: fyList.response });
  }


  _renderCategoriesList() {
    const { mainCategories } = this.state;
    return mainCategories.map((category, catIndex) => {
      return (
        <div className='main-list' key={catIndex}>
          <span className='dash-cat-name'><img src={require(`../../assets/images/${catIndex + 1}.svg`)} className="cat-icon" alt="cat-icon" />{category.question}</span>
          {/* <span className='dash-cat-rank'>{changeIndexToNepali(category.rank)}</span> */}
          <span className='dash-cat-score' style={{ color: 'white', background: colorScale(category.percentage) }}>{changeIndexToNepali(category.percentage)}%</span>
        </div>
      )
    })
  }

  _getColumns = (data) => {

    // sn., title, total_mark, obtained_mark , remarks
    //      question, total_mark, obtained_mark, remarks

    let columns = [
      {
        Header: 'क्र.सं.',
        id: 'id',
        maxWidth: 50,
        Cell: (row) => {
          return changeIndexToNepali(row.index + 1);
        }
      },
      {
        id: 'title',
        Header: 'सूचक',
        maxWidth: 1000,
        accessor: d => {
          if (d.isCat) {
            return <b>{d.question}</b>;
          } else {
            return d.question;
          }
        },
      },
      {
        id: 'marks',
        Header: 'पूर्णाङ्क',
        maxWidth: 150,
        accessor: d => {
          return changeIndexToNepali(d.marks);
        },
      },
      {
        id: 'score',
        Header: 'प्राप्ताङ्क',
        maxWidth: 150,
        accessor: d => {
          return changeIndexToNepali(d.score);
        },
      },
      {
        id: 'remarks',
        Header: 'कैैफियत',
        maxWidth: 200,
        accessor: d => {
          return changeIndexToNepali(d.remarks);
        },
      }
    ];
    return columns;
  }

  toggle() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }

  _verifyForm = async () => {
    const { type, id } = this.props.match.params;
    const { user } = this.props;
    this.toggle();
    let response = await Api.verifyForm({
      fiscal_year_id: user.fiscal_year_id,
      gnid: id
    });
    toast.success(response.status, {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  _verifyForm = async () => {
    const { type, id } = this.props.match.params;
    this.toggle();
    let response = await Api.verifyForm({
      fiscal_year_id: this.state.currentFiscalYear,
      gnid: id
    });
    toast.success(response.status, {
      position: toast.POSITION.TOP_CENTER,
    });
  }

  _onFormClick = () => {
    return (
      <Redirect to={{
        pathname: '/selfaccessmentform',
        state: { from: this.props.location }
      }} />
    );
  }

  _openDocumentModal = () => {
    const { onDocumentsShow } = this.props;
    const { id } = this.props.match.params;
    onDocumentsShow({ gnid: id, modalType: 'view', fyId: localStorage.getItem('fiscalYear') });
  }
  _getTotalScore = (data) => {
    let numbers = []
    data.map(item => numbers.push(parseFloat(item.score)))
    return numbers.reduce((a, b) => a + b, 0)
  }
  _getTotalMarks = (data) => {
    let numbers = []
    data.map(item => numbers.push(parseFloat(item.marks)))
    return numbers.reduce((a, b) => a + b, 0)
  }
  _import2Excel = () => {
    const { data, columns } = this.state;
    let filteredData = this._getFilteredData();
    let excelColumns = [
      <ExcelColumn key="question" label="सूचक" value="question" />,
      <ExcelColumn key="marks" label="पूर्णाङ्क" value="marks" />,
      <ExcelColumn key="score" label="प्राप्ताङ्क" value="score" />,
      <ExcelColumn key="remarks" label="कैैफियत" value="remarks" />,
    ];
    return (
      <ExcelFile element={<Button color="primary" style={{ marginRight: 10 }}>डाउनलोड</Button>}>
        <ExcelSheet data={filteredData} name="स्थानीय तह स्थिति टेबल">
          {excelColumns}
        </ExcelSheet>
      </ExcelFile>
    );
  }

  _getFilteredData = () => {
    const { data, searchInput } = this.state;
    const searchKeys = ['question', 'marks', 'score', 'remarks'];
    if (searchInput) {
      let filteredData = data.filter(d => {
        let found = false;
        searchKeys.map(searchKey => {
          if (d[searchKey].toString().includes(searchInput)) found = true;
        });
        return found;
      });
      return filteredData;
    }
    return data;
  }

  _handleDownloadImage = async () => {
    const element = this.printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'RadarChart.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  _handleFiscalYearChange = async (e) => {
    let fiscalYearId = e.target.value
    await this.setState({
      currentFiscalYear: fiscalYearId,
      isLoading: true
    });
    localStorage.setItem('fiscalYear', fiscalYearId);
    await this._handleInitialFetching();
    this.setState({
      isLoading: false
    });
  }

  _renderDashboard = () => {
    const { gnId, currentFiscalYear, fiscalYear, newData, data, geoJson, confirmModal, showDocModal, documents, columns, isApproved, isLoading, total, mainCategories, detailCatData, subCategories, approvedDate, approvedFile, qaScore, lg, authenticated, userGnId } = this.state;
    if (isLoading) {
      return (<div>Loading...</div>);
    }
    if (!isApproved) {
      return (
        <>
          <div className="header">
            <h1 className="heading">{sessionStorage.getItem("lg-name")
              || lg.name}</h1> {/*TODO show , district name from map data*/}
            <div className='d-flex align-items-center'>
              <div className='mt-2'>
                <Input className="" type="select" name="fselect" id="fiscalYearSelect" value={currentFiscalYear} onChange={(e) => this._handleFiscalYearChange(e)}>
                  <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                  {
                    fiscalYear.length > 0 &&
                    fiscalYear.map((fy, index) => {
                      return (
                        <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                      );
                    })
                  }
                </Input>
              </div>
            </div>
          </div>
          <div>फारम सार्वजनिक भएको छैन।</div>
        </>
      );
    }
    return (
      <>
        <Helmet>
          <title>LISA Nepal - Details</title>
        </Helmet>
        <div>
          <div className="header">
            <h1 className="heading">{lg.name}</h1> {/*TODO show , district name from map data*/}
            <div className='d-flex align-items-center'>
              <div className='mt-2 mr-4'>
                <Input className="" type="select" name="fselect" id="fiscalYearSelect" value={currentFiscalYear} onChange={(e) => this._handleFiscalYearChange(e)}>
                  <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                  {
                    fiscalYear.length > 0 &&
                    fiscalYear.map((fy, index) => {
                      return (
                        <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                      );
                    })
                  }
                </Input>
              </div>
              <GradientLabel />
            </div>
          </div>
          <div className="content">
            <Row>
              <Col sm="9">
                <div className="card-layout">
                  <div className="progress-section">
                    <Row>
                      <Col sm="2">
                        <h3 className="sub-heading">जम्मा प्राप्ताङ्क</h3>
                        <h3 className="green-number" style={{ color: colorScale(total.score) }}>{changeIndexToNepali(total.score)}%</h3>
                        <p>औसत</p>
                        {qaScore != 'N/A' && authenticated && gnId == userGnId &&
                          <>
                            <hr />
                            <h3 className="sub-heading">जम्मा गुणस्तर अंक</h3>
                            <h3 className='text-secondary'>{changeIndexToNepali(qaScore)}</h3>
                            <Link to={`/qa-report-public?gnid=${gnId}`} className='btn btn-sm btn-outline-success mt-3'>गुणस्तर रिपोर्ट</Link>
                          </>
                        }

                        {/* <p>औसत</p> */}
                      </Col>
                      <Col sm="10">
                        <div className="progress-box">
                          {subCategories.map((sCat, sIndex) => {
                            return (
                              <Row key={sIndex}>
                                <Col sm="4">
                                  <h3 className="progressbar-text">{sCat.name}</h3>
                                </Col>
                                <Col sm="8">
                                  <div style={{ marginLeft: `${sCat.score}%` }} className="progress-percent">{changeIndexToNepali(sCat.percentage)}%</div>
                                  <Line percent={sCat.percentage} strokeWidth={1} strokeColor={colorScale(sCat.percentage)} />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                        <div className="mt-2 p-4 border rounded">
                          <Row className="align-items-center">
                            <Col sm="6">
                              <h3 className="progressbar-text p-0 m-0">नतिजा सार्वजनिक मिति</h3>
                            </Col>
                            <Col sm="6">
                              <div><h5 className=' p-0 m-0'>{approvedDate && changeIndexToNepali(approvedDate.split(" ")[0])}</h5></div>
                            </Col>
                          </Row>
                          <Row className="pt-2">
                            <Col sm="6">
                              <h3 className="align-items-center progressbar-text p-0 m-0">कार्यपालिकाबाट अनुमोदन भएको कागजात</h3>
                            </Col>
                            <Col sm="6" className="align-items-center">
                              <FilePreviewModal filrUrl={approvedFile} />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* <div className="ranking-section">
                              <Row>
                                  <Col sm="2">
                                      <h3 className="red-number">{changeIndexToNepali(data.rank)} / {changeIndexToNepali(data.total)}</h3>
                                      <p>बरीयतक्रम </p>
                                  </Col>
                                  <Col sm="8">
                                      <div className="ranking-timeline">
                                          <span className="vertical-line"></span>
                                          <span className="circle" style={{ marginLeft: "100px" }}>{changeIndexToNepali(data.rank-3)}  <label>{data.rankNeighbours.prev3.name}</label></span>
                                          <span className="circle" style={{ marginLeft: "200px" }}>{changeIndexToNepali(data.rank-2)} <label>{data.rankNeighbours.prev2.name}</label></span>
                                          <span className="circle" style={{ marginLeft: "300px" }}>{changeIndexToNepali(data.rank-1)} <label>{data.rankNeighbours.prev1.name} </label> </span>
                                          <span className="circle-active" style={{ marginLeft: "400px" }}>{changeIndexToNepali(data.rank)} <label>{data.name} </label></span>
                                          <span className="circle" style={{ marginLeft: "500px" }}>{changeIndexToNepali(data.rank+1)} <label>{data.rankNeighbours.next1.name}  </label></span>
                                          <span className="circle" style={{ marginLeft: "600px" }}>{changeIndexToNepali(data.rank+2)} <label>{data.rankNeighbours.next2.name}  </label></span>
                                          <span className="circle" style={{ marginLeft: "700px" }}>{changeIndexToNepali(data.rank+3)} <label>{data.rankNeighbours.next3.name} </label></span>

                                      </div>
                                  </Col>
                              </Row>
                          </div> */}

              </Col>
              <Col sm="3">
                <div className="card-layout">
                  <h3 className="sub-heading2">नक्सा </h3>
                  <div className="leaflet-container">
                    <Map geoJson={geoJson} />
                  </div>
                  <p className="location">{data.name}</p>
                </div>
              </Col>
            </Row>
            <div className="chart-section">
              <Row>
                <Col sm="4">
                  <div className='cat-list card-layout'>
                    <h3 className="sub-heading"> स्व-मूल्याङ्कन विषयक्षेत्रहरु </h3>
                    <div className='main-list'>
                      <span className='dash-cat-name-title'></span>
                      {/* <span className='dash-cat-rank-title'>बरीयतक्रम</span> */}
                      <span className='dash-cat-score-title'>स्कोर प्रतिशत</span>
                    </div>
                    {
                      this._renderCategoriesList()
                    }
                  </div>
                </Col>
                <Col sm="8">
                  <div className="card-layout">
                    <div className="d-flex justify-content-end">
                      <button type="button" className='btn btn-primary btn-sm' onClick={this._handleDownloadImage}>
                        Save as Image
                      </button>
                    </div>
                    <div ref={this.printRef}>
                      <RadarChart width={800} height={500} data={mainCategories} />
                    </div>
                  </div>

                </Col>
              </Row>
            </div>
            <div className="bar-chart">
              <Row>
                <Col sm="12">
                  <div className="bar-chart">
                    <Row>
                      <Col sm="12">
                        <div className="card-layout bar-graph">
                          <BarGraph data={mainCategories} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="data-details">
              <Row>
                {detailCatData.map((category, catIndex) => {
                  return (

                    <Col sm="4" key={catIndex}>
                      <div className="details-box card-layout">
                        <div className="data-header">
                          <h3 className="data-name">
                            {category.category}
                            {/* <FaPaperclip className="cat-attatchments" onClick={() => this._getCatAttatchments(category.id)} color={'#898989'} /> */}

                          </h3>
                          <div className="rank-score">

                            <div className="score">
                              <h3 className="data-score" style={{ color: colorScale(mainCategories[catIndex].percentage) }}>{changeIndexToNepali(mainCategories[catIndex].percentage)}%</h3>
                              <p> स्कोर प्रतिशत</p>
                            </div>

                          </div>
                        </div>
                        <div className='main-list main-list-header'>
                          <span className='dash-cat-name'>तहहरु</span>
                          <span className='dash-cat-score-title'>स्कोर</span>
                        </div>
                        {category.subCategories.map((sCat, sCatIndex) => (
                          <div className='main-list' key={sCatIndex}>
                            <span className='dash-cat-name'>{sCat.name}</span>
                            <span className='dash-cat-score' style={{ color: 'white', background: colorScale(sCat.score / sCat.marks * 100) }}>{changeIndexToNepali(sCat.score)}</span>
                          </div>
                        ))

                        }
                      </div>

                    </Col>

                  )
                })
                }
                <Modal isOpen={showDocModal} toggle={() => this._toggleCatAttatchmentModal()} className="delete-modal">
                  <ModalHeader>प्रमाणीकरणको आधार</ModalHeader>
                  <ModalBody>
                    {
                      documents.map(document => {
                        return (
                          <a target="_blank" href={document.url} key={document.id}>
                            <p>{document.name}</p>
                          </a>
                        )
                      })
                    }
                  </ModalBody>
                </Modal>

              </Row>


            </div>


          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div className="report-header">
            <h2>रिपोर्ट टेबल</h2>
            <div className="report-controls">
              <Button onClick={() => this._openDocumentModal()} className="btn btn-info mr-2">कागजातहरु हेर्नुहोस</Button>
              {this._import2Excel()}
            </div>
          </div>

          {/* <Accordion allowZeroExpanded preExpanded={[0]}> */}
          <Accordion allowZeroExpanded>
            {newData.map((item, i) =>
              <AccordionItem uuid={i++} >
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div>
                      {changeIndexToNepali(i) + '. ' + item.category}
                    </div>
                    <div>
                      {changeIndexToNepali(item.score)}/
                      {changeIndexToNepali(item.marks)}
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <DetailTable comments={item} />
                </AccordionItemPanel>
              </AccordionItem>
            )}
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <div>
                    कुल
                  </div>
                  <div>
                    {changeIndexToNepali(this._getTotalScore(newData))}/{changeIndexToNepali(this._getTotalMarks(newData))}
                  </div>
                </AccordionItemButton>
              </AccordionItemHeading>
            </AccordionItem>
          </Accordion>
        </div>
      </>
    );
    // if(!data && !data.length > 1) {
    //   return (<div>तपाईको फारम स्विक्रित भएको छैन। क्रिपया फारममा भएका सम्पूर्ण जवाफ साथसाथै कागजातहरु अपलोड गर्नुहोस्।</div>);
    // }
  }

  render() {
    const { data, geoJson, confirmModal, showDocModal, documents, columns, isApproved, isLoading } = this.state;
    return (
      <MainLoader active={isLoading}>
        <section className="dashboard">
          <ToastContainer />

          {this._renderDashboard()}

        </section>
      </MainLoader>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDocumentsShow: (modalProps) => {
    dispatch(modalAction.processModal(modalAction.SHOW_MODAL, modalAction.ModalTypes.SHOW_DOCUMENT, modalProps));
  },
});


const enhance = compose(withRouter, connect(null, mapDispatchToProps));

export default enhance(Dashboard);