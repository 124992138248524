import apiUtil from './apiUtils';
import request from "superagent";
// import jwt_decode from 'jwt-decode';

let baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://lisadev.ktmserver.com/backend/api'
// || 'http://lisadev.ddns.net/backend/api'
const urls = [
    '/questionapi.php', // form data
    '/responsedataentry.php', //submit response,
    '/fileupload.php',
    'optionsapi.php', //previous answers
    'datalg.php' //dashboard
]
let api = new apiUtil(baseUrl);

class Api {
    
    async login(body = {}) {
        try {
            let response = await api.post(
                '/login', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async register(body = {}) {
        try {
            let response = await api.post(
                '/registrations', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async sendResponse(body = {}) {
        try {
            let response = await api.post(
                '/answers', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async submitQA(body = {}) {
        try {
            let response = await api.post(
                '/submit_qa', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async Answers(body = {}) {
        try {
            let response = await api.get(`/optionsapi.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getQuestions(fiscal_year_id, gnid) {
        try {
            let response = await api.get(`/questions`, { fiscal_year_id, gnid });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getQaIndex(fiscal_year_id, gnid) {
        try {
            let response = await api.get(`/questions/qa_index`, { fiscal_year_id, gnid });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getDocumentsList(body = {}) {
        try {
            let response = await api.get(`/documents`, body); //get docs by fy id
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getMap(body = {}) {
        try {
            let response = await api.get(`/mapapi.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getProvinceMap() {
        try {
            let response = await api.get(`/provinces`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getPalikaMap(gnid) {
        try {
            let response = await api.get(`/palikas/${gnid}`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getAllPalikaMap(gnid) {
        try {
            let response = await api.get(`/palikas`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getNepalProvinceData(body = {}) {
        try {
            let response = await api.get(`/frontdatanep.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getLocalData(body = {}) {
        try {
            let response = await api.get(`/frontdatalg.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getProvinceList() {
        try {
            let response = await api.get(`/provinces`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getDistrictList(provinceId) {
        try {
            let response = await api.get(`/districts`, { province_id: provinceId });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getPalikaList(districtId) {
        try {
            let response = await api.get(`/palikas`, { district_id: districtId });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getHomeData(body = {}) {
        try {
            let response = await api.get(`/home`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async uploadDocument(files, fields = {}) {
        const res = await request
            .post(`${baseUrl}/uploads`)
            .attach('path', files[0])
            .field('fiscal_year_id', fields['fiscal_year_id'])
            .field('document_id', fields['document_id'])
            .set('Authorization', 'Bearer ' + fields.user_token)
            .accept('application/json')
        let responseJson = JSON.parse(res.text);
        return responseJson;
        // files.forEach(file => {
        //   req.attach('file', file);
        // });
        // for (var field in fields) {
        //     req.field(field, fields[field]);
        // }
        // req.end(function(err, res){
        //     if (res.ok) {
        //         return JSON.parse(res.text);
        //     }
        // });
    }

    async setDocumentRemarks(body = {}) {
        try {
            let response = await api.post(
                '/update_remarks', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async submitQAFeedback(body = {}) {
        try {
            let response = await api.post(
                '/submit_qa_feedback', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getIndividualDashboard(body = {}) {
        try {
            let response = await api.get(`/answers`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }
    async getDistrictDashboard(f_id, d_id) {
        try {
            let response = await api.get(`/reports/palika_progress`, { fiscal_year_id: f_id, district_id: d_id });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getQADashboard(f_id) {
        try {
            let response = await api.get(`/reports/qa_progress`, { fiscal_year_id: f_id });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getAllComments(f_id, gnid) {
        try {
            let response = await api.get(`/comments/all`, { fiscal_year_id: f_id, gnid: gnid });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getAllCommentsForPrint(f_id, gnid) {
        try {
            let response = await api.get(`/comments/all`, { fiscal_year_id: f_id, gnid: gnid, all: 1 });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }
    async getComments(f_id, q_id, gnid) {
        try {
            let response = await api.get(`/comments`, { fiscal_year_id: f_id, question_id: q_id, gnid: gnid });
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }
    async postComment(body = {}) {
        try {
            let response = await api.post(
                '/comments', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async editComment(body = {}, id) {
        try {
            let response = await api.put(
                `/comments/${id}`, {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async deleteComment(id) {
        try {
            let response = await api.delete(
                `/comments/${id}`, {}
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async postFeedback(body = {}) {
        try {
            let response = await api.post(
                '/submit_qa', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }
    async changeStatus(body = {}) {
        try {
            let response = await api.post(
                '/change_status', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getAllCategories(body = {}) {
        try {
            let response = await api.get(`/categories/all`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getCategories(body = {}) {
        try {
            let response = await api.get(`/categories`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getSubCategories(body = {}) {
        try {
            let response = await api.get(`/sub_categories`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getSingleLocalData(body = {}) {
        try {
            let response = await api.get(`/datasinglelg.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getReportData(body = {}) {
        try {
            let response = await api.get(`/reports/summary`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getProvinceReportData(body = {}) {
        try {
            let response = await api.get(`/reports/download_district_score`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }


    async verifyForm(body = {}) {
        try {
            let response = await api.get(`/dataverification.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async deleteFile(body = {}) {
        try {
            let response = await api.post(
                '/deletefile.php', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getDropdown() {
        try {
            let response = await api.get(`/dropdownapi.php`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async sendRemarks(body = {}) {
        try {
            let response = await api.post(
                '/remarks.php', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getCatDocuments(body = {}) {
        try {
            let response = await api.get(`/documentlist.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async checkSubmit(body = {}) {
        try {
            let response = await api.get(`/submitapi.php`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async getFyList() {
        try {
            let response = await api.get(`/fiscal_years`);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async approveAnswers(body = {}) {
        try {
            let response = await api.post(
                '/uploads/delete', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async removeMov(body = {}) {
        try {
            let response = await api.post(
                '/uploads/delete', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async updateFormStatus(body = {}) {
        try {
            let response = await api.post(
                '/change_status', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async getUsers(body = {}) {
        try {
            let response = await api.get(`/users`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async getUserDetail(id) {
        try {
            let response = await api.get(`/users/${id}`, {});
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async createUser(body = {}) {
        try {
            let response = await api.post(
                '/add_user', {
                body
            }
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async deactivateUser(id) {
        try {
            let response = await api.get(
                `/deactivate_user/${id}`, {}
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async updateUser(user) {
        try {
            let response = await api.post(
                `/update_user/${user.id}`, {
                body: user
            },
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProfile(body = {}) {
        try {
            let response = await api.post(
                `/update_profile`, {
                body
            },
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async getProfile(body = {}) {
        try {
            let response = await api.get(
                `/profile`,
                body
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async changeOwnPassword(body = {}) {
        try {
            let response = await api.post(
                `/change_password`, {
                body
            },
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async updateFormStatusFinal(files, fields = {}) {
        const res = await request
            .post(`${baseUrl}/change_status`)
            .attach('file', files[0])
            .field('fiscal_year_id', fields['fiscal_year_id'])
            .field('status', fields['status'])
            .set('Authorization', 'Bearer ' + fields.user_token)
            .accept('application/json')
        let responseJson = JSON.parse(res.text);
        return responseJson;
    }

    async getPalikaDetailStiti(body = {}) {
        try {
            let response = await api.get(`/reports/palika_progress`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async changePassword(user, body = {}) {
        try {
            let response = await api.post(
                `/change_user_password/${user.id}`, {
                body
            },
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async resetExpirePassword(code, body = {}) {
        try {
            let response = await api.post(
                `/reset_expired_password/${code}`, {
                body
            },
            );
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            console.log(error);
        }
    }

    async getPalikaSuspendedUsers(body = {}) {
        try {
            let response = await api.get(`/suspended_users`, body);
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }

    async restoreUser(id) {
        try {
            let response = await api.get(`/restore_user/${id}`, {});
            let responseJson = await response.json();
            return responseJson;
        } catch (error) {
            throw error;
        }
    }
}

export default new Api();
