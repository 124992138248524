import * as Actions from '../actions/modalAction';

const initialState = {
    activeModal: {
        modalType: null,
        modalProps: {
            showModal: false
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
    case Actions.SHOW_MODAL:
        return { ...state, activeModal: action.modal };
    case Actions.CLOSE_MODAL:
        return initialState;
    default:
        return state;
    }
};
