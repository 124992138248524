import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Input, Button, Row, Col, FormGroup, Tooltip } from 'reactstrap';
import NepalMap from '../../components/NepalMap';
import ReactTable from "react-table";
import './style.css';
import { changeIndexToNepali } from '../../utils';
import classnames from 'classnames';
import MainLoader from '../../components/MainLoader';
import ReactExport from "react-export-excel";
import Api from '../../services/api'
import { connect } from 'react-redux';
import Select from 'react-select';
import { MdSelectAll } from 'react-icons/md';
import { BounceLoader } from 'react-spinners';
import ProvinceReport from './provinceReport';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn; class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      resData: [],
      resProvinceData: [],
      columns: null,
      activeTab: 'local',
      categories: [],
      categoriesOption: [],
      selectedCategories: [],
      selectedValues: '',
      isLoading: true,
      isFilterLoading: false,
      searchInput: '',
      fyId: 0,
      fyList: [],
      provinceId: 0,
      provinceList: [],
      districtId: 0,
      districtList: [],
      tooltipOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  _getCategories = async () => {
    let catRes = await Api.getCategories();
    if (catRes.response) {
      const catOpt = catRes.response.map((sCat) => {
        return ({
          value: sCat.id,
          label: sCat.name
        })
      });
      this.setState({ categories: catRes.response, categoriesOption: catOpt })

    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  async componentDidMount() {
    const { history, fiscal_year_id } = this.props;
    let fiscal = localStorage.getItem('fiscalYear')
    await this.setState({
      fyId: fiscal_year_id ? fiscal_year_id : fiscal
    })
    await this._getCategories();

    let repData = await Api.getReportData({ fiscal_year_id: this.state.fyId });

    this._getProvinceList();
    this._getFiscalyear();

    if (repData.response) {
      this._setReportData(repData.response)
      this.setState({
        resData: repData.response
      })
    }
    // if(repProvinceData.response){
    //   this.setState({
    //     resProvinceData: repProvinceData.response
    //   })
    // }
  }

  _setReportData = (theData) => {
    const { history } = this.props;
    const categories = this.state.categories;
    let data = theData;
    data.map(sData => {
      Object.keys(sData.categories).map((catId) => {
        let category = categories.filter(category => {
          return category.id.toString() == catId;
        });
        let score = sData.categories[catId]
        sData.categories[catId] = {
          name: category[0].name,
          score: score,
          total_marks: category[0].total_marks
        }
      })
    });


    let columns = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    },
    {
      Header: 'स्थानीय तह',
      id: 'name',
      accessor: d => {
        return (
          <span onClick={() => {
            history.push(`/details/local/${d.gnid}`)
            sessionStorage.setItem("lg_name", d.name)
          }} className="lg-name">{d.name}</span>
        );
      },
    },
    {
      Header: 'जिल्ला',
      id: 'district',
      accessor: d => {
        return (
          <span className="lg-name">{d.district}</span>
        );
      },
    }, {
      Header: 'प्रदेश',
      id: 'province',
      accessor: d => {
        return (
          <span className="lg-name">{d.province}</span>
        );
      },
    }
    ];
    let extraColumns = [];
    if (data[0]) {
      extraColumns = Object.keys(data[0].categories).map((categoryId, catIndex) => {
        return (
          {
            id: categoryId,
            Header: `${data[0].categories[categoryId].name} (${changeIndexToNepali(data[0].categories[categoryId].total_marks)})`,
            maxWidth: 150,
            accessor: d => {
              return (d.categories[categoryId].score) ? changeIndexToNepali(d.categories[categoryId].score) : '-';
            },
          }
        );
      });
    }
    let totalColumn = {
      id: 'total',
      Header: 'जम्मा प्राप्ताङ्क',
      maxWidth: 150,
      accessor: d => {
        return changeIndexToNepali(d.score);
      },
    }
    columns = columns.concat(totalColumn);
    columns = columns.concat(extraColumns);
    this.setState(
      {
        data: data,
        columns: columns,
        isLoading: false
      }
    )
  }

  _getFilteredReportData = async (params) => {

    this.setState({
      isFilterLoading: true
    })
    let districtName;
    let provinceName;
    if (params.district_id) {
      districtName = this.state.districtList.find(x => x.id.toString() === params.district_id.toString()).name
    }
    if (params.province_id) {
      provinceName = this.state.provinceList.find(x => x.id.toString() === params.province_id.toString()).name
    }

    let filteredData

    if (params.district_id && params.categories && params.categories.length > 0) {
      filteredData = this.state.resData.filter(x => x.district === districtName).map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).
            filter((key) => params.categories.includes(key)).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    } else if (params.province_id && params.categories && params.categories.length > 0) {
      filteredData = this.state.resData.filter(x => x.province === provinceName).map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).
            filter((key) => params.categories.includes(key)).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    } else if (params.categories && params.categories.length > 0) {
      filteredData = this.state.resData.map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).
            filter((key) => params.categories.includes(key)).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    } else if (params.district_id) {
      filteredData = this.state.resData.filter(x => x.district === districtName).map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    } else if (params.province_id) {
      filteredData = this.state.resData.filter(x => x.province === provinceName).map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    } else {
      filteredData = this.state.resData.map(item => {
        return {
          ...item,
          categories: Object.keys(item.categories).reduce((cur, key) => { return Object.assign(cur, { [key]: item.categories[key].score }) }, {})
        }
      })
    }

    if (filteredData) {
      console.log('FilteredData:', filteredData, this.state.resData)
      this._setReportData(filteredData)
    }

    this.setState({
      isFilterLoading: false
    })
  }

  _getFiscalyear = async () => {
    let fyList = await Api.getFyList();
    this.setState({ fyList: fyList.response });
  }

  _getProvinceList = async () => {
    let provinceListRes = await Api.getProvinceList();

    this.setState({
      provinceList: provinceListRes.response,
    });
  }

  _handleProvinceChange = (e) => {

    let provinceId = e.target.value
    this.setState({
      provinceId: provinceId,
      districtId: 0,
      palikaId: 0,
      palikaList: [],
    });
    this._getDistrictList(provinceId);

    let filterParam
    if (this.state.selectedValues) {
      filterParam = { fiscal_year_id: this.state.fyId, categories: this.state.selectedValues, province_id: provinceId, }
    } else {
      filterParam = { fiscal_year_id: this.state.fyId, province_id: provinceId, }
    }
    this._getFilteredReportData(filterParam);
  }

  _getDistrictList = async (provinceId) => {
    let districtListRes = await Api.getDistrictList(provinceId);

    this.setState({
      districtList: districtListRes.response,
    });
  }

  _handleDistrictChange = (e) => {
    let districtId = e.target.value
    this.setState({
      districtId: districtId,
    });

    let filterParam
    if (this.state.selectedValues) {
      filterParam = { fiscal_year_id: this.state.fyId, categories: this.state.selectedValues, district_id: districtId }
    } else {
      filterParam = { fiscal_year_id: this.state.fyId, district_id: districtId }
    }
    this._getFilteredReportData(filterParam);
  }

  _handleFiscalYearChange = async (e) => {
    let fiscalYearId = e.target.value
    this.setState({
      fyId: fiscalYearId,
      provinceId: 0,
      districtId: 0,
      districtList: [],
      selectedValues: [],
      selectedCategories: [],
      isFilterLoading: true
    });
    let data = await Api.getReportData({ fiscal_year_id: fiscalYearId });
    if (data.response) {
      this._setReportData(data.response)
      this.setState({
        resData: data.response,
        isFilterLoading: false
      })
    }
    localStorage.setItem('fiscalYear', fiscalYearId);
  }

  _handleCategoryChange = (selectedOption) => {
    this.setState({ selectedCategories: selectedOption });

    const selectedValues = selectedOption && selectedOption.length > 0 ? selectedOption.map(x => `${x.value}`) : []
    this.setState({ selectedValues: selectedValues })

    let filterParam
    if (this.state.districtId) {
      filterParam = { fiscal_year_id: this.state.fyId, categories: selectedValues, district_id: this.state.districtId }
    } else if (this.state.provinceId) {
      filterParam = { fiscal_year_id: this.state.fyId, categories: selectedValues, province_id: this.state.provinceId }
    } else {
      filterParam = { fiscal_year_id: this.state.fyId, categories: selectedValues }
    }
    this._getFilteredReportData(filterParam);
  };

  _selectAllCategory = () => {
    this.setState({ selectedCategories: this.state.categoriesOption });
    this._handleCategoryChange(this.state.categoriesOption);
  }

  _import2Excel = () => {
    const { data, columns } = this.state;
    let filteredData = this._getFilteredData();
    filteredData.map(sData => {
      Object.keys(sData.categories).map((categoryId, catIndex) => {
        sData[sData.categories[categoryId].name] = parseFloat(sData.categories[categoryId].score);
      });
    });
    let excelColumns = [
      <ExcelColumn key={'gnid'} label="LGCODE" value="gnid" />,
      <ExcelColumn key={'name'} label="स्थानीय तह" value="name" />,
      <ExcelColumn key={'province'} label="प्रदेश" value="province" />,
      <ExcelColumn key={'district'} label="जिल्ला" value="district" />,
      <ExcelColumn key={'score'} label="जम्मा प्राप्ताङ्क" value="score" />,
    ];
    if (filteredData.length > 0) {
      Object.keys(filteredData[0].categories).map((categoryId, catIndex) => {
        excelColumns.push(<ExcelColumn key={categoryId} label={filteredData[0].categories[categoryId].name} value={filteredData[0].categories[categoryId].name} />)
      });
      return (
        <ExcelFile element={<Button color="primary">डाउनलोड</Button>}>
          <ExcelSheet data={filteredData} name="स्थानीय तह स्कोर टेबल">
            {excelColumns}
          </ExcelSheet>
        </ExcelFile>
      );
    }
  }

  _getFilteredData = () => {
    const { data, searchInput, columns } = this.state;
    const searchKeys = ['name', 'province', 'district', 'categories'];
    if (searchInput) {
      let filteredData = data.filter(d => {
        let found = false;
        searchKeys.map(searchKey => {
          if (searchKey === 'categories') {
            Object.keys(d.categories).map(catKey => {
              if (d.categories[catKey].score.toString().includes(searchInput)) found = true;
            });
          } else {
            if (d[searchKey].toString().includes(searchInput)) found = true;
          }
        });
        return found;
      });
      return filteredData;
    }
    return data;

  }

  _onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
  }

  _toggleToolTip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });

  render() {
    const { data, columns, isLoading, fyId, fyList, provinceId, provinceList, districtList, districtId, selectedCategories, categoriesOption } = this.state;
    return (
      <MainLoader active={isLoading}>
        <ProvinceReport />

        <div className="">
          <div className="content">
            <h1 className="sub-heading">स्थानीय तह स्कोर टेबल</h1>
            <>
              <Row>
                <Col sm={2}>
                  <FormGroup>
                    <Input type="select" name="fselect" id="fiscalYearSelect" value={fyId} onChange={(e) => this._handleFiscalYearChange(e)}>
                      <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                      {
                        fyList.length > 0 &&
                        fyList.map((fy, index) => {
                          return (
                            <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                          );
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup>
                    <Input type="select" placeholder="प्रदेश चयन गर्नुहोस" name="provinceId" id="provinceId" value={provinceId} onChange={(e) => this._handleProvinceChange(e)}>
                      <option value={0} disabled selected>प्रदेश चयन गर्नुहोस</option>
                      {
                        provinceList.length > 0 &&
                        provinceList.map((province, index) => {
                          return (
                            <option key={province.id} value={province.id} >{province.name}</option>
                          );
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup>
                    <Input type="select" placeholder="जिल्ला चयन गर्नुहोस" name="districtId" id="districtId" value={districtId} onChange={(e) => this._handleDistrictChange(e)}>
                      <option value={0} disabled selected>जिल्ला चयन गर्नुहोस</option>
                      {
                        districtList.length > 0 &&
                        districtList.map((district, index) => {
                          return (
                            <option key={district.id} value={district.id} >{district.name}</option>
                          );
                        })
                      }
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={2}>
                  <FormGroup>
                    <div className="select-cat-wrapper">
                      <Select
                        value={selectedCategories}
                        onChange={this._handleCategoryChange}
                        className="select-cat-multi"
                        isMulti={true}
                        options={categoriesOption}
                      />
                      <MdSelectAll className="select-all-icon" id="cat-select-all" onClick={() => this._selectAllCategory()} />
                      <Tooltip placement="top" isOpen={this.state.tooltipOpen} autohide={false} target="cat-select-all" toggle={this._toggleToolTip}>
                        सबै विषयक्षेत्रहरु छान्नुहोस्
                      </Tooltip>
                    </div>
                  </FormGroup>
                </Col>
                <Col sm={3}>
                  <FormGroup>
                    <Input className="" type="text" name="search" placeholder="खोजी" value={this.state.searchInput || ""} onChange={this._onSearchInputChange} />
                  </FormGroup>
                </Col>
                <Col sm={1}>
                  {this._import2Excel()}
                </Col>
              </Row>
            </>
            {this.state.isFilterLoading ? (<div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>) : (
              <>
                {data.length > 0 &&
                  <>
                    <ReactTable
                      data={this._getFilteredData()}
                      columns={columns}
                      defaultPageSize={20}
                      className="-striped -highlight"
                    />
                  </>
                }
              </>
            )}
          </div>
        </div>

      </MainLoader>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  fiscal_year_id: auth.login.fiscal_year_id
});

export default connect(mapStateToProps)(Report);