import React, { useEffect, useState, useMemo } from "react";
import './style.css';
import Api from '../../services/api';
import store from '../../store';
import Table from '../../components/Table';
import MainLoader from '../../components/MainLoader';
import HeaderTable from "../../components/DataTable/HeaderTable";
import PaginationComponent from "../../components/DataTable/Pagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SearchTable from "../../components/DataTable/Search";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { changeIndexToNepali } from '../../utils';
import { Helmet } from "react-helmet";
import { FaSpinner } from "react-icons/fa";


function DistrictDashboard({ user }) {
    const [data, setData] = useState("");
    const [isLoading, setLoadin] = useState(true);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [refresh, setRrefresh] = useState(false);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 20;

    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [gnid, setGnid] = useState('');
    const toggle = () => setModal(!modal);
    const toggle2 = () => setModal2(!modal2);

    const [saveLoading, setSaveLoading] = useState(false)
    const [approveLoading, setApproveLoading] = useState(false)


    const postStatus = async (gnid, status_id) => {

        if (status_id == 2) {
            setApproveLoading(true)
        } else {
            setSaveLoading(true)
        }
        let response = await Api.changeStatus({
            fiscal_year_id: user.fiscal_year_id,
            status: status_id,
            gnid: gnid
        });

        setSaveLoading(false);
        setApproveLoading(false)

        if (response.code === 200) {
            toast.success(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            if (status_id == 2) {
                toggle2()
            } else {
                toggle()
            }
            await _loadDashboard();
        } else if (response.code === 201) {
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            if (status_id == 2) {
                toggle2()
            } else {
                toggle()
            }
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            if (status_id == 2) {
                toggle2()
            } else {
                toggle()
            }
        }
    }


    const headers = [
        { name: "क्र.स.", field: "id", sortable: false },
        { name: "नगरपालिका/ गाउँपालिका", field: "comp", sortable: false },
        { name: "प्रारम्भिक नतिजा तयार हुदैँ", field: "action1", sortable: false },
        { name: "पृष्ठपोषणका लागी पेश", field: "action2", sortable: false },
        { name: "पृष्ठपोषण सम्पन्न", field: "action4", sortable: false },
        { name: "अनुमोदनका लागी पेश", field: "action3", sortable: false },
        { name: "अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक", field: "action5", sortable: false },
        { name: "गुणस्तर परिक्षण", field: "action6", sortable: false },
        { name: "प्राप्तांक", field: "action6", sortable: false },
    ];
    const commentsData = useMemo(() => {
        let computedComments = data;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.name.toLowerCase().includes(search.toLowerCase())
                // ||
                // comment.img.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [data, currentPage, search, sorting]);
    // let state = store.getState();
    // useEffect(() => {
    //     setData(state.auth.user) 
    //   });
    // console.log(data.fiscal_year_id)
    let datas;
    const _loadDashboard = async () => {
        datas = await Api.getDistrictDashboard(user.fiscal_year_id, user.district_id);
        setData(datas.response)
        setLoadin(false)
    }

    useEffect(() => {
        _loadDashboard()
    }, []);

    return (
        <MainLoader active={isLoading}>
            <Helmet>
                <title>LISA Nepal - Dashboard</title>
            </Helmet>
            {data &&
                <div className="card-layout">
                    <ToastContainer />
                    <div className="row">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row table-heading">
                                <div className="col-md-6">
                                    {/* <h1 className="sub-heading">{title}</h1> */}
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse dataTables_search">
                                    <SearchTable
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>
                            {commentsData == '' ? <h4>विवरण उपलब्ध छैन</h4> :
                                <table className="table table-responsive">
                                    <HeaderTable
                                        headers={headers}
                                        onSorting={(field, order) =>
                                            setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {commentsData.map((comment, i) => (
                                            <tr>
                                                <th scope="row" key={comment.id}>
                                                    {++i}
                                                </th>
                                                {console.log("The Data:", comment)}
                                                <td>
                                                    <Link to={`/details/local/${comment.gnid}`}>{comment.name}</Link>
                                                    {comment.status_id >= 1 ? 
                                                    <div><Link to={`/selfassessmentform/${comment.gnid}`} className='btn btn-primary'>
                                                                        फारममा जानुहोस्
                                                                    </Link></div> : ''}
                                                </td>
                                                {comment.statuses.map((item, i) =>
                                                    <td key={i++}>
                                                        {i === 8 && comment.status_id === 4 && user.role_id === 6 ?
                                                            <div>
                                                                <div className="comment-box view-file has-file action-dcc">
                                                                    <button onClick={async () => { await setGnid(comment.gnid); toggle(); }} className='btn btn-primary' >
                                                                        स्वीकृत गर्नुहोस
                                                                    </button>
                                                                    <Link to={`/selfassessmentform/${comment.gnid}`} className='btn btn-primary'>
                                                                        परिक्षण गर्नुहोस
                                                                    </Link>
                                                                    <Modal
                                                                        returnFocusAfterClose={false}
                                                                        isOpen={modal}
                                                                        toggle={toggle}
                                                                        className={"modal-sm modal-dialog-centered comments-wrap"}
                                                                    >
                                                                        <ModalHeader toggle={toggle}>के तपाई गुणस्तर सुनिश्चता स्वीकृत गर्न चाहनुहुन्छ ? </ModalHeader>
                                                                        <ModalFooter>
                                                                            <button className='btn btn-secondary' onClick={toggle}>
                                                                                रद्द गर्नुहोस्
                                                                            </button>
                                                                            <button className='btn btn-primary' disabled={saveLoading} onClick={() => postStatus(gnid, 5)}>
                                                                                स्वीकृत गर्नुहोस &nbsp; {saveLoading && <FaSpinner className="rotating" />}
                                                                            </button>
                                                                        </ModalFooter>
                                                                    </Modal>
                                                                </div>
                                                            </div>
                                                            : i === 3 && comment.status_id === 1 && user.role_id === 6 ?
                                                                <div>
                                                                    <div className="comment-box view-file has-file action-dcc">
                                                                        <button onClick={async () => { await setGnid(comment.gnid); toggle2() }} className='btn btn-primary' >
                                                                            स्वीकृत गर्नुहोस
                                                                        </button>
                                                                        <Link to={`/selfassessmentform/${comment.gnid}`} className='btn btn-primary'>
                                                                            फारममा जानुहोस्
                                                                        </Link>
                                                                        <Modal
                                                                            returnFocusAfterClose={false}
                                                                            isOpen={modal2}
                                                                            toggle={toggle2}
                                                                            className={"modal-sm modal-dialog-centered comments-wrap"}
                                                                        >
                                                                            <ModalHeader toggle={toggle2}>के तपाई पृष्ठपोषण स्वीकृत गर्न चाहनुहुन्छ ? </ModalHeader>
                                                                            <ModalFooter>
                                                                                <button className='btn btn-secondary' onClick={toggle2}>
                                                                                    रद्द गर्नुहोस्
                                                                                </button>
                                                                                <button className='btn btn-primary' disabled={approveLoading} onClick={() => postStatus(gnid, 2)}>
                                                                                    स्वीकृत गर्नुहोस &nbsp; {approveLoading && <FaSpinner className="rotating" />}
                                                                                </button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                                :
                                                                item.date !== '' ?
                                                                    changeIndexToNepali(item.date) :
                                                                    'नभएको'}
                                                    </td>
                                                    
                                                )}
                                                <td>
                                                    {comment.score}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>}
                        </div>
                    </div>

                </div>

            }
        </MainLoader>
    )
}

export default DistrictDashboard;
