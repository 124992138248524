import React, { Component } from "react";
import {
  Router,
  HashRouter,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { PrivateRoute } from "./PrivateRoute";
import Login from "../containers/Login";
import Home from "../containers/Home";
import Report from "../containers/Report";
import { Row, Col } from "reactstrap";
import Detail from "../containers/Detail";
import MainNav from "../components/MainNav";
import Guideline from "../containers/Guideline";
import ForgotPassword from "../containers/ForgotPassword";
import OtpPassword from "../containers/OtpPassword";
import AuthenticatedMainLayout from "../containers/AuthenticatedMainLayout";
import history from "./history";
import Support from "../containers/Support";
import PalikaStatus from "../containers/PalikaStatus";
import api from "../services/api";
import Register from "../containers/Register/Register";
import QaReview from "../containers/Review";
import QaReport from "../containers/QaReport";
import ResetPassword from "../containers/ResetPassword";
import QaReportPublic from "../containers/QaReportPublic";

class Routes extends Component {
  componentDidMount() {
    if (!localStorage.getItem("fiscalYear")) {
      this._getAndSetFiscalYear();
    }
  }

  _getAndSetFiscalYear = async () => {
    const fyList = await api.getFyList();
    let fiscalYear;
    fyList.response.map((fy) => {
      if (fy.current_fiscal_year) {
        fiscalYear = fy.id;
      }
    });
    localStorage.setItem("fiscalYear", fiscalYear);
  };

  render() {
    const { isAuthenticated } = this.props;

    const localStorageData =
      localStorage && localStorage.user ? JSON.parse(localStorage.user) : null;

    return (
      <Router history={history}>
        <div>
          {localStorageData && localStorageData.user.entry_status === 0 ? (
            <p
              className="text-white d-flex justify-content-center p-3"
              style={{ backgroundColor: "red" }}
            >
              <marquee>
                LISA को data प्रविष्ट कार्य हाललाई स्थगित गरिएको छ।
              </marquee>
            </p>
          ) : (
            <></>
          )}
          <div className="layout-container">
            <Row>
              <Col sm="12">
                <MainNav />
                <Switch>
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />
                  <Route
                    exact
                    path="/reset-password/:data"
                    component={ResetPassword}
                  />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/report" component={Report} />
                  <Route exact path="/details/:type/:id" component={Detail} />
                  <Route exact path="/qa-report" component={QaReport} />
                  <Route
                    exact
                    path="/qa-report-public"
                    component={QaReportPublic}
                  />
                  <Route exact path="/qa" component={QaReview} />
                  <Route exact path="/guideline" component={Guideline} />
                  <Route exact path="/local/status" component={PalikaStatus} />
                  <Route
                    exact
                    path="/password/forgot"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/password/reset/otp"
                    component={OtpPassword}
                  />
                  <Route exact path="/support" component={Support} />
                  <PrivateRoute
                    path="/"
                    component={AuthenticatedMainLayout}
                    isAuthenticated={isAuthenticated}
                  />
                </Switch>
              </Col>
            </Row>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
});
export default connect(mapStateToProps)(Routes);
