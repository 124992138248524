import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import * as authAction from '../../actions/authAction';
import Api from '../../services/api';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import './style.css';

class OtpPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
        };

        this.onEmailChange = this.onEmailChange.bind(this);
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onForgotPassword = async (e) => {
        const { email } = this.state;
       
        let response = await Api.forgotPassword({email});
    }

    render() {
        const { email, password, error } = this.state;
        return (
            <div className="login-container">
                <div className="login-wrapper center-container">
                    {/* <img src={logo} alt="logo" className='login-logo'/> */}
                    <div className="login-header">LISA</div>
                    <div className="login-form">
                        {error && <div className="error-message">{error}</div>}
                        <input type="email" name="email" className="login-email" placeholder="प्रयोगकर्ता ससनाम" value={this.state.email || ""} onChange={this.onEmailChange} />
                        <input type="password" name="password" className="login-password" placeholder="पासवर्ड " value={this.state.password || ""} onChange={this.onPasswordChange} />
                        <Button className="login-button" onClick={this.onClickLogin}>लगइन</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default (OtpPassword);
