import React, { useState } from "react";
import Dashboard from "../../containers/Dashboard";

const HeaderTable = ({ headers, onSorting , isOld, type}) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    };
    let today = new Date();
    today = today.getDate()
    return (
        <thead>
            {type === 'nested' ? 
                <>
                <tr>
<th id="fam" class="toplevel" rowSpan="2">सि.न.  </th>
<th id="fam" class="toplevel" rowSpan="2">बिषय क्षेत्र</th>
<th id="rmc" class="toplevel" colspan="4" style={{borderBottom: 'none'}}>सूचक गत प्राप्तांक</th>

<th id="rmc" class="toplevel" rowSpan="2"style={{borderBottom: 'none'}}>कुल प्राप्तांक (औसत)</th>
</tr>
<tr>
<th headers="hue">जम्मा</th>
<th headers="rmc" id="es">प्रकिया सूचक (औसत)</th>
<th headers="rmc" id="fr">नतिजा सूचक (औसत)</th>
<th headers="cel" id="ga">वित्तीय अनुशासनका सूचक (औसत)</th>
</tr>
        </>
            : 
            <tr>
                {headers.map(({ name, field, sortable }) => (
                    <th
                        key={name}
                        onClick={() =>
                            sortable ? onSortingChange(field) : null
                        }
                    >
                        {name}

                        {sortingField && sortingField === field && (
                            <i
                                className={
                                    sortingOrder === "asc"
                                        ? "fa fa-arrow-down"
                                        : "fa fa-arrow-up"
                                }
                            />
                        )}
                    </th>
                ))}
            </tr>  
        }
        </thead>
    );
};

export default HeaderTable;

