import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import * as authAction from '../../actions/authAction';
import logo from '../../assets/images/logo.svg';
import { Button } from 'reactstrap';
import Api from '../../services/api';
import './style.css';

class GuidelineContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "lalitpur@mofaga.gov.np",
            password: "lalitpur",
            error: ''
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onClickLogin = this.onClickLogin.bind(this);
    }

    onEmailChange = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    onPasswordChange = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    onClickLogin = async (e) => {
        const { email, password } = this.state;
        const { onLogin } = this.props;
        // if (!this.isValid()) {
        //     this.setState({ error: 'Incorrect Email or password'})
        //     return;
        // }
        //Call API
        //TODO: validations and other
        let cred = {
            email,
            password
        }
        let response = await Api.login(cred);
        onLogin(cred, response);
    }

    render() {
        const { email, password, error } = this.state;
        if (this.props.isAuthenticated) {
            return (
                <Redirect to={{
                    pathname: '/dashboard',
                    state: { from: this.props.location }
                }} />
            )
        }

        return (
            <div className="login-container">
                <div className="login-wrapper center-container">
                    <img src={logo} alt="logo" className='login-logo'/>
                    <div className="login-header">LISA</div>
                    <div className="login-form">
                        {error && <div className="error-message">{error}</div>}
                        <input type="email" name="email" className="login-email" placeholder="प्रयोगकर्तानाम" value={this.state.email || ""} onChange={this.onEmailChange} />
                        <input type="password" name="password" className="login-password" placeholder="पासवर्ड " value={this.state.password || ""} onChange={this.onPasswordChange} />
                        <Button className="login-button" onClick={this.onClickLogin}>लगइन</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default (GuidelineContainer);
