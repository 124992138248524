
import React from 'react';
import L from 'leaflet';
import { withRouter } from 'react-router-dom';
import { changeIndexToNepali, colorScale } from '../../utils';
import './style.css';
class NepalMap extends React.Component {
  constructor(props) {
    super(props);
    this._renderMap = this._renderMap.bind(this);
  }
  componentDidMount() {
    // create map
    this.map = L.map('map', {
      maxZoom: 9,
      minZoom: 7,
      zoom: 10,
      zoomControl: true,
      layers: [

      ]
    });
    this.layer = L.layerGroup().addTo(this.map);
    var extentControl = L.Control.extend({
      options: {
        position: 'topleft'
      },
      onAdd: function (map) {
        // var llBounds = map.getBounds();
        var container = L.DomUtil.create('div', 'extentControl');
        // $(container).css('background', 'url(i/extent.png) no-repeat 50% 50%').css('width', '26px').css('height', '26px').css('outline', '1px black');
        // $(container).on('click', function () {
        //     map.fitBounds(llBounds);
        // });
        return container;
      }
    })

    this.map.addControl(new extentControl());
    this._renderMap();
  }

  componentWillUnmount() {
    this.layer.clearLayers();
  }

  _renderMap() {

    const { geoJson, type, updateData, orgData, redirect, setColor, activeArea } = this.props;
    const geoJsonStyle = {
      "color": "#ffffff",
      "weight": 2,
      "fillOpacity": 1
    };

    let geojsonLayer = L.geoJSON(geoJson, {
      style: geoJsonStyle,
      onEachFeature: function (feature, layer) {
        let objectId = (type === 'province') ?
          ((feature.properties.province) ? feature.properties.province : 0)
          : (type === 'district') ?
            ((feature.properties.district) ? feature.properties.district : 0)
            : (feature.properties.gnid) ? feature.properties.gnid : 0;
        layer.orgColor = setColor(objectId, type);

        layer.setStyle({
          'fillColor': activeArea === objectId ? '#2572bc' : layer.orgColor
        });

        let layerName = (type === 'province') ?
          ((feature.properties.province) ? `${changeIndexToNepali(feature.properties.province)}` : `प्रदेश`)
          : (type === 'district') ?
            ((feature.properties.district) ? feature.properties.district : ``)
            : (feature.properties.palika) ? feature.properties.palika : ``;
        layer.bindPopup('<h5>' + layerName + '</h5><br />');
        layer.on('mouseover', function () {
          let objectId = (type === 'province') ?
            ((feature.properties.province) ? feature.properties.province : 0)
            : (type === 'district') ?
              ((feature.properties.district) ? feature.properties.district : 0)
              : (feature.properties.gnid) ? feature.properties.gnid : 0;
          updateData(objectId, type, layerName);
          this.openPopup();
          this.setStyle({
            'fillColor': '#2572bc'
          });
        });

        layer.on("add", function () {
          activeArea === layerName && this.openPopup();
        });

        layer.on('mouseout', function () {
          updateData(null, 'nepal', layerName);
          this.closePopup();
          this.setStyle({
            'fillColor': this.orgColor
          });
        });
        layer.on('click', function () {
          let id = (type === 'province' || type === 'nepal') ?
            ((feature.properties.province) ? feature.properties.province : 0)
            : (type === 'district') ?
              ((feature.properties.district) ? feature.properties.district : 0)
              : (feature.properties.gnid) ? feature.properties.gnid : 0;
          redirect(id, type);

        });
      }
    }).addTo(this.layer);
    this.map.fitBounds(geojsonLayer.getBounds());
    this.setState({ geoJson: geojsonLayer })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type || this.props.activeArea !== prevProps.activeArea) {
      this.layer.clearLayers();
      // this.map.removeLayer(prevState.geoJson);
      this._renderMap();
    }
  }

  render() {
    return <div id="map"></div>
  }
}
export default withRouter(NepalMap);