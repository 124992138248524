import React, { Component } from "react";
import { connect } from 'react-redux';
import { FaCloudUploadAlt } from 'react-icons/fa';
import ReactDropzone from "react-dropzone";
import Api from '../../services/api';
import './style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../store';
class FileUpload extends Component {
  onDrop = async (files) => {
    let {onUploaded, user} = this.props;
    let {auth: { token }} = store.getState();
    const { document, toggleLoader } = this.props;
    // POST to a test endpoint for demo purposes
    toggleLoader && toggleLoader();
    let response = await Api.uploadDocument(files, {
        document_id: document.id,
        fiscal_year_id: user.fiscal_year_id,
        user_token: token,
    });
    toggleLoader && toggleLoader();
    onUploaded(document, response);
    
    //TODO Feedback
    
    // if(response.status==="success") {
    //   setAnwerDocs(documentId, response.url);
      
    //   // this.notify("successToast", response)
    // } else {
    //   // this.notify("errorToast")
    // }
  }

  notify(toastId, response) {
    if (toastId==='successToast') {
      toast.success(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  render() {
    return (
        <ReactDropzone
          onDrop={this.onDrop}
          className='upload-drop'
          multiple={false}
        >
          {/* <ToastContainer /> */}
          <div className="upload-box">
            <FaCloudUploadAlt />
            <span className="upload-text">अपलोड गर्नुहोस्</span>
          </div>
        </ReactDropzone>
    );
  }
}

const mapStateToProps = ({auth}) => ({
  user: auth.user
});

export default connect(mapStateToProps)(FileUpload);