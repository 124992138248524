
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const ModalTypes = {
    SHOW_DOCUMENT: 'SHOW_DOCUMENT'
};

export function processModal(actionType, modalType, modalProps ) {
    return (
        {
            type: actionType,
            modal: {
                modalType: modalType,
                modalProps: modalProps
            }
        }
    );
}

export function closeModal(payload) {
    return { type: CLOSE_MODAL };
}