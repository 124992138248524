import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup, Label, Input, } from 'reactstrap';
import NepalMap from '../../components/NepalMap';
// import gapanapa_wgs from '../../assets/geoJson/gapanapa_wgs';
import CircularProgressbar from 'react-circular-progressbar';
// import provinceJson from '../../assets/geoJson/province';
import 'react-circular-progressbar/dist/styles.css';
import classnames from 'classnames';
import { changeIndexToNepali, colorScale } from '../../utils'
import Api from '../../services/api';
import './style.css';
import GradientLabel from '../../components/GradientLabel';
import provinceMapJson from '../../assets/geoJson/province.json';
import districtMapJson from '../../assets/geoJson/district.json';
import localMapJson from '../../assets/geoJson/local.json';
import * as authAction from '../../actions/authAction';
import { Helmet } from 'react-helmet';
import LoadingOverlay from 'react-loading-overlay';
import { BounceLoader } from 'react-spinners';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                categories: []
            },
            activeTab: 'province',
            activeArea: null,
            orgData: null,
            nepalMap: null,
            provinceMap: null,
            districtMap: null,
            localMap: null,
            nepalData: null,
            provinceData: null,
            districtData: null,
            localData: null,
            activeData: null,
            categories: [],
            subCategories: [],
            totalLgFilled: 0,
            isLoading: true,
            fy: null,
            fyId: 0,
            fyList: [],
            provinceId: 0,
            provinceList: [],
            districtId: 0,
            districtList: [],
            palikaId: 0,
            palikaList: []
        };

        this.loadMap = this.loadMap.bind(this);
        this.toggle = this.toggle.bind(this);
        this._updateData = this._updateData.bind(this);
        this._getCategories = this._getCategories.bind(this);
        // this._getNepalProvinceData = this._getNepalProvinceData.bind(this);
        // this._getLocalData = this._getLocalData.bind(this);
        this._redirect = this._redirect.bind(this);
        this._setColor = this._setColor.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    async componentDidMount() {
        let localData = this.props.fiscal_year_id
        let fiscal = localStorage.getItem('fiscalYear');

        await this.setState({
            fyId: localData ? localData : fiscal
        })

        this._getCategories();
        // this._getNepalProvinceData();
        // this._getLocalData();
        this._getHomeData();
        this._getProvinceList();
        this._getTotalData();
        this.loadMap();
        this._getFiscalyear();
    }

    _getTotalData = async () => {
        // const data = await Api.getReportData({fy });
        this.setState({ totalLgFilled: changeIndexToNepali(1) });
    }

    async _getCategories() {
        let catRes = await Api.getCategories();
        let subCatRes = await Api.getSubCategories();
        this.setState({ categories: catRes.response, subCategories: subCatRes.response });
    }

    _getHomeData = async () => {
        const { fiscal_year_id } = this.props;
        let homeRes = await Api.getHomeData({ fiscal_year_id: this.state.fyId });
        let nullData = {
            "name": "",
            "score": "-",
            "totalMarks": 0,
            "categories": {
                "1": "0",
                "2": "0",
                "3": "0",
                "4": "0",
                "5": "0",
                "6": "0",
                "7": "0",
                "8": "0",
                "9": "0",
                "10": "0"
            },
            "subCategories": {
                "1": "0",
                "2": "0",
                "3": "0"
            }
        };
        this.setState({
            nepalData: homeRes.response.nepal,
            provinceData: homeRes.response.province,
            districtData: homeRes.response.district,
            localData: homeRes.response.lg,
            activeData: homeRes.response.nepal,
            nullData,
            isLoading: false,
        });
    }

    // async _getNepalProvinceData() {
    //     let nepProvinceData = await Api.getNepalProvinceData({ fy });
    //     let nullData = {
    //         "name": "",
    //         "score": "-",
    //         "totalMarks": 0,
    //         "categories": {
    //             "1": "0",
    //             "2": "0",
    //             "3": "0",
    //             "4": "0",
    //             "5": "0",
    //             "6": "0",
    //             "7": "0",
    //             "8": "0",
    //             "9": "0",
    //             "10": "0"
    //         },
    //         "subCategories": {
    //             "1": "0",
    //             "2": "0",
    //             "3": "0"
    //         }
    //     };
    //     this.setState({
    //         nepalData: nepProvinceData.nepal,
    //         provinceData: nepProvinceData.province,
    //         activeData: nepProvinceData.nepal,
    //         nullData
    //     });
    // }

    _getHomeDataFilter = async (params) => {
        let homeRes = await Api.getHomeData(params);
        let nullData = {
            "name": "",
            "score": "-",
            "totalMarks": 0,
            "categories": {
                "1": "0",
                "2": "0",
                "3": "0",
                "4": "0",
                "5": "0",
                "6": "0",
                "7": "0",
                "8": "0",
                "9": "0",
                "10": "0"
            },
            "subCategories": {
                "1": "0",
                "2": "0",
                "3": "0"
            }
        };
        this.setState({
            nepalData: homeRes.response.nepal,
            provinceData: homeRes.response.province,
            districtData: homeRes.response.district,
            localData: homeRes.response.lg,
            activeData: homeRes.response.nepal,
            nullData,
            isLoading: false,
        });
    }

    _getProvinceList = async () => {
        let provinceListRes = await Api.getProvinceList();

        this.setState({
            provinceList: provinceListRes.response,
        });
    }

    _handleProvinceChange = async (e) => {
        let provinceId = e.target.value
        this.setState({
            provinceId: provinceId,
            districtId: 0,
            palikaId: 0,
            palikaList: [],
        });
        this._getDistrictList(provinceId);
        const provinceName = await this.state.provinceList.find(x => x.id.toString() === provinceId.toString()).name
        this._updateData(provinceName, 'province', provinceName);
        this.setState({
            activeArea: provinceName
        })
        this.toggle('province');
        // this._getHomeDataFilter({ province_id: provinceId, fiscal_year_id: this.state.fyId });
    }

    _getDistrictList = async (provinceId) => {
        let districtListRes = await Api.getDistrictList(provinceId);

        this.setState({
            districtList: districtListRes.response,
        });
    }

    _handleDistrictChange = async (e) => {
        let districtId = e.target.value
        this.setState({
            districtId: districtId,
            palikaId: 0,
        });
        this._getPalikaList(districtId);
        const districtName = await this.state.districtList.find(x => x.id.toString() === districtId.toString()).name
        this._updateData(districtName, 'district', districtName);
        this.setState({
            activeArea: districtName
        })
        this.toggle('district');
        // this._getHomeDataFilter({ district_id: districtId, fiscal_year_id: this.state.fyId });
    }

    _getPalikaList = async (districtId) => {
        let palikaListRes = await Api.getPalikaList(districtId);

        this.setState({
            palikaList: palikaListRes.response,
        });
    }

    _handlePalikaChange = async (e) => {
        let palikaId = e.target.value
        this.setState({
            palikaId: palikaId,
        });

        const palika = await this.state.palikaList.find((palika) => palika.id.toString() === palikaId.toString())

        this._updateData(palika.gnid, 'local', palika.name);
        this.setState({
            activeArea: palika.name
        })
        this.toggle('local');

        // if (palika && palika.gnid) {
        //     this.props.history.push(
        //         {
        //             pathname: `/details/local/${palika.gnid}`
        //         }
        //     );
        // }
    }

    _handleFiscalYearChange = async (e) => {
        let fiscalYearId = e.target.value
        this.setState({
            fyId: fiscalYearId,
            provinceId: 0,
            districtId: 0,
            districtList: [],
            palikaId: 0,
            palikaList: [],
            isLoading: true
        });
        localStorage.setItem('fiscalYear', fiscalYearId);
        await this._getHomeDataFilter({ fiscal_year_id: fiscalYearId });
        this.setState({
            isLoading: false
        });
    }

    // async _getLocalData() {
    //     let localData = await Api.getLocalData({fy});
    //     //TODo: Fix from server.
    //     let localDataFiltered = JSON.parse(JSON.stringify(localData));
    //     Object.keys(localData.lg).map(lgId=>{
    //         if(Array.isArray(localData.lg[lgId][0].categories)) delete localDataFiltered.lg[lgId];
    //     });
    //     this.setState({
    //         localData: localDataFiltered.lg
    //     });
    // }

    _updateData(objectId, type, layerName) {
        const { provinceData, localData, nepalData, nullData, districtData } = this.state;
        let data;
        switch (type) {
            case 'province':
                let provinceFound = provinceData.find(p => p.name == objectId);
                if (provinceFound) {
                    data = provinceFound;
                } else {
                    data = nullData;
                    data.name = layerName
                }
                this.setState({ activeData: data });
                this.loadMap();
                return;
            case 'district':
                let districtFound = districtData.find(d => d.name == objectId);
                if (districtFound) {
                    data = districtFound;
                } else {
                    data = nullData;
                    data.name = layerName
                }
                this.setState({ activeData: data });
                return;
            case 'local':
                let lgFound = localData.find(lg => lg.gnid == objectId);
                if (lgFound) {
                    data = lgFound;
                } else {
                    data = nullData;
                    data.name = layerName
                }
                this.setState({ activeData: data });
                return;
            default:
                this.setState({ activeData: nepalData });
                return;
        }
    }

    _redirect(objectId, type) {
        const { history } = this.props;
        const { localData, provinceData } = this.state;
        let provinceFound = provinceData.find(p => p.name == objectId);
        let lgFound = localData.find(lg => lg.gnid == objectId);

        if (type === 'local' && lgFound) {
            history.push(
                {
                    pathname: `/details/${type}/${objectId}`
                }
            );
        } else if (type === 'district') {
            return;
        } else if (type === 'province') {
            return;
        }

    }

    loadMap() {
        // province map
        let provinceMapRes = provinceMapJson;
        let provinceMapGeo = {
            "type": "FeatureCollection",
            "features": []
        };
        provinceMapRes.response.map(province => {
            provinceMapGeo.features.push({
                "type": "Feature",
                "geometry": province.geometry,
                "properties": {
                    "province": province.name
                }
            });

        })

        // district map
        let districtMapRes = districtMapJson;
        let districtMapGeo = {
            "type": "FeatureCollection",
            "features": []
        };
        districtMapRes.features.map(district => {
            districtMapGeo.features.push({
                "type": "Feature",
                "geometry": district.geometry,
                "properties": {
                    "district": district.properties.DISTRICT
                }
            });

        })

        // local gov. map
        let lgMapRes = localMapJson;
        let lgMapGeo = {
            "type": "FeatureCollection",
            "features": []
        };
        lgMapRes.response.map(lg => {
            lgMapGeo.features.push({
                "type": "Feature",
                "geometry": lg.geometry,
                "properties": {
                    "gnid": lg.gnid,
                    "palika": lg.name
                }
            });

        })
        this.setState({ localMap: lgMapGeo, provinceMap: provinceMapGeo, districtMap: districtMapGeo });
    }

    _onLoginClick() {
        return (
            <Redirect to={{
                pathname: '/login',
                state: { from: this.props.location }
            }} />
        );
    }

    _renderCategoriesList() {
        const { activeData } = this.state;
        if (!activeData) return;
        let content = [];
        const { categories } = this.state;
        categories.map(category => {
            let categoryId = (category.id).toString();
            content.push(<div className='main-list' key={categoryId}>
                <span className='dash-cat-name'>
                    <img src={category.image} className="cat-icon" alt="cat-icon" />
                    <div className="name">{category.name}</div>
                    <CircularProgressbar
                        percentage={activeData.categories[categoryId]}
                        text={`${changeIndexToNepali(activeData.categories[categoryId])}`}
                        styles={{
                            path: { stroke: colorScale(activeData.categories[categoryId]) },
                            text: { fill: colorScale(activeData.categories[categoryId]), fontSize: '28px' },
                        }}
                    />
                </span>
            </div>);
        })
        return content;
    }

    _setColor(objectId, type) {
        const { provinceData, districtData, localData } = this.state;
        switch (type) {
            case 'province':
                let provinceFound = provinceData.find(p => p.name == objectId);
                if (provinceFound) {
                    return colorScale(provinceFound.percentage);
                }
                break;
            case 'district':
                let districtFound = districtData.find(d => d.name == objectId);
                if (districtFound) {
                    return colorScale(districtFound.percentage);
                }
                break;
            case 'local':
                let lgFound = localData.find(lg => lg.gnid == objectId);
                if (lgFound) {
                    return colorScale(lgFound.percentage);//TODO score to peercent
                }
                break;
            default:
                break;
        }
        return '#bebebe';
    }

    _renderSubCategories() {
        let content = [];
        const { subCategories, activeData } = this.state;
        if (!activeData) return;
        subCategories.map(subCategory => {
            let scatId = (subCategory.id).toString();
            content.push(
                <div className="pro" key={scatId}>
                    <CircularProgressbar
                        percentage={activeData.subCategories[scatId]}
                        text={`${changeIndexToNepali(activeData.subCategories[scatId] || '-')}`}
                        styles={{
                            path: { stroke: colorScale(activeData.subCategories[scatId]) },
                            text: { fill: '#66d120', fontSize: '26px' },
                        }}
                    />
                    <p>{subCategory.name}</p>
                </div>
            );
        })
        return content;
    }

    _getFiscalyear = async () => {
        let fyList = await Api.getFyList();
        this.setState({ fyList: fyList.response });
    }

    render() {
        const { history } = this.props;
        const { activeTab, localMap, districtMap, provinceMap, activeData, nepalData, provinceData, categories, subCategories, totalLgFilled, fyId, fyList, provinceId, provinceList, districtList, districtId, palikaList, palikaId } = this.state;
        const isJsonLoaded = (activeTab === 'local') ? !!localMap : (activeTab === 'district') ? !!districtMap : !!provinceMap;
        if (!activeData) {
            return (
                <div className="loading">Loading...</div>
            );
        }
        return (
            <section className="home">
                <Helmet>
                    <title>LISA Nepal - Home</title>
                </Helmet>
                <Row>
                    <Col sm={3}>
                        <span className="breadcrumb-text" style={{ fontSize: '18px', fontWeight: 'bold', color: 'black' }}>
                            गृह पृष्ठ
                            |</span>
                        <span className="breadcrumb-text" style={{ marginLeft: '5px' }}>
                            नक्शा
                        </span>
                    </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Input type="select" name="fselect" id="fiscalYearSelect" value={fyId} onChange={(e) => this._handleFiscalYearChange(e)}>
                                <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                                {
                                    fyList.length > 0 &&
                                    fyList.map((fy, index) => {
                                        return (
                                            <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <Input type="select" placeholder="प्रदेश चयन गर्नुहोस" name="provinceId" id="provinceId" value={provinceId} onChange={(e) => this._handleProvinceChange(e)}>
                                <option value={0} disabled selected>प्रदेश चयन गर्नुहोस</option>
                                {
                                    provinceList.length > 0 &&
                                    provinceList.map((province, index) => {
                                        return (
                                            <option key={province.id} value={province.id} >{province.name}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <Input type="select" placeholder="जिल्ला चयन गर्नुहोस" name="districtId" id="districtId" value={districtId} onChange={(e) => this._handleDistrictChange(e)}>
                                <option value={0} disabled selected>जिल्ला चयन गर्नुहोस</option>
                                {
                                    districtList.length > 0 &&
                                    districtList.map((district, index) => {
                                        return (
                                            <option key={district.id} value={district.id} >{district.name}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm={2}>
                        <FormGroup>
                            <Input type="select" placeholder="पालिका चयन गर्नुहोस" name="palikaId" id="palikaId" value={palikaId} onChange={(e) => this._handlePalikaChange(e)}>
                                <option value={0} disabled selected>पालिका चयन गर्नुहोस</option>
                                {
                                    palikaList.length > 0 &&
                                    palikaList.map((palika, index) => {
                                        return (
                                            <option key={palika.id} value={palika.id} >{palika.name}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {this.state.isLoading ? (<div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>) : (
                    <div className="content">
                        <Row>
                            <Col sm="3">
                                <div className="home-list">
                                    <h5 className="sub-heading">स्व-मूल्याङ्कन विषयक्षेत्रहरु</h5>
                                    {
                                        this._renderCategoriesList()
                                    }
                                </div>

                            </Col>
                            <Col sm="9">
                                <div className="details-header">
                                    <Row>
                                        <Col sm="3" >

                                            <h3 className="municipal-name">{activeData.name}</h3>
                                            <div className="average-rank">
                                                <div className="average">
                                                    <h3 className="data-average" style={{ color: colorScale(activeData.percentage) }}>{changeIndexToNepali(activeData.percentage)}%</h3>
                                                    <p>औसत</p>
                                                </div>
                                                {activeData.scope &&
                                                    <div className="rank">
                                                        <h3 className="data-rank">{changeIndexToNepali(activeData.scope)}</h3>
                                                        <p>स्थानीय तहहरु</p>
                                                    </div>
                                                }
                                                {/* <div className="rank">
                                                <h3 className="data-rank">२२/१५०  </h3>
                                                <p> बरीयताक्रम</p> 8
                                            </div> */}
                                            </div>
                                        </Col>
                                        <Col sm="4" >
                                            <div className="municipal-progress">
                                                {this._renderSubCategories()}

                                            </div>
                                        </Col>
                                        <Col sm="5" className="clearfix">
                                            <Nav tabs className="flex-nowrap">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === 'local' })}
                                                        onClick={() => { this.toggle('local'); }}
                                                    >
                                                        स्थानीय तह
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === 'district' })}
                                                        onClick={() => { this.toggle('district'); }}
                                                    >
                                                        जिल्ला
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.activeTab === 'province' })}
                                                        onClick={() => { this.toggle('province'); }}
                                                    >
                                                        प्रदेश
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <GradientLabel />
                                        </Col>
                                    </Row>

                                </div>
                                {isJsonLoaded &&
                                    <div className="map-wrapper">
                                        <NepalMap geoJson={(activeTab === 'local') ? localMap : (activeTab === 'district') ? districtMap : provinceMap} type={activeTab} updateData={this._updateData} redirect={this._redirect} setColor={this._setColor} activeArea={this.state.activeArea} />
                                    </div>
                                }
                            </Col>
                        </Row>
                    </div>
                )}
            </section>



        );
    }
}
const mapStateToProps = ({ auth }) => ({
    fiscal_year_id: auth.login.fiscal_year_id
});


export default connect(mapStateToProps)(Home);
