import * as actions from '../actions/fyAction';

let initialState = { 
    activeFy: '2',
};

export default  (state=initialState, action) => {
    switch(action.type) {
    case actions.SET_FY:
        return { ...state, activeFy: action.fy };
    default: 
        return state;
    }
};
