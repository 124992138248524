import React, { useState, useEffect } from 'react';
import { Input, Button, Row, Col, FormGroup, Tooltip } from 'reactstrap';
import ReactExport from "react-export-excel";
import loading_img from "../../assets/images/loading.gif"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ProvinceReport = () => {
    let baseUrl = process.env.REACT_APP_API_BASE_URL||'https://lisa.mofaga.gov.np/backend/api';

    const[fyId,setFyId] = useState(localStorage.getItem('fiscalYear'));
    const[fyList,setFyList] = useState(null);
    const[provinceId,setProvinceId] = useState(null);
    const[provinceList,setProvinceList] = useState(null);
    const[data,setData] = useState(null);
    const[isDisable,setIsDisable] = useState(false);

    const handleFiscalYearChange = (e) =>{
        setFyId(e.target.value);
    }

    const handleProvinceChange = (e) =>{
        setProvinceId(e.target.value);
    }

    const export2Excel = () => {

        let filterData = [];

        if(data){

        data.forEach((item) => {
            let my_obj = {
                indicator: item.indicator,
                name: item.question,
            };
            if(item.district){
                item.district.forEach((child)=>{
                    if(child.palika){
                        child.palika.forEach((subchild)=>{
                            my_obj = {...my_obj,[subchild.palika_name]:subchild.score}
                        })
                    }
                }
            )}
            filterData.push(my_obj);
        });
        }

        
        const excelColumns = [
            <ExcelColumn key="indicator" label="क्रसं" value="indicator" />,
            <ExcelColumn key="name" label="प्रश्न" value="name" />
          ];

          if(data){
        //   data[0]?.district[0]?.palika.map((item,index)=>
        //   excelColumns.push(<ExcelColumn key={index} label={item?.palika_name} value={item?.palika_name} />)
        //   )  

        const districts = data[0].district;
          
        if (districts) {
          districts.forEach((item) => {
            const palikas = item.palika;
            if (palikas) {
              palikas.forEach((subitem, index) => {
                const palikaName = subitem.palika_name;
                if (palikaName) {
                    {console.log(palikaName)}
                  excelColumns.push(<ExcelColumn key={index} label={palikaName} value={palikaName} />);
                }
              });
            }
          });
        }}
        
          return(
        <ExcelFile element={<Button color="primary" disabled={isDisable||!provinceId}>
            {(isDisable)?<img height="20px" src={loading_img}/>:"डाउनलोड"}
        </Button>}>
        <ExcelSheet data={filterData} name="स्थानीय तह स्कोर टेबल">
          {excelColumns}
        </ExcelSheet>
      </ExcelFile>
          )
      }

    useEffect(()=>{
        fetch(baseUrl+'/provinces')
        .then(response => response.json())
        .then(data => setProvinceList(data.response))

        fetch(baseUrl+'/fiscal_years')
        .then(response => response.json())
        .then(data => setFyList(data.response))

    },[])

    useEffect(()=>{
        setIsDisable(true);
        fetch(baseUrl+`/reports/download_district_score?fiscal_year_id=${fyId}&province_id=${provinceId||1}`).
        then(response=>response.json())
        .then(data=>{setData(data.response.data)
        setIsDisable(false)
    })
    },[fyId,provinceId])


    useEffect(()=>{
        export2Excel()
    },[data])

    return (
        <div className="">
            <div className="content">
                <h1 className="sub-heading">प्रदेश रिपोर्ट</h1>
                <>
                    <Row>
                        <Col sm={2}>
                            <FormGroup>
                                <Input type="select" name="fselect" id="fiscalYearSelect" value={fyId} onChange={handleFiscalYearChange}>
                                    <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                                    {
                                        fyList && fyList.length > 0 &&
                                        fyList.map((fy, index) => {
                                            return (
                                                <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={2}>
                            <FormGroup>
                                <Input type="select" placeholder="प्रदेश चयन गर्नुहोस" name="provinceId" id="provinceId" value={provinceId} onChange={handleProvinceChange}>
                                    <option value={0} disabled selected>प्रदेश चयन गर्नुहोस</option>
                                    {
                                        provinceList && provinceList.length > 0 &&
                                        provinceList.map((province, index) => {
                                            return (
                                                <option key={province.id} value={province.id} >{province.name}</option>
                                            );
                                        })
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={1}>
                        {export2Excel()}
                        </Col>
                    </Row>
                </>
            </div>
        </div>
    )
}

export default ProvinceReport;