import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../services/api';
import { changeIndexToNepali } from '../../utils';
import Actions from './Actions';
import './style.css';
import localMapJson from "../../assets/geoJson/local.json";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BounceLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify';
import logo from '../../assets/images/logo.svg';
import ViewModal from '../../components/modals/ViewModal';
import { FaCheckCircle, FaPrint, FaExclamationCircle, FaEye, FaSpinner } from 'react-icons/fa';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const QaReview = ({ user }) => {

    const [questionLoading, setQuestionLoading] = useState(false)
    const [questionData, setQuestionData] = useState([])
    const [feedback1, setFeedback1] = useState('')
    const [feedback2, setFeedback2] = useState('')
    const [isCompleted, setIsCompleted] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [palikaName, setPalikaName] = useState('')

    const [showConfirm, setShowConfirm] = useState(false)
    const toogleConfirmation = () => setShowConfirm(!showConfirm);

    const [showFeedback, setShowFeedback] = useState(false);
    const toogleFeedback = () => setShowFeedback(!showFeedback);

    let fiscalYear = user.fiscal_year_id;
    console.log("here", user.fiscal_year_id)
    let gnId = window.location.search.substring(6);

    const initialCall = useCallback(
        async () => {
            setQuestionLoading(true);
            let response = await Api.getQaIndex(fiscalYear, gnId);
            if (response && response.code === 200) {
                setQuestionData(response.response.questions)
                setFeedback1(response.response.qa_feedback)
                setFeedback2(response.response.qa_feedback_others)
                setIsCompleted(response.response.qa_status === 'complete')
            }
            setQuestionLoading(false);

            let geoJson = localMapJson.response.find((palika) => {
                return gnId === palika.gnid;
            });
            setPalikaName(geoJson.name)
        },
        [],
    )

    useEffect(() => {
        initialCall()
    }, [initialCall])

    const internalControlRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => internalControlRef.current,
    });

    const renderDocumentListFromOption = (documents) => {
        let documentList = [];
        if (documents) {
            if (documents.length === 0) {
                documentList.push(<p className="document-name document-empty" key={'no-doc'}>&bull; कुनैपनि प्रमाणीकरणको आधारहरु नचाहिने <FaCheckCircle className="check-circle-doc" color='#28a745' /></p>);
            }
            documents.map(document => {
                let singleDoc;
                if (document.path) {
                    singleDoc = <React.Fragment key={document.id}><a>
                        <p className="document-name">&bull; {document.name}
                            <FaCheckCircle className="check-circle-doc" color='#28a745' />
                            <span className='d-inline'><ViewModal file={document.path} /></span>
                        </p>
                    </a>
                    </React.Fragment>
                } else {
                    singleDoc = <a key={document.id}><p className="document-name">&bull; {document.name}<FaExclamationCircle className="check-circle-doc" color='#e12a2a' /></p></a>
                }
                documentList.push(singleDoc);
                //if path==="" show exclaim else show tick and link to path.
            });
        }
        return documentList;
    }

    const handleSubmit = async () => {
        setSubmitLoading(true)
        let res = await Api.changeStatus({
            fiscal_year_id: fiscalYear,
            status: 5,
            gnid: gnId
        });

        if (res && res.code === 200) {
            console.log(res.code, "here")

            toast.success(res.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            toogleConfirmation()
            let response = await Api.getQaIndex(fiscalYear, gnId);
            if (response && response.code === 200) {
                setQuestionData(response.response.questions)
                setFeedback1(response.response.qa_feedback)
                setFeedback2(response.response.qa_feedback_other)
                setIsCompleted(response.response.qa_status === 'complete')
            }
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setSubmitLoading(false)
    }

    const handleFeedback1Change = (e) => {
        setFeedback1(e.target.value);
    }

    const handleFeedback2Change = (e) => {
        setFeedback2(e.target.value);
    }

    const handleSubmitFeedback = async () => {
        setFeedbackLoading(true)
        let response = await Api.submitQAFeedback({
            fiscal_year_id: fiscalYear,
            status: 5,
            gnid: gnId,
            qa_feedback: feedback1,
            qa_feedback_others: feedback2
        });

        if (response.code === 200) {
            toast.success(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
            toast.error(response.message, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
        setFeedbackLoading(false)
    }

    return (
        <>
            {questionLoading ? (
                <div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>
            ) : (
                <div className="manual-wrapper">
                    <div className="mx-1 sticky-heading-internal">
                        <h1 className="sub-heading">{palikaName}को गुणस्तर परीक्षण फारम</h1>{" "}
                        <div>
                            <Button onClick={() => toogleFeedback()} className="uni-menu-item mr-2">
                                प्रतिक्रिया
                            </Button>
                            <Button onClick={handlePrint} color="success" className="print-button mr-2">
                                <FaPrint className="print-icon" />
                                Print
                            </Button>
                            <Button disabled={isCompleted} onClick={() => toogleConfirmation()} className="uni-menu-item">
                                पेश गर्नुहोस्
                            </Button>
                        </div>
                    </div>

                    <div ref={internalControlRef}>
                        <div className="print-header print-only-internal">
                            <img src={logo} alt="logo" className="nav-logo-user" />
                            <div style={{ color: '#e12a2a', marginLeft: 20, fontSize: 14 }}>
                                <span style={{ color: '#676767', fontSize: 20 }}>
                                    {palikaName} <br />
                                </span>
                                <span style={{ color: '#2572bc', fontSize: 14 }}>-<br /></span>
                                Local Government Institutional Capacity Self-Assessment
                            </div>
                        </div>
                        <table className="waffle" cellSpacing={5} style={{ borderCollapse: 'unset' }} cellPadding={10} width={"100%"}>
                            <thead className="table-heading-internal">
                                <tr>
                                    <td className="font-weight-bold text-right">क्र.सं.</td>
                                    <td className="font-weight-bold" style={{}}>प्रश्न</td>
                                    <td className="font-weight-bold" style={{}}>चयन गरिएको विकल्प</td>
                                    <td className="font-weight-bold" style={{}}>कागजात</td>
                                    <td className="font-weight-bold" style={{ minWidth: '200px' }}>हुनपर्ने विकल्प</td>
                                    <td className="font-weight-bold" style={{ minWidth: '200px' }}>कैफियत</td>
                                </tr>
                            </thead>
                            <tbody>
                                {questionData.map((item) => (
                                    <Fragment key={item.id}>
                                        <tr className="table-bg-white">
                                            <td className="text-left">{item.indicator}</td>
                                            <td className="text-left">{item.question}</td>
                                            <td className="text-left">{item.selected_option_name}</td>
                                            <td className="text-left">{
                                                renderDocumentListFromOption(item.documents)
                                            }
                                            </td>
                                            <Actions item={item} fiscal_year_id={fiscalYear} gnid={gnId} isCompleted={isCompleted} />
                                        </tr>
                                    </Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <Modal isOpen={showConfirm} toggle={toogleConfirmation} className="delete-modal">
                <ModalHeader toggle={toogleConfirmation}>पेश गर्नुहोस्</ModalHeader>
                <ModalBody>
                    के तपाइँ निश्चित रूपमा पेश गर्न चाहनुहुन्छ?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={submitLoading} onClick={() => handleSubmit()}>पेश गर्नुहोस्
                        &nbsp; {submitLoading && <FaSpinner className="rotating" />}
                    </Button>
                    <Button color="red" onClick={toogleConfirmation}>रद्द गर्नुहोस्</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={showFeedback} toggle={toogleFeedback} className="delete-modal">
                <ModalHeader toggle={toogleFeedback}>प्रतिक्रिया</ModalHeader>
                <ModalBody>
                    <label htmlFor='feedback1'>
                        स्थानीय तहलाई गुणस्तर परीक्षणर्ताको सुझाव
                    </label>
                    <textarea disabled={isCompleted} className="form-control w-100 mb-3" value={feedback1} name={`feedback1`} onChange={handleFeedback1Change} />
                    <label htmlFor='feedback2'>
                        अन्य केही भए:
                    </label>
                    <textarea disabled={isCompleted} className="form-control w-100" value={feedback2} name={`feedback2`} onChange={handleFeedback2Change} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={isCompleted || feedbackLoading} onClick={() => handleSubmitFeedback()}>प्रतिक्रिया पेश गर्नुहोस्
                        &nbsp; {feedbackLoading && <FaSpinner className="rotating" />}
                    </Button>
                    <Button color="red" onClick={toogleFeedback}>रद्द गर्नुहोस्</Button>
                </ModalFooter>
            </Modal>

            <ToastContainer />
        </>
    )
}
const mapStateToProps = ({ auth }) => ({
    user: auth.user,
});

const enhance = compose(withRouter, connect(mapStateToProps, null));

export default enhance(QaReview);
