import React, { useEffect, useState, useMemo } from "react";
import HeaderTable from "../DataTable/HeaderTable";
import PaginationComponent from "../DataTable/Pagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SearchTable from "../DataTable/Search";
import Api from '../../services/api';
import "./style.css";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Comment from "../../containers/SelfAssessmentForm/Comment";
import moment from 'moment'


export default function AllCommentTable({ comments, title, user }) {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 20;

  const headers = [
    { name: "क्र.स.", field: "id", sortable: false },
    { name: "प्रश्न", field: "title", sortable: false },
    { name: "पृष्ठपोषण", field: "title", sortable: false },
    { name: "कार्य", field: "action", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.question.toLowerCase().includes(search.toLowerCase())
        // ||
        // comment.img.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedComments;

    //Current Page slice
    // return computedComments.slice(
    //   (currentPage - 1) * ITEMS_PER_PAGE,
    //   (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    // );
  }, [comments, currentPage, search, sorting]);

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col mb-3 col-12">
          {/* <div className="row table-heading">
            <div className="col-md-6">
              {title && <h1 className="sub-heading">{title}</h1>}
            </div>
            <div className="col-md-6 d-flex flex-row-reverse dataTables_search">
              <SearchTable
                onSearch={value => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div> */}
          {commentsData == '' ? <h4>विवरण उपलब्ध छैन</h4> :
            <table className="table">
              <HeaderTable
                headers={headers}
                onSorting={(field, order) =>
                  setSorting({ field, order })
                }
              />
              <tbody>
                {commentsData.map((comment, i) => (
                  <tr>
                    <th style={{ width: '5%' }} scope="row" key={i}>
                      {++i}
                    </th>
                    <td style={{ width: '35%' }}>{comment.question}</td>
                    <td style={{ width: '60%' }} align="char">{comment.comments.map((item, i) => (
                      <div>
                        <p className="m-0">{item.message}</p>
                        <div>
                          <small className="text-primary">{item.created_by}</small>
                          <small>&nbsp; at &nbsp;</small>
                          <small className="text-muted">{item.created_at && moment(item.created_at).format('DD MMM YYYY, HH:mm A')}</small>
                        </div>
                        <hr />
                      </div>
                    ))}</td>
                    <td style={{ width: '10%' }}><Comment user={user} count={comments.length} q_id={comment.question_id} link={true} /></td>
                  </tr>
                ))}
              </tbody>
            </table>}
        </div>
      </div>
    </>
  )
}
