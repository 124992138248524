import React, { useEffect, useState, useMemo } from "react";
import "./style.css";
import Api from "../../services/api";
import store from "../../store";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { changeIndexToNepali } from "../../utils";

export default function UserFeedback({ user }) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <div>
      <button onClick={toggle} className="btn btn-primary sticky-button">
        <i className="fa fa-comment"></i>
      </button>
      <Modal
        returnFocusAfterClose={false}
        isOpen={modal}
        toggle={toggle}
        className={"modal-lg modal-dialog-centered comments-wrap"}
      >
        <ModalHeader toggle={toggle}>प्रतिक्रिया पठाउनुहोस् </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="user-name">नाम</Label>
              <Input type="text" name="name" id="name" placeholder="नाम" />
            </FormGroup>
            <FormGroup>
              <Label for="user-email">इमेल</Label>
              <Input type="email" name="user-email" placeholder="इमेल" />
            </FormGroup>
            <FormGroup>
              <Label for="user-password">ठेगाना</Label>
              <Input type="address" name="user-address" placeholder="ठेगाना" />
            </FormGroup>
            <FormGroup>
              <Label for="user-re-password">प्रतिक्रिया</Label>
              <Input
                type="textarea"
                name="user-feedback"
                placeholder="प्रतिक्रिया"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            onClick={toggle}
            disabled={user.entry_status === 0}
          >
            रद्द गर्नुहोस्
          </button>
          <button
            className="btn btn-primary"
            disabled={user.entry_status === 0}
          >
            स्वीकृत गर्नुहोस
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
