import React, { useEffect, useRef, useState } from 'react'
import { FaPrint } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { BounceLoader } from 'react-spinners';
import ReactToPrint from 'react-to-print';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import AllCommentTable from '../../components/Table/AllCommentTable';
import Api from '../../services/api';
import AllCommentTablePrint from './AllCommentTablePrint';

const AllComments = ({ user, totalCount }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [loading, setLoading] = useState([]);
  const [data, setData] = useState([]);
  const [commentForPrint, setCommentForPrint] = useState([]);

  const _loadComments = async () => {
    setLoading(true)
    let fy = user.fiscal_year_id
    let location = window.location.href
    let gnId = location.substring(location.length - 5)
    let datas = await Api.getAllComments(fy, gnId);
    if (datas.response.code != '') {
      await setData(datas.response)
      setLoading(false)
    }
  }

  const _loadCommentsForPrint = async () => {
    let fy = user.fiscal_year_id
    let location = window.location.href
    let gnId = location.substring(location.length - 5)
    let datas = await Api.getAllCommentsForPrint(fy, gnId);
    if (datas.response.code != '') {
      await setCommentForPrint(datas.response)
    }
  }

  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  return (
    <>
      <Button onClick={() => { _loadComments(); toggle(); _loadCommentsForPrint(); }} className="uni-menu-item">पृष्ठपोषण हेर्नुहोस् ({totalCount})</Button>
      <Modal
        returnFocusAfterClose={false}
        isOpen={modal}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          सबै पृष्ठपोषण हेर्नुहोस्
        </ModalHeader>
        <ModalBody>
          {loading ? (
            <div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>
          ) : (
            <>
              <div className='d-flex  justify-content-end mb-2'>
                <ReactToPrint
                  trigger={() => <Button color="success" className="print-button btn-sm"><FaPrint className="print-icon" />Print All Comments</Button>}
                  content={reactToPrintContent}
                  onBeforePrint={() => console.log('on before')}
                  onAfterPrint={() => console.log('on after')}
                />
              </div>
              <AllCommentTable comments={data} user={user} />

              <div ref={componentRef} id="print-comment" className='m-5'>
                <AllCommentTablePrint comments={commentForPrint} user={user} />
              </div>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}

export default AllComments
