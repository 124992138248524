import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, Tooltip, Badge } from 'reactstrap';
import Map from './../../components/Map';
import RadarChart from './../../components/RadarChart';
import './style.css';
import Api from '../../services/api';
import { FaCog, FaPaperclip, FaEdit, FaTrash, FaKey } from 'react-icons/fa';
import { changeIndexToNepali, changeNepaliIndexToEnglish, colorScale } from '../../utils';
import { Line } from 'rc-progress';
import BarGraph from '../../components/BarChart';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as modalAction from '../../actions/modalAction';
import MainLoader from '../../components/MainLoader';
import { MdSelectAll } from 'react-icons/md';
import Select from 'react-select';
import moment from 'moment';

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: props.userId || null,
      toggleEditModal: props.toggleEditModal,
      getUsers: props.getUsers,
      user: null,
      confirmModal: false,
      mode: props.mode || 'show', //can be show||edit
      categoriesOption: [],
      changePasswordModal: false,
      password: '',
      confirmPassword: '',
      saveLoading: false,
      adminRole: null,
      showCatsModal: false,
      checkedCategories: [],
      allCategoriesOption: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    const { user } = this.state;
    const { history, loginUser } = this.props;
    await this.setState({ adminRole: loginUser.role })

    let categoriesOption = await this._getCategories();
    if (!user) {
      this._loadUser(categoriesOption)
    } else {
      // let selectedCategories = [];
      if (user.categories) {
        // user.categories.map(sCat => {
        //   selectedCategories.push({
        //     value: sCat,
        //     label: categoriesOption.filter(category => {
        //       return category.value === sCat;
        //     })[0].label,
        //   });
        // });
        this.setState({ user: { ...user }, checkedCategories: user.categories });
      }
    }
    let allCategoriesOption = await this._getAllCategories();

    this.setState({ categoriesOption, allCategoriesOption });
  }

  _getAllCategories = async () => {
    let catRes = await Api.getAllCategories();
    return catRes.response;
  }

  _loadUser = async () => {
    const { userId } = this.state;
    let res = await Api.getUserDetail(userId);
    let user = res.response;
    let roleArray = {
      "Palika Approver (CAO)": "approver",
      "Palika Data Entry": "user",
      "District Approver": "dcc_approver",
      "District Data Entry": "dcc_user"
    }
    user.role = roleArray[user.role];

    if (user.categories) {
      this.setState({ user: { ...res.response }, checkedCategories: user.categories });

    } else {
      this.setState({ user: { ...res.response } });
    }
  }


  toggle() {
    this.setState({
      confirmModal: !this.state.confirmModal
    });
  }

  _toggleCatsModal = async () => {
    this.setState({ showCatsModal: !this.state.showCatsModal });
  }

  _onSaveClick() {
    return (
      <Redirect to={{
        pathname: '/selfaccessmentform',
        state: { from: this.props.location }
      }} />
    );
  }

  onRoleChange = (e) => {
    this.setState({
      user: { ...this.state.user, role: e.target.value }
    })
  }


  onEmailChange = (e) => {
    this.setState({
      user: { ...this.state.user, email: e.target.value }
    })
  }

  onNameChange = (e) => {
    this.setState({
      user: { ...this.state.user, name: e.target.value }
    })
  }

  onPassChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  onConfirmPassChange = (e) => {
    this.setState({
      confirmPassword: e.target.value
    })
  }
  _changePassword = async () => {
    const { user, password, confirmPassword } = this.state;
    if (password !== confirmPassword) {
      toast.error('पासवर्ड र पुन: पासवर्ड मिलेन', {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    let response = await Api.changePassword(user, { password, password_confirmation: confirmPassword });
    if (response.code === 200) {
      toast.success('पासवर्ड परिवर्तन सफल', {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this._toggleChangePasswordModal();
    this.setState({ password: '', confirmPassword: '' });
  }

  _toggleDeleteModal = async () => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  _toggleChangePasswordModal = async () => {
    this.setState({ changePasswordModal: !this.state.changePasswordModal });
  }

  _deactivateUser = async () => {
    const { user } = this.state;
    const { history } = this.props;
    this.setState({ isLoading: true });
    await Api.deactivateUser(user.id);
    toast.success('युजर हटाउन सफल।', {
      position: toast.POSITION.TOP_CENTER,
    });
    this._toggleDeleteModal();
    this.setState({ isLoading: false });
    history.push({
      pathname: `/user`,
    });
  }

  _userEditClick = (d) => {
    // const { history } = this.props;
    // history.push({
    //   pathname: `/user/${d.id}/edit`,
    //   state: { user: d }
    // });
    this.setState({ mode: 'edit' });
  }

  _renderShow = () => {
    const { user } = this.state;
    const { history } = this.props;
    if (!user) return;
    return (
      <>
        <div className="user-control">
          <FaEdit onClick={() => this._userEditClick(user)} className="edit-action" color='#28a53d' />
          <FaTrash onClick={() => this._toggleDeleteModal()} className="remove-action" color='#e12a2a' />
        </div>
        {this.state.adminRole === 'Palika Admin (Focal Person)' ? (
          <p>स्थानिय तह: {user.palika_name}</p>
        ) : this.state.adminRole === 'District Admin' ? (
          <p>जिल्ला: {user.district_name}</p>
        ) : null}
        <Badge color={'primary'}>रोल: {user.role}</Badge>

        <p>नाम: {user.name}</p>
        <p>मेल: {user.email}</p>
        {user.created_at && <p>बनाइएको मिति: {user.created_at}</p>}
        {user.updated_at && <p>अद्यावधिकको मिति: {user.updated_at}</p>}
        {console.log("user", user)}
        {user.role_id === 4 &&
          <div>
            <div className='d-flex justify-content-between'>
              <div><p>विषयगत क्षेत्राधिकार:</p></div>
              <div>
                <div className='d-flex align-items-center'>
                  <p className='bg-success rounded' style={{ height: "15px", width: "15px" }}></p>
                  <p>अधिकार दिइएको</p>
                </div>
                <div className='d-flex align-items-center'>
                  <p className='rounded text-white' style={{ height: "15px", width: "15px", backgroundColor: "#FF8B13" }}></p>
                  <p>अधिकार दिन बाकि</p>
                </div>


              </div>

            </div>

            <table>

              {this.state.allCategoriesOption.map((item, index) => {
                return (
                  <tbody className='table-bordered'>

                    <td className='text-center' style={{ width: "10%" }}>{item.module}:</td>
                    <td>
                      {item.categories.map(cat => (
                        this.state.checkedCategories.includes(cat.id) ?
                          <label htmlFor={`cat-checkbox${cat.id}`} className='m-1 btn btn-success text-white' >{cat.name}</label> : <label htmlFor={`cat-checkbox${cat.id}`} className='m-1 btn' style={{ backgroundColor: "#FF8B13" }}>{cat.name}</label>

                      ))}
                    </td>


                  </tbody>

                )
              })}



            </table>
          </div>}

        {/* <Button className="btn btn-danger mt-3" onClick={this.state.toggleEditModal}>रद्द गर्नुहोस्</Button> */}

      </>
    )

  }

  selectAllCategory = () => {
    this.setState({ user: { ...this.state.user, categories: this.state.categoriesOption } });
  }

  _toggleTooltip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  _getCategories = async () => {
    let catRes = await Api.getCategories();
    let categoriesOption = [];
    catRes.response.map((sCat) => {
      categoriesOption.push({
        value: sCat.id,
        label: sCat.name,
      })
    });
    return categoriesOption;
  }

  handleCategoryChange = selectedOption => {
    this.setState({ user: { ...this.state.user, categories: selectedOption } });
  };

  handleCategoryCheck = (value, id) => {
    let checkedCategoriesList
    if (value) {
      checkedCategoriesList = this.state.checkedCategories
      checkedCategoriesList.push(id)
    } else {
      checkedCategoriesList = this.state.checkedCategories.filter(x => x.toString() != id.toString())
    }
    this.setState({ checkedCategories: checkedCategoriesList });
  };


  _renderEdit = () => {
    const { user, tooltipOpen, categoriesOption, changePasswordModal } = this.state;
    if (!user) return;
    return (
      <Form>
        <FaTrash onClick={() => this._toggleDeleteModal()} className="remove-action" color='#e12a2a' />
        <FaKey onClick={() => this._toggleChangePasswordModal()} className="password-action" color='#FFD700' />
        {this.state.adminRole === 'Palika Admin (Focal Person)' ? (
          <p>स्थानिय तह: {user.palika_name}</p>
        ) : this.state.adminRole === 'District Admin' ? (
          <p>जिल्ला: {user.district_name}</p>
        ) : null}
        {this.state.adminRole === 'Palika Admin (Focal Person)' ? (
          <FormGroup>
            <Label for="user-role">रोल</Label>
            <Input type="select" name="select" id="user-role" value={user.role || ""} onChange={this.onRoleChange}>
              <option value="" disabled selected="selected">छान्नुहोस्</option>
              <option value="approver">CAO</option>
              <option value="user">Palika Data Entry</option>
            </Input>
          </FormGroup>
        ) : this.state.adminRole === 'District Admin' ? (
          <FormGroup>
            <Label for="user-role">रोल</Label>
            <Input type="select" name="select" id="user-role" value={user.role || ""} onChange={this.onRoleChange}>
              <option value="" disabled selected="selected">छान्नुहोस्</option>
              <option value="dcc_approver">DCC Approver</option>
              <option value="dcc_user">DCC Data Entry</option>
            </Input>
          </FormGroup>
        ) : null}
        <FormGroup>
          <Label for="userName">नाम</Label>
          <Input type="text" name="userName" id="userName" placeholder="नाम" onChange={this.onNameChange} value={user.name || ""} />
        </FormGroup>
        <FormGroup>
          <Label for="userMail">मेल</Label>
          <Input type="email" name="email" id="userMail" placeholder="मेल" onChange={this.onEmailChange} value={user.email || ""} />
        </FormGroup>
        {this.state.adminRole === 'Palika Admin (Focal Person)' && (user.role === "Palika Data Entry" || user.role === "user") &&

          <FormGroup>
            <Label for="user-category">विषयक्षेत्रहरु</Label>
            <div className="select-cat-wrapper">
              {/* <Select
                value={user.categories}
                onChange={this.handleCategoryChange}
                className="select-cat-multi"
                isMulti={true}
                options={categoriesOption}
              />
              <MdSelectAll className="select-all-icon" id="cat-select-all" onClick={() => this.selectAllCategory()} />
              <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="cat-select-all" toggle={this._toggleTooltip}>
                सबै विषयक्षेत्रहरु छान्नुहोस्
              </Tooltip> */}
              <Button className="btn btn-sm bt-info" onClick={this._toggleCatsModal}>Select Categories</Button>

            </div>
          </FormGroup>

        }

        {user.updated_by && <p>अद्यावधिकद्वारा: {user.updated_by}</p>}
        {user.updated_at && <p>अद्यावधिकको मिति: {moment(user.updated_at).format("dddd, MMMM Do, YYYY")} </p>}
        <Button onClick={this._onSubmit}>Submit</Button>
        {/* <Button className="btn btn-danger ml-3" onClick={this.state.toggleEditModal}>रद्द गर्नुहोस्</Button> */}
      </Form>
    )
  }

  _onSubmit = async () => {
    this.setState({
      saveLoading: true
    })

    const { user, checkedCategories } = this.state;

    let userDetails = user;
    if (userDetails.role === 'user') {
      let selectedCategoriesParam = checkedCategories
      selectedCategoriesParam = selectedCategoriesParam.map(cat => cat).join();
      userDetails = { ...userDetails, categories: selectedCategoriesParam }
      if (checkedCategories.length === 0) {
        toast.error('Palika Data Entry प्रयोगकर्तालाई कृपया कमसेकम एउटा स्व-मूल्याङ्कन विषयक्षेत्र छान्नुहोस्।', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({
          saveLoading: false
        })
        return;
      }
    }
    if (!userDetails.name || !userDetails.email || !userDetails.role) {
      toast.error('कृपया फारमको सबै क्षेत्रहरू भर्नुहोस्', {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({
        saveLoading: true
      })
      return;
    }
    let response;
    if (userDetails.categories) {
      response = await Api.updateUser({ ...userDetails });
    }
    else {
      response = await Api.updateUser({ ...userDetails });
    }
    if (response.code === 200) {
      await this._loadUser();
      toast.success('प्रयोगकर्ता अद्यावधिक सफल।', {
        position: toast.POSITION.TOP_CENTER,
      });
      this.state.getUsers()
      this.state.toggleEditModal()
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.setState({
      saveLoading: true
    })
  }

  render() {
    const { isLoading, mode, showDeleteModal, changePasswordModal } = this.state;
    return (
      <MainLoader active={isLoading}>
        <section className="lisa-form">
          <ToastContainer />
          {mode === 'show' ? this._renderShow() : this._renderEdit()}

        </section>
        <Modal isOpen={showDeleteModal} toggle={this._toggleDeleteModal} className="delete-modal">
          <ModalHeader toggle={this._toggleDeleteModal}>प्रयोगकर्ता हटाउनुहोस्</ModalHeader>
          <ModalBody>
            के तपाईँ यो प्रयोगकर्ता हटाउन चाहनुहुन्छ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { this._deactivateUser(); }}>प्रयोगकर्ता हटाउनुहोस्</Button>
            <Button color="red" onClick={this._toggleDeleteModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={changePasswordModal} toggle={this._toggleChangePasswordModal} className="delete-modal">
          <ModalHeader toggle={this._toggleChangePasswordModal}>पासवर्ड परिवर्तन गर्नुहोस्</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="user-password">पासवर्ड</Label>
              <Input type="password" name="user-password" placeholder="पासवर्ड" value={this.state.password || ""} onChange={this.onPassChange} />
            </FormGroup>
            <FormGroup>
              <Label for="user-re-password">पुन: पासवर्ड</Label>
              <Input type="password" name="user-re-password" placeholder="पुन: पासवर्ड" value={this.state.confirmPassword || ""} onChange={this.onConfirmPassChange} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { this._changePassword(); }}>परिवर्तन गर्नुहोस्</Button>
            <Button color="red" onClick={this._toggleChangePasswordModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>

        <Modal size="lg" isOpen={this.state.showCatsModal} toggle={this._toggleCatsModal} className="delete-modal">
          <ModalHeader toggle={this._toggleCatsModal}>विषयक्षेत्रहरु छान्नुहोस्</ModalHeader>
          <ModalBody>
            <Row>
              {this.state.allCategoriesOption.map(item => (
                <Col sm="6">
                  <h4>{item.module}</h4>
                  {item.categories.map(cat => (
                    <div className="d-block">
                      <input id={`cat-checkbox${cat.id}`} type="checkbox" checked={this.state.checkedCategories.includes(cat.id)} onChange={(e) => this.handleCategoryCheck(e.currentTarget.checked, cat.id)} /> &nbsp;
                      <label htmlFor={`cat-checkbox${cat.id}`}>{cat.name}</label>
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this._toggleCatsModal}>विषयक्षेत्रहरु छान्नुहोस्</Button>
            <Button color="red" onClick={this._toggleCatsModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>
      </MainLoader>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onDocumentsShow: (modalProps) => {
    dispatch(modalAction.processModal(modalAction.SHOW_MODAL, modalAction.ModalTypes.SHOW_DOCUMENT, modalProps));
  },
});

const mapStateToProps = ({ auth }) => ({
  loginUser: auth.user
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(UserDetail);