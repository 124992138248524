import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup, Label, Input, Tooltip, Badge } from 'reactstrap';
import Map from './../../components/Map';
import RadarChart from './../../components/RadarChart';
import './style.css';
import Api from '../../services/api';
import { FaCog, FaPaperclip, FaEdit, FaTrash, FaKey, FaEye } from 'react-icons/fa';
import { changeIndexToNepali, changeNepaliIndexToEnglish, colorScale } from '../../utils';
import { Line } from 'rc-progress';
import BarGraph from '../../components/BarChart';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as modalAction from '../../actions/modalAction';
import MainLoader from '../../components/MainLoader';
import { MdSelectAll } from 'react-icons/md';
import Select from 'react-select';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          user: null,
          password: '',
          confirmPassword: '',
          isLoading: true,
          mode: props.match.params.mode||'show', //can be show||edit
          categoriesNameObject: [],
        };
    }

    _getCategories = async() => {
      let catRes = await Api.getCategories();
      let categoriesNameObject = [];
      catRes.response.map((sCat)=>{
        categoriesNameObject[sCat.id]= sCat.name;
      });
      this.setState({categoriesNameObject})
    }

    async componentDidMount() {
      await this._getCategories();
      await this._loadProfile();
    }

    _loadProfile = async() => {
      const {fiscal_year_id} = this.props
      let res = await Api.getProfile({fiscal_year_id: fiscal_year_id});
      let user = res.response;
      console.log(user);
      this.setState({user, isLoading: false});
    }

    _changePassword = async() => {
      const {user, password, confirmPassword} = this.state;
      if(password!==confirmPassword) {
        toast.error('पासवर्ड र पुन: पासवर्ड मिलेन', {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      let response = await Api.changeOwnPassword({password, password_confirmation: confirmPassword});
      if (response.code===200){
        toast.success('पासवर्ड परिवर्तन सफल', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      this._toggleChangePasswordModal();
      this.setState({password: '', confirmPassword: ''});
    }


    _toggleChangePasswordModal = async() => {
      this.setState({changePasswordModal: !this.state.changePasswordModal});
    }

    _renderProfileShow = () =>  {
      const {user, tooltipOpen, categoriesOption, changePasswordModal} = this.state;
      if(!user) return;
      return (
        <>
          <div className="user-control">
            <FaEdit onClick={()=> this._profileEditClick(user)} className="edit-action" color='#28a53d' />
            <FaKey onClick={()=> this._toggleChangePasswordModal()} className="password-action" color='#FFD700' />
          </div>
          <p>स्थानीय तह: {user.palika_name}</p>
          <Badge color={'primary'}>रोल: {user.role}</Badge>
          <p>नाम: {user.name}</p>
          <p>मेल: {user.email}</p>
          {user.created_at && <p>बनाइएको मिति: {user.created_at}</p>}
          {user.updated_at && <p>अद्यावधिकको मिति: {user.updated_at}</p>}
        </>   
      )
    }

    _renderProfileEdit = () =>  {
      const {user, categoriesNameObject} = this.state;
      if(!user) return;
      return (
        <Form>
          <FaEye onClick={()=> this._profileShowClick()} className="remove-action" color='#2572bc' />
          <FaKey onClick={()=> this._toggleChangePasswordModal()} className="password-action" color='#FFD700' />
          <p>स्थानीय तह: {user.palika_name}</p>
          <p>रोल: {user.role}</p>
          <FormGroup>
            <Label for="userName">नाम</Label>
            <Input type="text" name="userName" id="userName" placeholder="नाम" onChange={this.onNameChange} value={user.name || ""}/>
          </FormGroup>
          <FormGroup>
            <Label for="userMail">मेल</Label>
            <Input type="email" name="email" id="userMail" placeholder="मेल" onChange={this.onEmailChange} value={user.email || ""}/>
          </FormGroup>
         
          {(user.role==="Palika Data Entry" || user.role==="user") && //TODO category API CALL,
            <p>विषयक्षेत्रहरु: {user.categories.map(category=> <Badge color={'primary'}>{categoriesNameObject[category]}</Badge>)}</p>
          }
          {user.updated_by && <p>अद्यावधिकद्वारा: {user.updated_by}</p>}
          {user.updated_at && <p>अद्यावधिकको मिति: {user.updated_at}</p>}
          <Button onClick={this._onSubmit}>Submit</Button>
        </Form>
      )
    }

    _onSubmit = async () => {
      const {user} = this.state;
      if (!user.name || !user.email) {
        toast.error('कृपया फारमको सबै क्षेत्रहरू भर्नुहोस्', {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      delete user.categories;
      delete user.role;
      let response = await Api.updateProfile({...user});
      if(response.code===200){
        await this._loadProfile();
        toast.success('प्रफाइल अद्यावधिक सफल।', {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
  }

    _profileEditClick = () => {
      const { history } = this.props;
      history.push({
        pathname: `/profile/edit`,
      });
      this.setState({mode: 'edit'});
    }

    _profileShowClick = () => {
      const { history } = this.props;
      history.push({
        pathname: `/profile/show`,
      });
      this.setState({mode: 'show'});
    }

    onEmailChange = (e) => {
      this.setState({
        user: {...this.state.user, email: e.target.value}
      })
    }

    onNameChange = (e) => {
      this.setState({
          user: {...this.state.user, name: e.target.value}
      })
    }

    onPassChange = (e) => {
      this.setState({
          password: e.target.value
      })
    }

    onConfirmPassChange = (e) => {
      this.setState({
          confirmPassword: e.target.value
      })
    }
  
    render() {
      const { isLoading, showDeleteModal,changePasswordModal, mode } = this.state;  
      return (
        <MainLoader active={isLoading}>
          <section className="lisa-form">
              <ToastContainer />
              {mode==='show'?this._renderProfileShow():this._renderProfileEdit()}
          </section>

          <Modal isOpen={changePasswordModal} toggle={this._toggleChangePasswordModal} className="delete-modal">
            <ModalHeader toggle={this._toggleChangePasswordModal}>पासवर्ड परिवर्तन गर्नुहोस्</ModalHeader>
              <ModalBody>
              <FormGroup>
                <Label for="user-password">पासवर्ड</Label>
                <Input type="password" name="user-password" placeholder="पासवर्ड" value={this.state.password || ""} onChange={this.onPassChange}/>
              </FormGroup>
              <FormGroup>
                <Label for="user-re-password">पुन: पासवर्ड</Label>
                <Input type="password" name="user-re-password" placeholder="पुन: पासवर्ड" value={this.state.confirmPassword || ""} onChange={this.onConfirmPassChange}/>
              </FormGroup>
              </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={()=>{this._changePassword();}}>परिवर्तन गर्नुहोस्</Button>
                <Button color="red" onClick={this._toggleChangePasswordModal}>रद्द गर्नुहोस्</Button>
            </ModalFooter>
          </Modal>
        </MainLoader>
      );
    }
}

const mapDispatchToProps = (dispatch) => ({
  
});


const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  fiscal_year_id: auth.login.fiscal_year_id
});

export default connect(mapStateToProps)(Profile);
