import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Input, Button, Row, Col, FormGroup, Tooltip } from 'reactstrap';
import ReactTable from "react-table";
import { connect } from 'react-redux';
import './style.css';
import { changeIndexToNepali, changeNepaliIndexToEnglish } from '../../utils';
import Api from '../../services/api';
import MainLoader from '../../components/MainLoader';
import ReactExport from "react-export-excel";
import { BounceLoader } from 'react-spinners';
import { MdSelectAll } from 'react-icons/md';
import Select from 'react-select';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const stages = [{
  value: 'status_draft_date',
  label: 'प्रारम्भिक नतिजा तयार हुदैँ'
},
{
  value: 'status_in_review_date',
  label: 'पृष्ठपोषणका लागी पेश'
},
{
  value: 'status_feedback_date',
  label: 'पृष्ठपोषण सम्पन्न'
},
{
  value: 'status_reviewed_date',
  label: 'अनुमोदनका लागी पेश'
},
{
  value: 'status_approved_date',
  label: 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक'
},
{
  value: 'status_dcc_approved_date',
  label: 'गुणस्तर परिक्षण'
}]
class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinceData: [],
      data: [],
      resData: [],
      filteredPrData: [],
      filteredLgData: [],
      columns: null,
      palikaStatus: [],
      isLoading: true,
      isFilterLoading: false,
      searchInput: '',
      provinceColumns: null,
      filterData: [],
      filterDataColumn: null,
      isFilterDataLoading: false,
      fyId: 0,
      fyList: [],
      provinceId: 0,
      provinceList: [],
      districtId: 0,
      districtList: [],
      tooltipOpen: false,
      categoriesOption: stages,
      selectedCategories: [],
      selectedCategory: null,
      isStageFilterLoading: false,

      filterLgList: [],
      startDate: '',
      endDate: '',
      provinceIdFilter: 0,
      districtIdFilter: 0,
      districtListFilter: [],
      searchInputFilter: ''

    };
  }

  _getTotal = (array, key) => {
    const total = array.reduce(function (sum, current) {
      return sum + current['total_palikas'];
    }, 0);
    const sum = array.reduce(function (sum, current) {
      return sum + current[key];
    }, 0);
    if (key === 'total_palikas') {
      return changeIndexToNepali(total);
    } else {
      const percentage = (sum / total) * 100;
      return `${changeIndexToNepali(sum)} (${changeIndexToNepali(percentage.toFixed(2))} %)`
    }
  }

  _getIndividualTotal = (total_palika, stage_data) => {
    const percentage = (stage_data / total_palika) * 100;
    return ` (${changeIndexToNepali(percentage.toFixed(2))} %)`
  }



  async componentDidMount() {
    const { history, fiscal_year_id } = this.props;
    let localData = this.props.fiscal_year_id
    let fiscal = localStorage.getItem('fiscalYear');
    await this.setState({
      fyId: localData ? localData : fiscal
    })

    this._getFiscalyear();
    this._getProvinceList();

    let data = await Api.getPalikaDetailStiti({ fiscal_year_id: this.state.fyId });
    let provinceData = data.response.provinces;
    let lgData = data.response.lg;

    let columns = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    }, {
      Header: 'स्थानीय तह',
      id: 'name',
      accessor: d => {
        return (
          <span onClick={() => history.push(`/details/local/${d.gnid}`)} className="lg-name">{d.name}</span>
        );
      },
    }, {
      Header: 'जिल्ला',
      id: 'district',
      accessor: d => {
        return (
          <span className="lg-name">{d.district}</span>
        );
      },
    }, {
      Header: 'प्रदेश',
      id: 'province ',
      accessor: d => {
        return (
          <span className="lg-name">{d.province}</span>
        );
      },
    }, {
      Header: 'स्थिति',
      id: 'status',
      accessor: d => {
        return (
          <span className="lg-name">{d.status}</span>
        );
      },
    }, {
      Header: 'स्थिति मिति',
      id: 'status_date',
      accessor: d => {
        return (
          <span className="lg-name">{d.status_date}</span>
        );
      },
    }
    ];

    let provinceColumns = [{
      Header: 'प्रदेश',
      Footer: d => {
        return (
          <b bold className="text-bold">कुल संख्या (%)</b>
        )
      },
      id: 'id',
      accessor: d => {
        return (
          <span className="lg-name">{d.name}</span>
        );
      },
    }, {
      Header: 'जम्मा स्थानीय तह',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'total_palikas')}</b>
        )
      },
      id: 'total_palikas',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.total_palikas)}</span>
        );
      },
    }];

    let statusDraftDateCol = {
      Header: 'प्रारम्भिक नतिजा तयार हुदैँ',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_draft_date')}</b>
        )
      },
      id: 'status_draft_date ',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_draft_date)}{this._getIndividualTotal(d.total_palikas, d.status_draft_date)}</span>
        );
      },
    }

    let statusInReviewDateCol = {
      Header: 'पृष्ठपोषणका लागी पेश',
      id: 'status_in_review_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_in_review_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_in_review_date)}{this._getIndividualTotal(d.total_palikas, d.status_in_review_date)}</span>
        );
      },
    }

    let statusFeedbackDateCol = {
      Header: 'पृष्ठपोषण सम्पन्न',
      id: 'status_feedback_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_feedback_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_feedback_date)}{this._getIndividualTotal(d.total_palikas, d.status_feedback_date)}</span>
        );
      },
    }

    let statusReviewedDateCol = {
      Header: 'अनुमोदनका लागि पेश',
      id: 'status_reviewed_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_reviewed_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_reviewed_date)}{this._getIndividualTotal(d.total_palikas, d.status_reviewed_date)}</span>
        );
      },
    }

    let statusApprovedDateCol = {
      Header: 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_approved_date')}</b>
        )
      },
      id: 'status_approved_date',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_approved_date)}{this._getIndividualTotal(d.total_palikas, d.status_approved_date)}</span>
        );
      },
    }

    let statusDccApprovedDate = {
      Header: 'गुणस्तर परिक्षण',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_dcc_approved_date')}</b>
        )
      },
      id: 'status_dcc_approved_date',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_dcc_approved_date)}{this._getIndividualTotal(d.total_palikas, d.status_dcc_approved_date)}</span>
        );
      },
    }

    let remainingCol = {
      Header: 'बाँकी प्रगति',
      id: 'remaining',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'remaining')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.remaining)}{this._getIndividualTotal(d.total_palikas, d.remaining)}</span>
        );
      },
    }


    let filterDataColumn = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    },
    {
      Header: 'जिल्ला',
      id: 'district',
      accessor: d => {
        return (
          <span className="lg-name">{d.district}</span>
        );
      },
    },
    {
      Header: 'प्रदेश',
      id: 'province',
      accessor: d => {
        return (
          <span className="lg-name">{d.province}</span>
        );
      },
    },
    {
      Header: 'स्थानीय तह',
      id: 'name',
      accessor: d => {
        return (
          <span className="lg-name">{d.name}</span>
        );
      },
    }];

    let statusDraftDateColFilter = {
      Header: 'प्रारम्भिक नतिजा तयार हुदैँ',
      id: 'status_draft_date ',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'प्रारम्भिक नतिजा तयार हुदैँ')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusInReviewDateColFilter = {
      Header: 'प्रारम्भिक नतिजा पेश',
      id: 'status_in_review_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'प्रारम्भिक नतिजा पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusFeedbackDateColFilter = {
      Header: 'पृष्ठपोषणका लागी पेश',
      id: 'status_feedback_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'पृष्ठपोषणका लागी पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusReviewedDateColFilter = {
      Header: 'अनुमोदनका लागि पेश',
      id: 'status_reviewed_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'अनुमोदनका लागि पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusApprovedDateColFilter = {
      Header: 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक',
      id: 'status_approved_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusDccApprovedDateFilter = {
      Header: 'गुणस्तर परिक्षण',
      id: 'status_dcc_approved_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'गुणस्तर परिक्षण')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }


    this.setState(
      {
        data: lgData,
        filteredLgData: lgData,
        filterLgList: lgData,
        resData: data.response,
        provinceData,
        columns: columns,
        isLoading: false,
        provinceColumns: [...provinceColumns, statusDraftDateCol, statusInReviewDateCol, statusFeedbackDateCol, statusReviewedDateCol, statusApprovedDateCol, statusDccApprovedDate, remainingCol],
        filterDataColumn: [...filterDataColumn, statusDraftDateColFilter, statusInReviewDateColFilter, statusFeedbackDateColFilter, statusReviewedDateColFilter, statusApprovedDateColFilter, statusDccApprovedDateFilter]
      }
    )
  }

  _getFilteredStatusData = async (params) => {
    this.setState({
      isFilterLoading: true
    })
    let districtName;
    let provinceName;
    if (params.district_id) {
      districtName = this.state.districtList.find(x => x.id.toString() === params.district_id.toString()).name
    }
    if (params.province_id) {
      provinceName = this.state.provinceList.find(x => x.id.toString() === params.province_id.toString()).name
    }

    let filteredData
    if (params.district_id) {
      filteredData = this.state.filteredLgData.filter(x => x.district === districtName)
    } else if (params.province_id) {
      filteredData = this.state.filteredLgData.filter(x => x.province === provinceName)
    } else {
      filteredData = this.state.filteredLgData
    }
    // let data = await Api.getReportData(params);
    if (filteredData) {
      this.setState({ data: filteredData })
    }

    this.setState({
      isFilterLoading: false
    })
  }

  _import2Excel = () => {
    const { data, columns } = this.state;
    let filteredData = this._getFilteredData();
    let excelColumns = [
      <ExcelColumn key="name" label="स्थानीय तह" value="name" />,
      <ExcelColumn key="district" label="जिल्ला" value="district" />,
      <ExcelColumn key="province" label="प्रदेश" value="province" />,
      <ExcelColumn key="status" label="स्थिति" value="status" />,
      <ExcelColumn key="status_date" label="स्थिति मिति" value="status_date" />,
    ];
    return (
      <ExcelFile element={<Button color="primary">डाउनलोड</Button>}>
        <ExcelSheet data={filteredData} name="स्थानीय तह स्थिति टेबल">
          {excelColumns}
        </ExcelSheet>
      </ExcelFile>
    );
  }

  _getFilteredData = () => {
    const { data, searchInput } = this.state;
    const searchKeys = ['name', 'province', 'district', 'status'];
    if (searchInput) {
      let filteredData = data.filter(d => {
        let found = false;
        searchKeys.map(searchKey => {
          if (d[searchKey].toString().includes(searchInput)) found = true;
        });
        return found;
      });
      return filteredData;
    }
    return data;
  }

  _getFiscalyear = async () => {
    let fyList = await Api.getFyList();
    this.setState({ fyList: fyList.response });
  }

  _handleFiscalYearChange = async (e) => {
    this.setState(
      {
        isLoading: true,
      }
    )
    let fiscalYearId = e.target.value
    this.setState({
      fyId: fiscalYearId,
      provinceId: 0,
      districtId: 0,
      districtList: []
    });

    localStorage.setItem('fiscalYear', fiscalYearId);
    let data = await Api.getPalikaDetailStiti({ fiscal_year_id: fiscalYearId });
    let provinceData = data.response.provinces;
    let lgData = data.response.lg;

    this.setState(
      {
        data: lgData,
        filteredLgData: lgData,
        resData: data.response,
        provinceData,
        isLoading: false,
      }
    )
  }

  _getProvinceList = async () => {
    let provinceListRes = await Api.getProvinceList();

    this.setState({
      provinceList: provinceListRes.response,
    });
  }

  _handleProvinceChange = (e) => {
    let provinceId = e.target.value
    this.setState({
      provinceId: provinceId,
      districtId: 0,
    });
    this._getDistrictList(provinceId);
    this._getFilteredStatusData({ province_id: provinceId, fiscal_year_id: this.state.fyId });
  }

  _getDistrictList = async (provinceId) => {
    let districtListRes = await Api.getDistrictList(provinceId);

    this.setState({
      districtList: districtListRes.response,
    });
  }

  _handleDistrictChange = (e) => {
    let districtId = e.target.value
    this.setState({
      districtId: districtId,
    });
    this._getFilteredStatusData({ district_id: districtId, fiscal_year_id: this.state.fyId });
  }

  _onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
  }

  _getStageName = (stageKey) => {
    let stage
    switch (stageKey) {
      case 'status_draft_date':
        stage = "प्रारम्भिक नतिजा तयार हुदैँ"
        break;
      case 'status_in_review_date':
        stage = "पृष्ठपोषणका लागी पेश"
        break;
      case 'status_feedback_date':
        stage = "पृष्ठपोषण सम्पन्न"
        break;
      case 'status_reviewed_date':
        stage = "अनुमोदनका लागी पेश"
        break;
      case 'status_approved_date':
        stage = "अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक"
        break;
      case 'status_dcc_approved_date':
        stage = "गुणस्तर परिक्षण"
        break;
      default:
        stage = ''
        break;
    }
    return stage;
  }

  _handleCategoryChange = async (selectedOption) => {
    this.setState({ selectedCategories: selectedOption, isStageFilterLoading: true, });

    const selectedValues = selectedOption && selectedOption.length > 0 ? selectedOption.map(x => `${x.value}`) : []
    this.setState({ selectedValues: selectedValues })

    const stageName = selectedValues.map(x => this._getStageName(x))
    if (stageName.length > 0) {
      const lgData = this.state.resData.lg.filter(x => stageName.includes(x.status))
      this.setState({
        data: lgData,
        filteredLgData: lgData,
        provinceId: 0,
        districtId: 0,
      })
    } else {
      this.setState({
        data: this.state.resData.lg,
        filteredLgData: this.state.resData.lg,
        provinceId: 0,
        districtId: 0,
      })
    }

    let provinceColumns = [{
      Header: 'प्रदेश',
      Footer: d => {
        return (
          <b bold className="text-bold">कुल संख्या (%)</b>
        )
      },
      id: 'id',
      accessor: d => {
        return (
          <span className="lg-name">{d.name}</span>
        );
      },
    }, {
      Header: 'जम्मा स्थानीय तह',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'total_palikas')}</b>
        )
      },
      id: 'total_palikas',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.total_palikas)}</span>
        );
      },
    }];

    let statusDraftDateCol = {
      Header: 'प्रारम्भिक नतिजा तयार हुदैँ',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_draft_date')}</b>
        )
      },
      id: 'status_draft_date ',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_draft_date)}{this._getIndividualTotal(d.total_palikas, d.status_draft_date)}</span>
        );
      },
    }

    let statusInReviewDateCol = {
      Header: 'पृष्ठपोषणका लागी पेश',
      id: 'status_in_review_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_in_review_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_in_review_date)}{this._getIndividualTotal(d.total_palikas, d.status_in_review_date)}</span>
        );
      },
    }

    let statusFeedbackDateCol = {
      Header: 'पृष्ठपोषण सम्पन्न',
      id: 'status_feedback_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_feedback_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_feedback_date)}{this._getIndividualTotal(d.total_palikas, d.status_feedback_date)}</span>
        );
      },
    }

    let statusReviewedDateCol = {
      Header: 'अनुमोदनका लागि पेश',
      id: 'status_reviewed_date',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_reviewed_date')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_reviewed_date)}{this._getIndividualTotal(d.total_palikas, d.status_reviewed_date)}</span>
        );
      },
    }

    let statusApprovedDateCol = {
      Header: 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_approved_date')}</b>
        )
      },
      id: 'status_approved_date',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_approved_date)}{this._getIndividualTotal(d.total_palikas, d.status_approved_date)}</span>
        );
      },
    }

    let statusDccApprovedDate = {
      Header: 'गुणस्तर परिक्षण',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'status_dcc_approved_date')}</b>
        )
      },
      id: 'status_dcc_approved_date',
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.status_dcc_approved_date)}{this._getIndividualTotal(d.total_palikas, d.status_dcc_approved_date)}</span>
        );
      },
    }

    let remainingCol = {
      Header: 'बाँकी प्रगति',
      id: 'remaining',
      Footer: d => {
        return (
          <b className="lg-name">{this.state.provinceData.length > 0 && this._getTotal(this.state.provinceData, 'remaining')}</b>
        )
      },
      accessor: d => {
        return (
          <span className="lg-name">{changeIndexToNepali(d.remaining)}{this._getIndividualTotal(d.total_palikas, d.remaining)}</span>
        );
      },
    }

    let proCol = provinceColumns
    selectedValues.includes('status_draft_date') | selectedValues.length === 0 && await proCol.push(statusDraftDateCol);
    selectedValues.includes('status_in_review_date') | selectedValues.length === 0 && await proCol.push(statusInReviewDateCol);
    selectedValues.includes('status_feedback_date') | selectedValues.length === 0 && await proCol.push(statusFeedbackDateCol);
    selectedValues.includes('status_reviewed_date') | selectedValues.length === 0 && await proCol.push(statusReviewedDateCol);
    selectedValues.includes('status_approved_date') | selectedValues.length === 0 && await proCol.push(statusApprovedDateCol);
    selectedValues.includes('status_dcc_approved_date') | selectedValues.length === 0 && await proCol.push(statusDccApprovedDate);
    selectedValues.length > 0 && proCol.push(remainingCol);

    this.setState(
      {
        isStageFilterLoading: false,
        provinceColumns: proCol
      }
    )
  };

  _selectAllCategory = () => {
    this.setState({ selectedCategories: this.state.categoriesOption });
    this._handleCategoryChange(this.state.categoriesOption);
  }

  _toggleToolTip = () => this.setState({ tooltipOpen: !this.state.tooltipOpen });


  renderProvinceStatus = () => {
    const { provinceData, provinceColumns, selectedCategories, categoriesOption, fyId, fyList } = this.state;
    return (
      <div className="province-status-wrapper">
        <h3 className="sub-heading">प्रदेश स्थिति टेबल</h3>
        <div className="row d-flex justify-content-end">
          <div className="col-sm-3">
            <FormGroup>
              <Input type="select" name="fselect" id="fiscalYearSelect" value={fyId} onChange={(e) => this._handleFiscalYearChange(e)}>
                <option value={0} disabled selected>आर्थिक वर्ष चयन गार्नुहोस</option>
                {
                  fyList.length > 0 &&
                  fyList.map((fy, index) => {
                    return (
                      <option key={fy.id} value={fy.id} >आर्थिक वर्ष {fy.name}</option>
                    );
                  })
                }
              </Input>
            </FormGroup>
          </div>
          <div className="col-sm-6">
            <FormGroup>
              <div className="select-cat-wrapper">
                <Select
                  value={selectedCategories}
                  onChange={this._handleCategoryChange}
                  className="select-cat-multi"
                  isMulti={true}
                  options={categoriesOption}
                  placeholder="स्थितिहरू द्वारा फिल्टर गर्नुहोस्"
                />
                <MdSelectAll className="select-all-icon" id="cat-select-all" onClick={() => this._selectAllCategory()} />
                <Tooltip placement="top" isOpen={this.state.tooltipOpen} autohide={false} target="cat-select-all" toggle={this._toggleToolTip}>
                  सबै स्थितिहरू छान्नुहोस्
                </Tooltip>
              </div>
            </FormGroup>
          </div>
        </div>
        {this.state.isStageFilterLoading ? (<div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>) : (
          <>
            {provinceData.length > 0 &&
              <ReactTable
                data={provinceData}
                columns={provinceColumns}
                defaultPageSize={7}
                showPagination={false}
                className="-striped -highlight"
              />
            }
          </>
        )}
      </div>
    );
  }

  render() {
    const { data, columns, isLoading, provinceId, provinceList, districtList, districtId, } = this.state;
    return (
      <MainLoader active={isLoading}>
        <div className="">
          <div className="content">
            {this.renderProvinceStatus()}
            <div className="lg-status-wrapper">
              <h3 className="sub-heading">स्थानीय तह स्थिति टेबल</h3>
              <>
                <Row className="d-flex justify-content-end">
                  <Col sm={2}>
                    <FormGroup>
                      <Input type="select" placeholder="प्रदेश चयन गर्नुहोस" name="provinceId" id="provinceId" value={provinceId} onChange={(e) => this._handleProvinceChange(e)}>
                        <option value={0} disabled selected>प्रदेश चयन गर्नुहोस</option>
                        {
                          provinceList.length > 0 &&
                          provinceList.map((province, index) => {
                            return (
                              <option key={province.id} value={province.id} >{province.name}</option>
                            );
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={2}>
                    <FormGroup>
                      <Input type="select" placeholder="जिल्ला चयन गर्नुहोस" name="districtId" id="districtId" value={districtId} onChange={(e) => this._handleDistrictChange(e)}>
                        <option value={0} disabled selected>जिल्ला चयन गर्नुहोस</option>
                        {
                          districtList.length > 0 &&
                          districtList.map((district, index) => {
                            return (
                              <option key={district.id} value={district.id} >{district.name}</option>
                            );
                          })
                        }
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={3}>
                    <Input className="" type="text" name="search" placeholder="खोजी" value={this.state.searchInput || ""} onChange={this._onSearchInputChange} />
                  </Col>
                  <Col sm={2}>{this._import2Excel()}</Col>

                </Row>
                {this.state.isFilterLoading ? (<div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>) : (
                  data.length > 0 &&
                  <>
                    <ReactTable
                      data={this._getFilteredData()}
                      columns={columns}
                      minRows={0}
                      defaultPageSize={this._getFilteredData().length < 10 ? this._getFilteredData().length : 10}
                      className="-striped -highlight"
                    />
                  </>
                )}

              </>

            </div>
            {this.renderDateFilterData()}
          </div>
        </div>
      </MainLoader>
    );
  }


  _handleFilterChange = async (selectedOption) => {
    await this.setState({ selectedCategory: selectedOption, isFilterDataLoading: true, });

    this._handleFilterDataChange(this.state.startDate, this.state.endDate, this.state.provinceIdFilter, this.state.districtIdFilter);

    const selectedValue = selectedOption ? selectedOption.value : null

    let filterDataColumn = [
      {
        Header: 'क्र.सं.',
        maxWidth: 50,
        id: 'id',
        Cell: (row) => {
          return changeIndexToNepali(row.index + 1);
        }
      },
      {
        Header: 'जिल्ला',
        id: 'district',
        accessor: d => {
          return (
            <span className="lg-name">{d.district}</span>
          );
        },
      },
      {
        Header: 'प्रदेश',
        id: 'province',
        accessor: d => {
          return (
            <span className="lg-name">{d.province}</span>
          );
        },
      },
      {
        Header: 'स्थानीय तह',
        id: 'name',
        accessor: d => {
          return (
            <span className="lg-name">{d.name}</span>
          );
        },
      }];

    let statusDraftDateCol = {
      Header: 'प्रारम्भिक नतिजा तयार हुदैँ',
      id: 'status_draft_date ',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'प्रारम्भिक नतिजा तयार हुदैँ')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusInReviewDateCol = {
      Header: 'प्रारम्भिक नतिजा पेश',
      id: 'status_in_review_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'प्रारम्भिक नतिजा पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusFeedbackDateCol = {
      Header: 'पृष्ठपोषणका लागी पेश',
      id: 'status_feedback_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'पृष्ठपोषणका लागी पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusReviewedDateCol = {
      Header: 'अनुमोदनका लागि पेश',
      id: 'status_reviewed_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'अनुमोदनका लागि पेश')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusApprovedDateCol = {
      Header: 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक',
      id: 'status_approved_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let statusDccApprovedDate = {
      Header: 'गुणस्तर परिक्षण',
      id: 'status_dcc_approved_date',
      accessor: d => {
        const status = d.statuses.find((status) => status.name === 'गुणस्तर परिक्षण')
        return (
          <span className="lg-name">{changeIndexToNepali(status ? status.date : '')}</span>
        );
      },
    }

    let proCol = filterDataColumn
    selectedValue == 'status_draft_date' | !selectedValue && await proCol.push(statusDraftDateCol);
    selectedValue == 'status_in_review_date' | !selectedValue && await proCol.push(statusInReviewDateCol);
    selectedValue == 'status_feedback_date' | !selectedValue && await proCol.push(statusFeedbackDateCol);
    selectedValue == 'status_reviewed_date' | !selectedValue && await proCol.push(statusReviewedDateCol);
    selectedValue == 'status_approved_date' | !selectedValue && await proCol.push(statusApprovedDateCol);
    selectedValue == 'status_dcc_approved_date' | !selectedValue && await proCol.push(statusDccApprovedDate);

    this.setState(
      {
        isFilterDataLoading: false,
        filterDataColumn: proCol
      }
    )
  };

  _handleFilterDataChange = async (startDate, endDate, provinceId, districtId) => {
    const min = await startDate && Date.parse(startDate);
    const max = await endDate && Date.parse(endDate);
    let data = this.state.resData.lg

    if (startDate && endDate) {
      if (this.state.selectedCategory) {
        data = await data && data.length > 0 && data.filter((d) => (d.statuses.find(item => (this.state.selectedCategory.label == item.name && (Date.parse(item.date) >= min && Date.parse(item.date) <= max)))))
      } else {
        data = await data && data.length > 0 && data.filter((d) => (d.statuses.some(item => (Date.parse(item.date) >= min && Date.parse(item.date) <= max))))
      }
    }

    let districtName;
    let provinceName;
    if (districtId) {
      districtName = await this.state.districtListFilter.find(x => x.id.toString() === districtId.toString()).name
    }
    if (provinceId) {
      provinceName = await this.state.provinceList.find(x => x.id.toString() === provinceId.toString()).name
    }

    if (districtId) {
      data = await data.filter(x => x.district === districtName)
    } else if (provinceId) {
      data = await data.filter(x => x.province === provinceName)
    } else {
      data = data
    }

    this.setState({
      filterLgList: data
    })
  }

  _handleProvinceChangeFilter = (e) => {
    let provinceId = e.target.value
    this.setState({
      provinceIdFilter: provinceId,
      districtIdFilter: 0,
    });
    this._getDistrictListFilter(provinceId);
    this._handleFilterDataChange(this.state.startDate, this.state.endDate, provinceId, this.state.districtIdFilter);
  }

  _getDistrictListFilter = async (provinceId) => {
    let districtListRes = await Api.getDistrictList(provinceId);

    this.setState({
      districtListFilter: districtListRes.response,
    });
  }

  _handleDistrictChangeFilter = (e) => {
    let districtId = e.target.value
    this.setState({
      districtIdFilter: districtId,
    });
    this._handleFilterDataChange(this.state.startDate, this.state.endDate, this.state.provinceIdFilter, districtId);
  }

  _onSearchInputChangeFilter = (e) => {
    this.setState({ searchInputFilter: e.target.value });
  }

  _getFilteredDataFilter = () => {
    const { filterLgList, searchInputFilter } = this.state;
    const searchKeys = ['name', 'province', 'district', 'status'];
    if (searchInputFilter) {
      let filteredData = filterLgList.filter(d => {
        let found = false;
        searchKeys.map(searchKey => {
          if (d[searchKey].toString().includes(searchInputFilter)) found = true;
        });
        return found;
      });
      return filteredData;
    }
    return filterLgList;
  }

  _handleResetFilter = async () => {
    await this.setState({
      provinceIdFilter: 0,
      districtIdFilter: 0,
      districtList: [],
      searchInputFilter: '',
      selectedCategory: null,
      startDate: null,
      endDate: null,
      filterLgList: this.state.resData.lg
    })
    this._handleFilterChange(null);
  }

  _import2ExcelFilter = () => {
    const filteredData = this._getFilteredDataFilter();

    let data = filteredData.map((item) => {
      return {
        name: item.name,
        status_draft_date: item.statuses.find((status) => status.name == 'प्रारम्भिक नतिजा पेश') && item.statuses.find((status) => status.name == 'प्रारम्भिक नतिजा पेश').date,
        status_in_review_date: item.statuses.find((status) => status.name == 'पृष्ठपोषणका लागी पेश') && item.statuses.find((status) => status.name == 'पृष्ठपोषणका लागी पेश').date,
        status_feedback_date: item.statuses.find((status) => status.name == 'पृष्ठपोषण सम्पन्न') && item.statuses.find((status) => status.name == 'पृष्ठपोषण सम्पन्न').date,
        status_reviewed_date: item.statuses.find((status) => status.name == 'अनुमोदनका लागी पेश') && item.statuses.find((status) => status.name == 'अनुमोदनका लागी पेश').date,
        status_approved_date: item.statuses.find((status) => status.name == 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक') && item.statuses.find((status) => status.name == 'अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक').date,
        status_dcc_approved_date: item.statuses.find((status) => status.name == 'गुणस्तर परिक्षण') && item.statuses.find((status) => status.name == 'गुणस्तर परिक्षण').date,
      }
    })

    let excelColumnsFilter = [
      <ExcelColumn key="name" label="स्थानीय तह" value="name" />,
      <ExcelColumn key="status_draft_date" label="प्रारम्भिक नतिजा पेश" value="status_draft_date" />,
      <ExcelColumn key="status_in_review_date" label="पृष्ठपोषणका लागी पेश" value="status_in_review_date" />,
      <ExcelColumn key="status_feedback_date" label="पृष्ठपोषण सम्पन्न" value="status_feedback_date" />,
      <ExcelColumn key="status_reviewed_date" label="अनुमोदनका लागी पेश" value="status_reviewed_date" />,
      <ExcelColumn key="status_approved_date" label="अन्तिम नतिजा कार्यपालिकाबाट अनुमोदन भइ सार्वजनिक" value="status_approved_date" />,
      <ExcelColumn key="status_dcc_approved_date" label="गुणस्तर परिक्षण" value="status_dcc_approved_date" />,
    ];

    return (
      <ExcelFile element={<Button color="primary">डाउनलोड</Button>}>
        <ExcelSheet data={data} name="स्थानीय तह स्थिति टेबल">
          {excelColumnsFilter}
        </ExcelSheet>
      </ExcelFile>
    );
  }

  renderDateFilterData = () => {
    const { filterLgList, filterDataColumn, selectedCategory, categoriesOption, startDate, endDate, provinceIdFilter, districtIdFilter, provinceList, districtListFilter, searchInputFilter } = this.state;
    return (
      <div className="province-status-wrapper mt-5">
        <h3 className="sub-heading">स्थानीय तह टेबल</h3>
        <Row>
          <Col sm={6}>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Input type="select" placeholder="प्रदेश चयन गर्नुहोस" name="provinceIdFilter" id="provinceIdFilter" value={provinceIdFilter} onChange={(e) => this._handleProvinceChangeFilter(e)}>
                    <option value={0} disabled selected>प्रदेश चयन गर्नुहोस</option>
                    {
                      provinceList.length > 0 &&
                      provinceList.map((province, index) => {
                        return (
                          <option key={province.id} value={province.id} >{province.name}</option>
                        );
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Input type="select" placeholder="जिल्ला चयन गर्नुहोस" name="districtIdFilter" id="districtIdFilter" value={districtIdFilter} onChange={(e) => this._handleDistrictChangeFilter(e)}>
                    <option value={0} disabled selected>जिल्ला चयन गर्नुहोस</option>
                    {
                      districtListFilter.length > 0 &&
                      districtListFilter.map((district, index) => {
                        return (
                          <option key={district.id} value={district.id} >{district.name}</option>
                        );
                      })
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={6}>
            <div className='d-flex justify-content-end'>
              <button className='btn btn-danger' onClick={this._handleResetFilter}>Reset All Filter</button>
              <span className='ml-2'>{this._import2ExcelFilter()}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <FormGroup>
              <Select
                value={selectedCategory}
                onChange={this._handleFilterChange}
                className="select-cat-multi"
                options={categoriesOption}
                placeholder="स्थिति द्वारा फिल्टर गर्नुहोस्"
                isClearable
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <Calendar
                value={startDate}
                className="form-control bg-white"
                placeholder='सुरु मिति चयन गर्नुहोस्'
                language="en"
                hideDefaultValue
                onChange={({ bsDate, adDate }) => {
                  this.setState({ startDate: bsDate });
                  this._handleFilterDataChange(bsDate, this.state.endDate, this.state.provinceIdFilter, this.state.districtIdFilter)
                }} />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <FormGroup>
              <Calendar
                className="form-control bg-white"
                value={endDate}
                placeholder='अन्त्य मिति चयन गर्नुहोस्'
                language="en"
                hideDefaultValue
                onChange={({ bsDate, adDate }) => {
                  this.setState({ endDate: bsDate });
                  this._handleFilterDataChange(this.state.startDate, bsDate, this.state.provinceIdFilter, this.state.districtIdFilter)
                }}
              />
            </FormGroup>
          </Col>
          <Col sm={3}>
            <Input className="" type="text" name="searchFilter" placeholder="खोजी" value={this.state.searchInputFilter || ""} onChange={this._onSearchInputChangeFilter} />
          </Col>
        </Row>


        {this.state.isFilterDataLoading ? (<div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>) : (
          <>
            {filterLgList && filterLgList.length > 0 ?
              <ReactTable
                data={this._getFilteredDataFilter()}
                columns={filterDataColumn}
                minRows={0}
                defaultPageSize={this._getFilteredDataFilter().length > 10 ? 10 : this._getFilteredDataFilter().length}
                className="-striped -highlight"
              />
              : <div>यी फिल्टरहरूको लागि कुनै डाटा छैन</div>
            }
          </>
        )}
      </div>
    );
  }
}


const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  fiscal_year_id: auth.login.fiscal_year_id
});

export default connect(mapStateToProps)(Report);
