import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import store from "../../store";
import Api from "../../services/api";
import CommentList from "./CommentList";
import { ToastContainer, toast } from "react-toastify";

export default function Comment({ count, q_id, user, link }) {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  let location = window.location.href;
  let gnId = location.substring(location.length - 5);
  const [comment, setComment] = useState("");
  const toggle = () => setModal(!modal);
  const state = store.getState();
  const [data, setData] = useState([]);
  const [posted, setPosted] = useState(1);

  let datas, status;
  const sendComment = async () => {
    setLoading(true);
    datas = await Api.postComment({
      fiscal_year_id: user.fiscal_year_id,
      question_id: q_id,
      // gnid: gnId,
      minsitry_id: sessionStorage.getItem("m_id"),
      message: comment,
    });
    if (datas.code === 200) {
      toast.success(datas.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setComment("");
      setLoading(false);
      setPosted(posted + 1);
    } else if (datas.code === 201) {
      toast.error(datas.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    } else {
      toast.error(datas.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }
  };
  const onChangeComment = (e) => {
    setComment(e.target.value);
  };
  return (
    <div className="comment-box" onClick={toggle}>
      {link ? (
        <button type="link" className="btn btn-link">
          सबै पृष्ठपोषण
        </button>
      ) : (
        <button type="button" className="btn btn-primary">
          <i className="far fa-comments" />
          पृष्ठपोषण गर्नुहोस्। ({count})
        </button>
      )}
      <Modal
        returnFocusAfterClose={false}
        isOpen={modal}
        toggle={toggle}
        className={"modal-lg modal-dialog-centered comments-wrap"}
      >
        <ModalHeader toggle={toggle}>पृष्ठपोषण गर्नुहोस्।</ModalHeader>
        <ModalBody>
          <CommentList
            fy={user.fiscal_year_id}
            q_id={q_id}
            gnid={gnId}
            posted={posted}
          />
        </ModalBody>
        <ModalFooter>
          <div className="comment-input col-md-12">
            <input
              onChange={onChangeComment}
              placeholder="Add Comment"
              type="textarea"
              className="px-2"
              value={comment}
            ></input>
            <button
              className="uni-menu-item btn btn-secondary ml-md-2"
              onClick={sendComment}
              disabled={
                comment === "" || loading
                  ? true
                  : false || user.entry_status === 0
              }
            >
              {loading ? "Posting..." : "Post Comment"}
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
