import moment from 'moment'
import React, { useState } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Api from '../../services/api'
import { FaSpinner } from "react-icons/fa";


const CommentItem = ({ comment, loadComments }) => {

  const [editLoading, setEditLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const [isEditing, setIsEditing] = useState(false)
  const toggleEdit = () => setIsEditing(!isEditing)

  const [commentValue, setCommentValue] = useState(comment.message);

  const [deleteModal, setDeleteModal] = useState(false)
  const toggleDeleteModal = () => setDeleteModal(!deleteModal)

  const handleEdit = async () => {
    setEditLoading(true)
    const res = await Api.editComment({ message: commentValue }, comment.id);
    if (res.code == 200) {
      toggleEdit();
      loadComments()
    }
    setEditLoading(false)
  }

  const handleDelete = async () => {
    setDeleteLoading(true)
    const res = await Api.deleteComment(comment.id);
    if (res.code == 200) {
      toggleDeleteModal()
      loadComments()
    }
    setDeleteLoading(false)
  }

  const onChangeComment = (e) => {
    setCommentValue(e.target.value)
  }

  return (
    <div className="card rounded m-2 p-2 bg-light">
      {isEditing ?
        <>
          <input
            onChange={onChangeComment}
            placeholder="Add Comment"
            type="textarea"
            className="px-2 form-control"
            value={commentValue}
          ></input>
          <div className='d-flex justify-content-end mt-2'>
            <button className='btn btn-sm btn-primary' onClick={handleEdit} disabled={commentValue === '' || editLoading ? true : false}>Save Comment  {editLoading && <FaSpinner className="rotating" />}</button>
            <button className='btn btn-sm btn-secondary ml-2' onClick={() => { toggleEdit(); setCommentValue(comment.message); }} >Cancel</button>
          </div>
        </> :
        <>
          <div className="d-flex flex-column">
            <p className="m-0">{comment.message}</p>
            <div className='d-flex'>
              <div className="flex-grow-1">
                <small className="text-primary">{comment.created_by}</small>
                <small>&nbsp; at &nbsp;</small>
                <small className="text-muted">{comment.created_at && moment(comment.created_at).format('DD MMM YYYY, HH:mm A')}</small>
              </div>
              <div>
                <FaEdit className="small pointer mr-2" color='#2f7de2' onClick={toggleEdit} />
                <FaTrash className="small pointer" color='#fa2b2b' onClick={toggleDeleteModal} />
              </div>
            </div>

          </div>
        </>
      }

      <Modal
        returnFocusAfterClose={false}
        isOpen={deleteModal}
        toggle={toggleDeleteModal}
        className="modal-sm modal-dialog-centered"
      >
        <ModalHeader toggle={toggleDeleteModal}>पृष्ठपोषण गर्नुहोस्।</ModalHeader>
        <ModalBody className="overflow-hidden">
          के तपाईँ यो पृष्ठपोषण हटाउन चाहनुहुन्छ?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>पृष्ठपोषण हटाउनुहोस् {deleteLoading && <FaSpinner className="rotating" />}</Button>
          <Button color="light" onClick={toggleDeleteModal}>रद्द गर्नुहोस्</Button>
        </ModalFooter>
      </Modal>
    </div >
  )
}

export default CommentItem
