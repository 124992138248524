import React, { useState, useEffect } from "react";
import Api from '../../services/api'
import CommentItem from "./CommentItem";

export default function CommentList({
  fy, q_id, gnid, posted
}) {
  const [commentData, setCommentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const _loadComments = async () => {
    setLoading(true)
    const res = await Api.getComments(fy, q_id, gnid);
    if (res.code == 200) {
      setCommentData(res.response)
    }
    setLoading(false)
  }

  useEffect(() => {
    _loadComments();
  }, [posted]);

  return (
    <>
      {loading ?
        <p>Loading...</p> :
        commentData.length === 0 ?
          <p>No Comments to show.</p> :
          <div>
            {commentData.map(item =>
              <CommentItem comment={item} loadComments={_loadComments} />
            )}
          </div>
      }
    </>
  )
}
