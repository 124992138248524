import React, { Component } from "react";
import { FaTimesCircle } from 'react-icons/fa';
import ReactDropzone from "react-dropzone";
import Api from '../../services/api';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import './style.css';

class FilePreview extends Component {
  constructor(props){
    super(props);
    this.state = {
      deleteModal: false
    }
    this.toggleModal=this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({deleteModal: !this.state.deleteModal})
  }

  render() {
    let { deleteModal } = this.state;
    let { url, onRemove, dId } = this.props;
    let docName = url.substr(url.lastIndexOf('/') + 1);
    return (
      <div className="preview-wrapper">
        <a href={url} target="_blank">
          <span className="preview-name">
            {docName}
          </span>
        </a>
        <FaTimesCircle className="remove-icon" onClick={()=>this.toggleModal()}/>
        <Modal isOpen={deleteModal} toggle={this.toggleModal} className="delete-modal">
            <ModalHeader toggle={this.toggle}>फाईल हटाउनुहोस्</ModalHeader>
            <ModalBody>
                के तपाईँ यो फाईल हटाउन चाहनुहुन्छ?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={()=>{onRemove(docName, dId);this.toggleModal();}}>फाईल हटाउनुहोस्</Button>
                <Button color="red" onClick={this.toggleModal}>रद्द गर्नुहोस्</Button>
            </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default (FilePreview);