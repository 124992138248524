import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "./Dashboard";
import SelfAssessmentForm from "./SelfAssessmentForm";
import Manual from "./Manual";
import User from "./User";
import PageNotFound from "./PageNotFound";
import UserDetail from "./UserDetail";
import Profile from "./Profile";

import "./index.css";
class AuthenticatedMainLayout extends Component {
  render() {
    let { user } = this.props;
    let isAdmin;
    let isDistrictAdmin;
    let isQA;
    if (user.role === "Palika Admin (Focal Person)") {
      isAdmin = true;
    }
    if (user.role === "District Admin") {
      isDistrictAdmin = true;
    }
    if (user.role === "QA Agent") {
      isQA = true;
    }
    if (this.props.location.pathname === "/") {
      return (
        <Redirect
          to={{
            pathname:
              user.hasSubmitted || isQA
                ? "/dashboard"
                : `/selfassessmentform/${user.gnid}`,
            state: { from: this.props.location },
          }}
        />
      );
    }
    return (
      <div className="container-fluid login-container-row">
        <div className="row">
          <div className="col-md-12 col-xs-12 authenticated-container">
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route
                path="/selfassessmentform/:gnid"
                component={SelfAssessmentForm}
              />
              <Route exact path="/user/:id/:mode" component={UserDetail} />
              <Route exact path="/manual" component={Manual} />
              <Route exact path="/profile/:mode" component={Profile} />
              {(isAdmin || isDistrictAdmin) && (
                <Route path="/user" component={User} />
              )}
              <Route component={PageNotFound} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

export default connect(mapStateToProps)(AuthenticatedMainLayout);

// const mapStateToProps = (store) => { return {store: store}}
// const mapDispatchToProps = (dispatch) => { return {dispatch}}
//
// export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedMainLayout);
