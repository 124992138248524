import React, { Component } from "react";
import { reduxForm } from "redux-form";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import FileUpload from "../../components/FileUpload";
import { FilePreview } from "../../components/FilePreview";
import "./style.css";
import Api from "../../services/api";
import { changeIndexToNepali } from "../../utils";
import {
  FaCheckCircle,
  FaPrint,
  FaExclamationCircle,
  FaEye,
} from "react-icons/fa";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import FormPrint from "../../components/FormPrint";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as modalAction from "../../actions/modalAction";
import MainLoader from "../../components/MainLoader";
import ViewModal from "../../components/modals/ViewModal";
import * as authAction from "../../actions/authAction";
import { utcFormat } from "d3";
import Comment from "./Comment";
import Feedback from "./Feedback";
import UserFeedback from "./UserFeedback";
import { Helmet } from "react-helmet";
import AllComments from "./AllComments";

class SelfAssessmentForm extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this._getCategories = this._getCategories.bind(this);
    this._setAnwerDocs = this._setAnwerDocs.bind(this);
    this._sendRemarks = this._sendRemarks.bind(this);
    this._sendRemarks = _.debounce(this._sendRemarks, 1500);
    this._handleTextAreaChange = this._handleTextAreaChange.bind(this);
    this.state = {
      activeTab: 1,
      activeTabIndex: 0,
      activeCategoryIndex: 0,
      categories: [],
      isLoading: true,
      answers: null,
      hasCompleted: {},
      isCompleted: {},
      answerDocs: {},
      print: false,
      total_comment_count: "",
      remarksTicked: [],
      autoFocus: false,
      current_stage: {},
      palika_name: "",
    };
  }

  componentDidMount() {
    let { user } = this.props;

    this._getCategories();
  }

  _setAnwerDocs(documentId, url) {
    const { user } = this.props;
    let { answerDocs } = this.state;
    if (user.hasSubmitted) return;
    answerDocs[documentId] = answerDocs[documentId]
      ? answerDocs[documentId]
      : {};
    answerDocs[documentId].url = url;
    this.setState({ answerDocs });
  }

  toggle(activeTabIndex, activeTab) {
    if (this.state.activeTab !== activeTab) {
      this.setState({
        activeTab,
        activeTabIndex,
      });
    }
  }

  async _getCategories() {
    let location = window.location.href;
    let gnId = location.substring(location.length - 5);
    console.log(gnId);
    const { user, fiscal_year_id } = this.props;
    try {
      let response = await Api.getQuestions(fiscal_year_id, gnId);
      let categories = response.response.questions;
      let current_stage = response.response.status;

      categories = this._setIsCompletedCategory(categories);
      if (user.role === "Palika Data Entry") {
        categories = this._setDisabledCategory(categories);
      }
      this.setState({
        categories,
        current_stage,
        activeTab: categories[0].subCategories[0].id,
        firstTab: categories[0].subCategories[0].id,
        isLoading: false,
        palika_name: response.response.palika_name,
        total_comment_count: response.response.total_comment_count,
      });
    } catch (e) {
      toast.error(e, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    }
  }

  _setDisabledCategory = (categories) => {
    const { user } = this.props;
    let allowedCategories = user.categories;
    categories.map((category) => {
      if (allowedCategories.includes(category.id)) {
        category.isDisabled = false;
      } else {
        category.isDisabled = true;
      }
    });
    return categories;
  };

  _getDisabled = () => {
    const { user } = this.props;
    let { current_stage } = this.state;
    let disabled = true;
    // if (user.role === 'Palika Admin (Focal Person)' || (user.role === 'Palika Data Entry' && user.form_status > 0)) {
    //   disabled = true;
    // }
    // if (user.role === 'Palika Approver (CAO)' && user.form_status === 0) {
    //   disabled = true;
    // }
    // if (user.form_status === 3) {
    //   disabled = true;
    // }

    console.log(user);
    if (
      user.role_id == 3 &&
      (current_stage == 1 || current_stage == 2) &&
      user.entry_status !== 0
    ) {
      disabled = false;
    }

    if (user.role_id == 4 && current_stage == 0 && user.entry_status !== 0) {
      disabled = false;
    }

    console.log(user, user.entry_status);
    return disabled;
  };

  _updateForm = async (
    category,
    subCategory,
    question,
    option,
    subCatIndex,
    qnIndex
  ) => {
    const { user } = this.props;
    let { categories, activeCategoryIndex } = this.state;
    this.setState({ isLoading: true });
    try {
      let response = await Api.sendResponse({
        fiscal_year_id: user.fiscal_year_id,
        question_id: question.id,
        option_id: option.id,
        score: option.marks,
      });
      if (response.code === 200 || response.code === 204) {
        let updatedCategories = categories;
        updatedCategories[activeCategoryIndex].subCategories[
          subCatIndex
        ].questions[qnIndex].selected_option = option.id;
        updatedCategories = this._setIsCompletedCategory();
        this.setState({ categories: updatedCategories });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (e) {
      toast.error(e, {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    this.setState({ isLoading: false });
    return;
  };

  _onNextClick = () => {
    window.scrollTo(0, 0);
    const {
      activeTab,
      activeCategoryIndex,
      activeTabIndex,
      categories,
    } = this.state;
    let newActiveTab, newactiveCategoryIndex, newActiveTabIndex, newState;
    if (activeTabIndex === 2) {
      newActiveTabIndex = 0;
      newactiveCategoryIndex = activeCategoryIndex + 1;
      newActiveTab = categories[
        newactiveCategoryIndex
      ].subCategories[0].id.toString();
      newState = {
        activeCategoryIndex: newactiveCategoryIndex,
        activeTabIndex: newActiveTabIndex,
        activeTab: parseInt(newActiveTab),
      };
    } else {
      newActiveTabIndex = parseInt(activeTabIndex) + 1;
      newActiveTab =
        categories[activeCategoryIndex].subCategories[newActiveTabIndex].id;
      newState = {
        activeTab: parseInt(newActiveTab),
        activeTabIndex: newActiveTabIndex,
      };
    }
    this.setState({ ...newState, autoFocus: false });
  };

  _onPrevClick = () => {
    window.scrollTo(0, 0);
    const {
      activeTab,
      activeCategoryIndex,
      activeTabIndex,
      categories,
    } = this.state;
    let newActiveTab, newactiveCategoryIndex, newActiveTabIndex, newState;
    if (activeTabIndex === 0) {
      newActiveTabIndex = 2;
      newactiveCategoryIndex = activeCategoryIndex - 1;
      newActiveTab = categories[newactiveCategoryIndex].subCategories[
        categories[newactiveCategoryIndex].subCategories.length - 1
      ].id.toString();
      newState = {
        activeCategoryIndex: newactiveCategoryIndex,
        activeTab: parseInt(newActiveTab),
        activeTabIndex: newActiveTabIndex,
      };
      return;
    } else {
      newState = newActiveTab = parseInt(activeTab) - 1;
      this.setState({ activeTab: parseInt(newActiveTab) });
    }
    this.setState({ ...newState, autoFocus: false });
  };

  _onCategoryClick(activeCategoryIndex) {
    window.scrollTo(0, 0);
    const { categories } = this.state;
    let activeTabIndex = 0;
    this.setState({
      activeCategoryIndex,
      activeTab:
        categories[activeCategoryIndex].subCategories[activeTabIndex].id,
      activeTabIndex,
      autoFocus: false,
    });
  }

  _handleRemarksCheck = (e, question) => {
    let updatedRemarksTicked = [...this.state.remarksTicked];
    if (e.target.checked) {
      updatedRemarksTicked.push(question.id);
    } else {
      updatedRemarksTicked = updatedRemarksTicked.filter(
        (tickedQuestionId, i) => tickedQuestionId !== question.id
      );
    }
    this.setState({ remarksTicked: updatedRemarksTicked });
  };

  _handleTextAreaChange = async (
    e,
    question,
    activeCategoryIndex,
    subCatIndex,
    qnIndex
  ) => {
    let { categories } = this.state;
    let activeCategory = categories[activeCategoryIndex];
    const { user } = this.props;
    activeCategory.subCategories[subCatIndex].questions[qnIndex].remarks =
      e.target.value;
    question.remarks = e.target.value;
    this.setState({ categories: categories });
    //update category
    // this.setState({activeRemarksText: e.target.value});

    // this.setState({ answerDocs });
    this._sendRemarks(question);
  };

  async _sendRemarks(question) {
    const { user } = this.props;
    let response = await Api.sendResponse({
      fiscal_year_id: user.fiscal_year_id,
      question_id: question.id,
      remarks: question.remarks,
    });
    if (response.code !== 200) {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  _renderDocumentListFromOption(question, user) {
    let documentList = [];
    let selectedOption = question.selected_option;
    if (selectedOption) {
      question.options.map((option) => {
        if (option.id === selectedOption) {
          if (option.documents.length === 0) {
            documentList.push(
              <p className="document-name document-empty" key={"no-doc"}>
                &bull; कुनैपनि प्रमाणीकरणको आधारहरु नचाहिने{" "}
                <FaCheckCircle className="check-circle-doc" color="#28a745" />
              </p>
            );
          }
          option.documents.map((document) => {
            let singleDoc;
            if (document.path) {
              singleDoc = (
                <React.Fragment key={document.id}>
                  <a className="d-flex align-items-baseline">
                    <p
                      onClick={() =>
                        user.entry_status !== 0
                          ? this._openDocumentModal(document)
                          : null
                      }
                      className="document-name"
                    >
                      &bull; {document.name}
                      <FaCheckCircle
                        className="check-circle-doc"
                        color="#28a745"
                      />
                    </p>
                    <ViewModal file={document.path} />
                  </a>
                </React.Fragment>
              );
            } else {
              singleDoc = (
                <a
                  onClick={() =>
                    user.entry_status !== 0
                      ? this._openDocumentModal(document)
                      : null
                  }
                  key={document.id}
                >
                  <p className="document-name">
                    &bull; {document.name}
                    <FaExclamationCircle
                      className="check-circle-doc"
                      color="#e12a2a"
                    />
                  </p>
                </a>
              );
            }
            documentList.push(singleDoc);
            //if path==="" show exclaim else show tick and link to path.
          });
        }
      });
    }
    return documentList;
  }

  _onDocumentRemoved = (removedDoc) => {
    let { categories } = this.state;
    categories.map((question) => {
      question.subCategories &&
        question.subCategories.length > 0 &&
        question.subCategories.map((subCat) => {
          subCat.questions &&
            subCat.questions.length > 0 &&
            subCat.questions.map((question) => {
              question.options &&
                question.options.length > 0 &&
                question.options.map((option) => {
                  option.documents &&
                    option.documents.length > 0 &&
                    option.documents.map((document) => {
                      if (document.id === removedDoc.id) {
                        document.path = "";
                      }
                    });
                });
            });
        });
    });
    categories = this._setIsCompletedCategory(categories);
    this.setState({ categories });
    //update cateegory obkect
  };

  _onDocumentUploaded = (uploadedDoc, response) => {
    let { categories } = this.state;
    categories.map((question) => {
      question.subCategories &&
        question.subCategories.lenght > 0 &&
        question.subCategories.map((subCat) => {
          subCat.questions &&
            subCat.questions.length > 0 &&
            subCat.questions.map((question) => {
              question.options &&
                question.options.length > 0 &&
                question.options.map((option) => {
                  option.documents &&
                    option.documents.length > 0 &&
                    option.documents.map((document) => {
                      if (document.id === uploadedDoc.id) {
                        document.path = response.response.path;
                      }
                    });
                });
            });
        });
    });
    categories = this._setIsCompletedCategory(categories);
    this.setState({ categories });

    //update cateegory obkect
  };

  _openDocumentModal = (document) => {
    const { onDocumentsShow } = this.props;
    onDocumentsShow({
      clickedDocument: document,
      onDocumentRemoved: this._onDocumentRemoved,
      onDocumentUploaded: this._onDocumentUploaded,
    });
  };

  _setIsCompletedCategory = (categoriesList) => {
    let { categories } = this.state;
    let categoryIsCompletedList =
      categories.length === 0 ? categoriesList : categories;
    categoryIsCompletedList.map((category) => {
      let isCatCompleted = true;
      category.subCategories &&
        category.subCategories.length > 0 &&
        category.subCategories.map((subCat) => {
          let isSubCatCompleted = true;
          subCat.questions &&
            subCat.questions.length > 0 &&
            subCat.questions.map((question) => {
              let isQuestionCompleted = true;
              if (!question.selected_option) {
                isCatCompleted = false;
                isSubCatCompleted = false;
                isQuestionCompleted = false;
              } else {
                const selectedOptionObject = question.options.filter(
                  (option) => {
                    return option.id === question.selected_option;
                  }
                );
                selectedOptionObject[0].documents.map((document) => {
                  if (!document.path) {
                    isCatCompleted = false;
                    isSubCatCompleted = false;
                    isQuestionCompleted = false;
                  }
                });
              }

              question.isCompleted = isQuestionCompleted;
            });
          subCat.isCompleted = isSubCatCompleted;
        });
      category.isCompleted = isCatCompleted;
    });
    return categoryIsCompletedList;
  };

  _checkAllAnsCompleted = () => {
    let { categories } = this.state;
    let isCompleted = true;
    categories.map((category) => {
      if (!category.isCompleted) {
        isCompleted = false;
      }
    });
    return isCompleted;
  };

  render() {
    const { handleSubmit, user } = this.props;
    let {
      categories,
      activeCategoryIndex,
      isLoading,
      activeTab,
      firstTab,
      isCompleted,
      activeTabIndex,
      answerDocs,
      remarksCheck,
      hasCompleted,
      autoFocus,
      remarksTicked,
      current_stage,
    } = this.state;
    let activeCategory = categories[activeCategoryIndex];
    let disabled = this._getDisabled();

    const alertStyle = {
      backgroundColor: '#ffe6e6', // light red background
      border: '1px solid #ffcccc', // border color
      borderRadius: '5px', // rounded corners
      padding: '15px', // padding around content
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // optional: box shadow
      width:
        "100%", // adjust width as needed
      margin: '0 auto', // center horizontally
      textAlign: 'center', // center text
    };

    return (
      <MainLoader active={isLoading}>
        <div style={alertStyle}>
          Form has been disabled for this fiscal year.
        </div>
        <Helmet>
          <title>LISA Nepal - Self Assessment Form</title>
        </Helmet>
        {categories.length > 0 && (
          <section className="assessment-form">
            <div className="header mb-2">
              <h1 className="heading">{this.state.palika_name}</h1>
              <div>
                <AllComments
                  user={user}
                  totalCount={this.state.total_comment_count}
                />
                &nbsp;&nbsp;
                {/* <Button onClick={() => window.open("/internal-control")} className="uni-menu-item">आन्तरिक नियन्त्रण</Button>&nbsp;&nbsp; */}
                <ReactToPrint
                  trigger={() => (
                    <Button color="success" className="print-button">
                      <FaPrint className="print-icon" />
                      Print
                    </Button>
                  )}
                  content={() => this.componentRef}
                  onBeforePrint={() => console.log("on before")}
                  onAfterPrint={() => console.log("on after")}
                />
                &nbsp;&nbsp;
                <Button
                  onClick={() => this._openDocumentModal()}
                  className="uni-menu-item"
                >
                  कागजातहरु हेर्नुहोस
                </Button>
              </div>
            </div>
            <Row>
              {/* <Col sm="12">
                <div className="doc-section">
                  <h3 className="docs-heading">जरुरी कागजातहरु {!user.is_current_fiscal_year ? '( यो फारम हाल बन्द छ )' : ''}</h3> 
                  <Button onClick={()=>this._openDocumentModal()} className="uni-menu-item">कागजातहरु हेर्नुहोस</Button>
                </div>
              </Col> */}
              <Col sm="3">
                <Nav vertical>
                  <div className="cat-heading-wrapper">
                    <h3 className="cat-heading">
                      स्व-मूल्याङ्कन विषयक्षेत्रहरु
                    </h3>
                  </div>
                  {categories.map((category, catIndex) => {
                    return (
                      <div key={category.id}>
                        <div
                          className={`cat-card ${activeCategoryIndex === catIndex ? "active" : ""
                            }`}
                          onClick={() => this._onCategoryClick(catIndex)}
                        >
                          <img
                            src={category.image}
                            className="cat-icon"
                            alt="cat-icon"
                          />
                          <span className="cat-name">{category.name}</span>
                          {category.isCompleted ? (
                            <FaCheckCircle
                              className="check-circle"
                              color="#28a745"
                            />
                          ) : (
                            <FaExclamationCircle
                              className="check-circle"
                              color="#e12a2a"
                            />
                          )}
                          {activeCategoryIndex === catIndex && (
                            <div className="arrow-right" />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </Nav>
              </Col>
              {activeCategory.subCategories && (
                <Col sm="9">
                  <div className="header-content">
                    <h3 className="subcat-header">{activeCategory.name} </h3>
                  </div>
                  <Nav tabs>
                    {activeCategory.subCategories.map(
                      (subCategory, sCatIndex) => {
                        return (
                          <NavItem key={subCategory.id}>
                            <NavLink
                              onClick={() => {
                                this.toggle(sCatIndex, subCategory.id);
                              }}
                              className={
                                activeTab === subCategory.id ? "active" : ""
                              }
                            >
                              {subCategory.name}{" "}
                              {subCategory.isCompleted ? (
                                <FaCheckCircle color="#28a745" />
                              ) : (
                                <FaExclamationCircle
                                  className="check-circle-doc"
                                  color="#e12a2a"
                                />
                              )}
                            </NavLink>
                          </NavItem>
                        );
                      }
                    )}
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    {activeCategory.subCategories.map(
                      (subCategory, subCatIndex) => {
                        if (
                          user.role_id == 4 &&
                          current_stage == 0 &&
                          user.entry_status !== 0
                        ) {
                          disabled = !!activeCategory.isDisabled;
                        }
                        return (
                          <TabPane tabId={subCategory.id} key={subCategory.id}>
                            <Row>
                              <Col sm="12">
                                <h4 className="subcat-header">
                                  {changeIndexToNepali(activeCategory.id)}.
                                  {changeIndexToNepali(subCatIndex + 1)}{" "}
                                  {subCategory.name}
                                </h4>
                                {subCategory.questions &&
                                  subCategory.questions.length > 0 &&
                                  subCategory.questions.map(
                                    (question, qnIndex) => {
                                      let selectedOption =
                                        question.selected_option;
                                      return (
                                        <FormGroup
                                          tag="fieldset"
                                          key={question.id}
                                        >
                                          <Row>
                                            <Col sm="12">
                                              <legend className="form-qn">
                                                {question.question}
                                              </legend>
                                              {question.updated_by && (
                                                <span className="updated-by">
                                                  <b>{question.updated_by}</b>
                                                  द्वारा{" "}
                                                  <b>
                                                    {new Date(
                                                      question.updated_at
                                                    ).toLocaleDateString(
                                                      "ne-NP",
                                                      {
                                                        day: "numeric",
                                                        month: "short",
                                                        year: "numeric",
                                                      }
                                                    )}
                                                  </b>
                                                  मा अद्यावधिक गरिएको
                                                </span>
                                              )}
                                              {question.options.map(
                                                (option, opIndex) => {
                                                  let checked =
                                                    selectedOption &&
                                                      selectedOption === option.id
                                                      ? true
                                                      : false;
                                                  return (
                                                    <FormGroup
                                                      check
                                                      key={option.id}
                                                    >
                                                      {console.log(
                                                        "user",
                                                        user
                                                      )}
                                                      <Label check>
                                                        <Input
                                                           disabled={disabled}
                                                         // disabled={true}
                                                          //just done quick disable for this fiscalyear. This need to uncomment for next fiscalyear.
                                                          type="radio"
                                                          name={`radio-${question.id}`}
                                                          checked={checked}
                                                          onChange={() =>
                                                            this._updateForm(
                                                              activeCategory,
                                                              subCategory,
                                                              question,
                                                              option,
                                                              subCatIndex,
                                                              qnIndex
                                                            )
                                                          }
                                                        />
                                                        {option.name}
                                                      </Label>
                                                    </FormGroup>
                                                  );
                                                }
                                              )}
                                              <div className="proof">
                                                <legend>
                                                  प्रमाणीकरणको आधार:
                                                </legend>
                                                <Row>
                                                  <Col sm="12">
                                                    {this._renderDocumentListFromOption(
                                                      question,
                                                      user
                                                    )}
                                                    {/* <p className="document-name">&bull; {question.selected_option.name}</p> */}
                                                  </Col>
                                                  {/* <Col sm="6">
                                              <div className="file-upload-wrapper">
                                                {submittedDoc && submittedDoc.url ?
                                                  (<div className="file-preview">
                                                    <FilePreview url={submittedDoc.url} onRemove={this._onRemove} dId={question.documents.document_id} />
                                                  </div>)
                                                  :
                                                  (<div className="file-drop">
                                                    <FileUpload catId={activeCategory.id} qId={question.id} documentId={question.documents.document_id} setAnwerDocs={this._setAnwerDocs} />
                                                  </div>)
                                                }
                                              </div>
                                            </Col> */}
                                                  <Col sm="6">
                                                    {current_stage > 0 && (
                                                      <Comment
                                                        user={user}
                                                        count={
                                                          question.comment_count
                                                        }
                                                        q_id={question.id}
                                                      />
                                                    )}
                                                    <div className="remarks-wrapper">
                                                      <label>
                                                        <input
                                                          disabled={disabled}
                                                          type="checkbox"
                                                          onChange={(e) => {
                                                            return this._handleRemarksCheck(
                                                              e,
                                                              question
                                                            );
                                                          }}
                                                          checked={
                                                            !!question.remarks ||
                                                            remarksTicked.includes(
                                                              question.id
                                                            )
                                                          }
                                                        />
                                                        &nbsp;&nbsp;&nbsp;कैफियत{" "}
                                                        <br />
                                                        {/* <span className="remarks-info"> ( कुनै कागजात नभएको वा वेबसाइटको लिङ्क राख्नुपरेको खण्डमा अनिवार्य रुपमा भरिनु पर्ने )</span> */}
                                                      </label>
                                                      {(question.remarks ||
                                                        remarksTicked.includes(
                                                          question.id
                                                        )) && (
                                                          <textarea
                                                            name="textarea"
                                                            disabled={disabled}
                                                            value={
                                                              question.remarks
                                                            }
                                                            onChange={(e) =>
                                                              this._handleTextAreaChange(
                                                                e,
                                                                question,
                                                                activeCategoryIndex,
                                                                subCatIndex,
                                                                qnIndex
                                                              )
                                                            }
                                                          ></textarea>
                                                        )}
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                              {/* {current_stage && current_stage === 4 && user.role_id === 6 ?
                                          <Feedback user={user} q_id={question.id} qa_approved={question.qa_approved} qa_remarks={question.qa_remarks} />
                                          : ''} */}
                                            </Col>
                                          </Row>
                                        </FormGroup>
                                      );
                                    }
                                  )}

                                {activeCategoryIndex === 0 &&
                                  activeTab === firstTab ? (
                                  ""
                                ) : (
                                  <Button
                                    className="previous"
                                    onClick={this._onPrevClick}
                                  >
                                    <p>अघिल्लो</p>
                                  </Button>
                                )}
                                <div className="right-button">
                                  {activeCategoryIndex ===
                                    categories.length - 1 &&
                                    activeTabIndex === 2 ? (
                                    <></>
                                  ) : (
                                    // !disabled &&
                                    // <Button className="next" onClick={this._toggleModal}><p> पेश गर्नुहोस्</p></Button>
                                    <Button
                                      className="next"
                                      onClick={this._onNextClick}
                                    >
                                      <p>अर्को</p>
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          </TabPane>
                        );
                      }
                    )}
                  </TabContent>
                </Col>
              )}
            </Row>
            {/* {!!answers && */}
            <FormPrint
              categories={categories}
              // answers={answers}
              answerDocs={answerDocs}
              remarksTicked={remarksTicked}
              ref={(el) => (this.componentRef = el)}
            />
            {/* } */}
            <ToastContainer />
            <UserFeedback user={user} />
          </section>
        )}
      </MainLoader>
    );
  }
}

SelfAssessmentForm = reduxForm({
  // a unique name for the form
  form: "selfAssessment",
})(SelfAssessmentForm);

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  fiscal_year_id: auth.login.fiscal_year_id,
});

const mapDispatchToProps = (dispatch) => ({
  onDocumentsShow: (modalProps) => {
    dispatch(
      modalAction.processModal(
        modalAction.SHOW_MODAL,
        modalAction.ModalTypes.SHOW_DOCUMENT,
        modalProps
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelfAssessmentForm);
