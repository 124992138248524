import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Form, FormGroup, Label, Input, FormText, Modal, ModalHeader, ModalBody, ModalFooter, Tooltip } from 'reactstrap';
import NepalMap from '../../components/NepalMap';
import ReactTable from "react-table";
import './style.css';
import { changeIndexToNepali } from '../../utils';
import { FaTrash, FaEye, FaEdit, FaWindowRestore, FaSpinner } from 'react-icons/fa';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import Api from '../../services/api';
import MainLoader from '../../components/MainLoader';
import { MdSelectAll } from 'react-icons/md';
import Select from 'react-select';
import UserDetail from '../UserDetail';
import { connect } from 'react-redux';

const fy = '074/75';
class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      activeAddUser: null,
      showAddModal: false,
      activeEditUser: null,
      showEditModal: false,
      activeDeleteUser: null,
      showDeleteModal: false,
      isLoading: true,
      selectedCategories: [],
      categoriesOption: [],
      checkedCategories: [],
      allCategoriesOption: [],
      password: '',
      confirmPassword: '',
      activeTab: 'normal',
      suspendedUsers: [],
      showRestoreModal: false,
      activeRestoreUser: null,
      showCatsModal: false,
      saveLoading: false,
      userId: null,
      mode: 'show',
      adminRole: null,
      loginUserId: null,
    };
  }

  getUsers = async () => {
    let users = await Api.getUsers();
    users = users.response;
    this.setState(
      {
        users,
      }
    )
  }

  getSuspendedUsers = async () => {
    let suspendedUsers = await Api.getPalikaSuspendedUsers();
    suspendedUsers = suspendedUsers.response;
    this.setState(
      {
        suspendedUsers,
      }
    )
  }

  _toggleAddModal = async (user) => {
    if (user) {
      this.setState({ activeAddUser: user, showAddModal: !this.state.showAddModal });
    } else {
      this.setState({ showAddModal: !this.state.showAddModal, showAddModal: false });
    }
  }

  _toggleEditModal = async (user) => {
    if (user) {
      this.setState({ activeEditUser: user, showEditModal: !this.state.showEditModal });
    } else {
      this.setState({ showEditModal: !this.state.showEditModal, showEditModal: false });
    }
  }

  _toggleDeleteModal = async (user) => {
    if (user) {
      this.setState({ activeDeleteUser: user, showDeleteModal: !this.state.showDeleteModal });
    } else {
      this.setState({ showDeleteModal: !this.state.showDeleteModal, showDeleteModal: false });
    }
  }

  _toggleRestoreModal = async (user) => {
    if (user) {
      this.setState({ activeRestoreUser: user, showRestoreModal: !this.state.showDeleteModal });
    } else {
      this.setState({ activeRestoreUser: !this.state.activeRestoreUser, showRestoreModal: false });
    }
  }

  _toggleCatsModal = async () => {
    this.setState({ showCatsModal: !this.state.showCatsModal });
  }

  _deactivateUser = async () => {
    const { activeDeleteUser } = this.state;
    this.setState({ isLoading: true });
    await Api.deactivateUser(activeDeleteUser.id);
    toast.success('युजर हटाउन सफल।', {
      position: toast.POSITION.TOP_CENTER,
    });
    this._toggleDeleteModal();
    await this.getUsers();
    await this.getSuspendedUsers();
    this.setState({ isLoading: false });
  }

  _getCategories = async () => {
    let catRes = await Api.getCategories();
    let categoriesOption = [];
    catRes.response.map((sCat) => {
      categoriesOption.push({
        value: sCat.id,
        label: sCat.name,
      })
    });
    return categoriesOption;
  }

  _getAllCategories = async () => {
    let catRes = await Api.getAllCategories();
    return catRes.response;
  }



  _restoreUser = async () => {
    const { activeRestoreUser } = this.state;
    this.setState({ isLoading: true });
    await Api.restoreUser(activeRestoreUser.id);
    toast.success('युजर रिस्टोर गर्न सफल।', {
      position: toast.POSITION.TOP_CENTER,
    });
    this._toggleRestoreModal();
    await this.getUsers();
    await this.getSuspendedUsers();
    this.setState({ isLoading: false });

  }

  _switchTab = () => {
    let { activeTab } = this.state;
    let newActiveTab = activeTab === 'normal' ? 'suspended' : 'normal';
    this.setState({ activeTab: newActiveTab });
  }

  _userShowClick = (d) => {
    // const { history } = this.props;
    // history.push({
    //   pathname: `/user/${d.id}/show`,
    //   state: { user: d }
    // });
    this.setState({
      userId: d.id,
      mode: 'show'
    })
    this._toggleEditModal(d)
  }

  _userEditClick = (d) => {
    // const { history } = this.props;
    // history.push({
    //   pathname: `/user/${d.id}/edit`,
    //   state: { user: d }
    // });
    this.setState({
      userId: d.id,
      mode: 'edit'
    })
    this._toggleEditModal(d)
  }

  async componentDidMount() {
    const { history, user } = this.props;
    await this.setState({ adminRole: user.role, loginUserId: user.id })
    let users = await Api.getUsers();
    users = users.response;
    let columns = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    }, {
      Header: 'नाम',
      id: 'name',
      accessor: d => {
        return (
          d.name
        );
      },
    }, {
      Header: 'इमेल',
      id: 'email',
      accessor: d => {
        return (
          d.email
        );
      },
    }, {
      Header: 'रोल',
      id: 'role',
      accessor: d => {
        return (
          d.role
        );
      },
    }, {
      Header: 'कार्य',
      id: 'action',
      accessor: d => {
        return (
          <>
            <FaEye onClick={() => this._userShowClick(d)} className="show-action" color='#2572bc' />
            <FaEdit onClick={() => this._userEditClick(d)} className="edit-action" color='#28a53d' />
            <FaTrash onClick={() => this._toggleDeleteModal(d)} className="remove-action" color='#e12a2a' />
          </>
        );
      },
    }
    ];

    let suspendedUsers = await Api.getPalikaSuspendedUsers();
    suspendedUsers = suspendedUsers.response;
    let suspendedUserColumn = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    }, {
      Header: 'नाम',
      id: 'name',
      accessor: d => {
        return (
          d.name
        );
      },
    }, {
      Header: 'इमेल',
      id: 'email',
      accessor: d => {
        return (
          d.email
        );
      },
    }, {
      Header: 'रोल',
      id: 'role',
      accessor: d => {
        return (
          d.role
        );
      },
    }, {
      Header: 'कार्य',
      id: 'action',
      accessor: d => {
        return (
          <>
            <FaWindowRestore onClick={() => this._toggleRestoreModal(d)} className="show-action" color='#2572bc' />
          </>
        );
      },
    }
    ];
    let categoriesOption = await this._getCategories();
    let allCategoriesOption = await this._getAllCategories();

    this.setState(
      {
        categoriesOption,
        allCategoriesOption,
        users,
        columns,
        suspendedUserColumn,
        suspendedUsers,
        isLoading: false,
      }
    )
  }

  onEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  onNameChange = (e) => {
    this.setState({
      name: e.target.value
    })
  }

  onPassChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  onConfirmPassChange = (e) => {
    this.setState({
      confirmPassword: e.target.value
    })
  }

  onRoleChange = (e) => {
    this.setState({
      role: e.target.value
    })
  }



  _onSubmit = async () => {
    this.setState({
      saveLoading: true
    })
    const { name, email, role, checkedCategories, password, confirmPassword } = this.state;
    let userDetails = { name, email, role, password, confirmPassword };
    userDetails["confirm-password"] = confirmPassword
    if (password !== confirmPassword) {
      toast.error('पासवर्ड र पुन: पासवर्ड मिलेन', {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({
        saveLoading: false
      })
      return;
    }
    if (role === 'user') {
      let selectedCategoriesParam = checkedCategories
      selectedCategoriesParam = selectedCategoriesParam.map(cat => cat).join();
      userDetails = { ...userDetails, categories: selectedCategoriesParam }
      if (checkedCategories.length === 0) {
        toast.error('Palika Data Entry प्रयोगकर्तालाई कृपया कमसेकम एउटा स्व-मूल्याङ्कन विषयक्षेत्र छान्नुहोस्।', {
          position: toast.POSITION.TOP_CENTER,
        });
        this.setState({
          saveLoading: false
        })
        return;
      }
    }
    if (!name || !email || !role) {
      toast.error('कृपया फारमको सबै क्षेत्रहरू भर्नुहोस्', {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({
        saveLoading: false
      })
      return;
    }
    let response = await Api.createUser(userDetails);
    if (response.code === 200) {
      toast.success('प्रयोगकर्ता बनाउन सफल।', {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({
        name: '', email: '', role: '', checkedCategories: []
      })
      await this.getUsers();
      this._toggleAddModal();
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.setState({
      saveLoading: false
    })
  }

  selectAllCategory = () => {
    this.setState({ selectedCategories: this.state.categoriesOption });
  }

  // handleCategoryChange = selectedOption => {
  //   this.setState({ selectedCategories: selectedOption });
  // };

  handleCategoryCheck = (value, id) => {
    let checkedCategoriesList
    if (value) {
      checkedCategoriesList = this.state.checkedCategories
      checkedCategoriesList.push(id)
    } else {
      checkedCategoriesList = this.state.checkedCategories.filter(x => x.toString() != id.toString())
    }
    this.setState({ checkedCategories: checkedCategoriesList });
  };

  // _checkValue = (id) => {
  //   if (this.state.checkedCategories.includes(id)) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  _toggleTooltip = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  _SelectCats = () => {

  }

  render() {

    let { users, columns, showCatsModal, showEditModal, showDeleteModal, showAddModal, isLoading, suspendedUsers, suspendedUserColumn, activeTab, showRestoreModal, tooltipOpen, selectedCategories, categoriesOption, } = this.state;
    return (
      <MainLoader active={isLoading}>
        <div className="content">
          {/* <h1 className="sub-heading">प्रयोगकर्ता</h1> */}
          <div className="user-form">
            <button className="btn btn-primary" onClick={this._toggleAddModal}>प्रयोगकर्ता सिर्जना गर्नुहोस्</button>
          </div>
          <div className="user-header">
            <h1 className="sub-heading">{activeTab === 'normal' ? 'प्रयोगकर्ता तालिका' : 'निलम्बित प्रयोगकर्ता तालिका'}</h1>
            <span className="tab-control" onClick={() => this._switchTab()}>{activeTab === 'normal' ? 'निलम्बित प्रयोगकर्ता तालिका' : 'प्रयोगकर्ता तालिका'}</span>
          </div>

          {users && users.length > 0 && activeTab === 'normal' &&
            <ReactTable
              data={users}
              columns={columns}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          }

          {suspendedUsers && suspendedUsers.length > 0 && activeTab === 'suspended' &&
            <ReactTable
              data={suspendedUsers}
              columns={suspendedUserColumn}
              defaultPageSize={10}
              className="-striped -highlight"
            />
          }
        </div>

        <Modal size="lg" isOpen={showEditModal} toggle={this._toggleEditModal} className="">
          <ModalHeader toggle={this._toggleEditModal}>प्रयोगकर्ता अद्यावधिक</ModalHeader>
          <ModalBody>
            <UserDetail userId={this.state.userId} mode={this.state.mode} toggleEditModal={this._toggleEditModal} getUsers={this.getUsers} />
          </ModalBody>
        </Modal>

        <Modal isOpen={showDeleteModal} toggle={this._toggleDeleteModal} className="delete-modal">
          <ModalHeader toggle={this._toggleDeleteModal}>प्रयोगकर्ता हटाउनुहोस्</ModalHeader>
          <ModalBody>
            के तपाईँ यो प्रयोगकर्ता हटाउन चाहनुहुन्छ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { this._deactivateUser(); }}>प्रयोगकर्ता हटाउनुहोस्</Button>
            <Button color="red" onClick={this._toggleDeleteModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={showRestoreModal} toggle={this._toggleRestoreModal} className="delete-modal">
          <ModalHeader toggle={this._toggleRestoreModal}>प्रयोगकर्ता रिस्टोर</ModalHeader>
          <ModalBody>
            के तपाईँ यो प्रयोगकर्ता रिस्टोर गर्न चाहनुहुन्छ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => { this._restoreUser(); }}>प्रयोगकर्ता रिस्टोर गर्नुहोस्</Button>
            <Button color="red" onClick={this._toggleRestoreModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>

        <Modal size="lg" isOpen={showCatsModal} toggle={this._toggleCatsModal} className="delete-modal">
          <ModalHeader >विषयक्षेत्रहरु छान्नुहोस्</ModalHeader>
          <ModalBody>
            <Row>
              {this.state.allCategoriesOption.map(item => (
                <Col sm="6">
                  <h4>{item.module}</h4>
                  {item.categories.map(cat => (
                    <div className="d-block">
                      <input id={`cat-checkbox${cat.id}`} type="checkbox" checked={this.state.checkedCategories.includes(cat.id)} onChange={(e) => this.handleCategoryCheck(e.currentTarget.checked, cat.id)} /> &nbsp;
                      <label htmlFor={`cat-checkbox${cat.id}`}>{cat.name}</label>
                    </div>
                  ))}
                </Col>
              ))}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this._toggleCatsModal}>विषयक्षेत्रहरु छान्नुहोस्</Button>
            <Button color="red" onClick={this._toggleCatsModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>

        <Modal size="lg" isOpen={showAddModal} toggle={this._toggleAddModal} className="">
          <ModalHeader toggle={this.toggle}>प्रयोगकर्ता सिर्जना गर्नुहोस्</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="user-name">नाम</Label>
                <Input type="text" name="address" id="user-name" placeholder="नाम" value={this.state.name || ""} onChange={this.onNameChange} />
              </FormGroup>
              <FormGroup>
                <Label for="user-email">इमेल</Label>
                <Input type="email" name="user-email" placeholder="इमेल" value={this.state.email || ""} onChange={this.onEmailChange} />
              </FormGroup>
              <FormGroup>
                <Label for="user-password">पासवर्ड</Label>
                <Input type="password" name="user-password" placeholder="पासवर्ड" value={this.state.password || ""} onChange={this.onPassChange} />
              </FormGroup>
              <FormGroup>
                <Label for="user-re-password">पुन: पासवर्ड</Label>
                <Input type="password" name="user-re-password" placeholder="पुन: पासवर्ड" value={this.state.confirmPassword || ""} onChange={this.onConfirmPassChange} />
              </FormGroup>
              {this.state.adminRole === 'Palika Admin (Focal Person)' ? (
                <FormGroup>
                  <Label for="user-role">रोल</Label>
                  <Input type="select" name="select" id="user-role" value={this.state.role || ""} onChange={this.onRoleChange}>
                    <option value="" disabled selected="selected">छान्नुहोस्</option>
                    <option value="approver">CAO</option>
                    <option value="user">Palika Data Entry</option>
                  </Input>
                </FormGroup>
              ) : this.state.adminRole === 'District Admin' ? (
                <FormGroup>
                  <Label for="user-role">रोल</Label>
                  <Input type="select" name="select" id="user-role" value={this.state.role || ""} onChange={this.onRoleChange}>
                    <option value="" disabled selected="selected">छान्नुहोस्</option>
                    <option value="dcc_approver">DCC Approver</option>
                    <option value="dcc_user">DCC Data Entry</option>
                  </Input>
                </FormGroup>
              ) : null}
              {this.state.adminRole === 'Palika Admin (Focal Person)' && this.state.role === "user" &&
                <FormGroup>
                  <Label for="user-category">विषयक्षेत्रहरु</Label>
                  <div className="select-cat-wrapper">
                    {/* <Select
                      value={selectedCategories}
                      // onChange={this.handleCategoryChange}
                      className="select-cat-multi"
                      isMulti={true}
                      options={categoriesOption}
                      onClick={this._toggleCatsModal}
                    /> */}
                    {/* <MdSelectAll className="select-all-icon" id="cat-select-all" onClick={() => this.selectAllCategory()} /> */}
                    {/* <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} target="cat-select-all" toggle={this._toggleTooltip}>
                      सबै विषयक्षेत्रहरु छान्नुहोस्
                    </Tooltip> */}
                    <Button className="btn btn-sm bt-info" onClick={this._toggleCatsModal}>SelectCategories</Button>
                  </div>
                </FormGroup>
              }
              <Button color='primary' onClick={this._onSubmit} disabled={this.state.saveLoading}>
                Submit &nbsp; {this.state.saveLoading && <FaSpinner className="rotating" />}
              </Button>
            </Form>
          </ModalBody>
        </Modal>
        <ToastContainer />

      </MainLoader>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user
});

const enhance = compose(withRouter);

export default connect(mapStateToProps)(enhance(User));
