import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, reduxForm } from 'redux-form';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Input, FormGroup, Navbar, Label } from 'reactstrap';
import { Progress } from 'reactstrap';
import FileUpload from '../../components/FileUpload';
import FilePreview from '../../components/FilePreview';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import './style.css';
import Api from '../../services/api';
import { changeIndexToNepali } from '../../utils';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import 'paper-css/paper.css';
import logo from '../../assets/images/logo.svg';
import radioChecked from '../../assets/images/radioChecked.png';
import radioUnchecked from '../../assets/images/radioUnchecked.png';

class FormPrint extends Component {
  
  _renderDocumentListFromOption(question) {
    let documentList = [];
    let selectedOption = question.selected_option;
    if (selectedOption) {
      question.options.map(option=>{
        if(option.id === selectedOption) {
          option.documents.map(document=>{
            documentList.push(<p className="document-name" key={document.id}>&bull; {document.name}{document.path?<FaCheckCircle className="check-circle-doc" color='#28a745' />:<FaExclamationCircle className="check-circle-doc" color='#e12a2a' />}</p>);
            //if path==="" show exclaim else show tick and link to path.
          });
          
        }
      })
    }
    return documentList;

  }
  
    render() {
        const { handleSubmit } = this.props;
        let { categories, answers, isCompleted, answerDocs, user, remarksTicked } = this.props;
        return (
            <div id='printcontents' className="A4">
                    {categories.map((activeCategory, catIndex) => {
                        return (
                            <section className="assessment-form" key={catIndex}>
                                    {activeCategory.subCategories.map((sCat, sCatIndex) => {
                                        return (
                                            <section className="sheet padding-10mm" key={sCatIndex}>
                                                {/* <Col sm="3">
                                                    <Nav vertical>
                                                        <div className="cat-heading-wrapper">
                                                            <h3 className="cat-heading">स्व-मूल्याङ्कन विषयक्षेत्रहरु</h3>
                                                        </div>
                                                        {categories.map((category, catIndex) => {
                                                            return (
                                                                <div key={category.id}>
                                                                    <div className={`cat-card ${(activeCategoryIndex === catIndex) ? 'active' : ''}`} onClick={() => this._onCategoryClick(catIndex)}>
                                                                        <img src={require(`../../assets/images/${catIndex + 1}.svg`)} className="cat-icon" alt="cat-icon" />
                                                                        <span className="cat-name">{category.name}</span>
                                                                        {(activeCategoryIndex === catIndex) && <div className="arrow-right" />}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </Nav>
                                                </Col> */}
                                                <div sm="12">
                                                    <div className="print-header">
                                                        <img src={logo} alt="logo" className="nav-logo-user" />
                                                        <div style={{ color: '#e12a2a', marginLeft: 20, fontSize: 14 }}>
                                                            <span style={{ color: '#676767', fontSize: 20 }}>
                                                                {user.palika_name} <br />
                                                            </span>
                                                            <span style={{ color: '#2572bc', fontSize: 14 }}>स्थानीय तह संस्थागत क्षमता स्वमूल्याङ्कन<br /></span>
                                                            Local Government Institutional Capacity Self-Assessment (LISA)
                                                        </div>
                                                    </div>
                                                    <h3 className="subcat-header">{changeIndexToNepali(catIndex+1)}. {activeCategory.name} </h3>
                                                    <Nav tabs>
                                                        {activeCategory.subCategories.map((subCategory, sCatIndex) => {
                                                            // if (!!isCompleted[activeCategory.id]) {
                                                            //     // console.log(isCompleted[activeCategory.id][subCategory.id]);
                                                            // }
                                                            return (
                                                                <NavItem key={subCategory.id}>
                                                                    <NavLink
                                                                        className={sCat.id === subCategory.id ? 'active' : ''}
                                                                    >
                                                                        {subCategory.name} {subCategory.isCompleted ? <FaCheckCircle color='#28a745' /> : <FaExclamationCircle className="check-circle-doc" color='#e12a2a' />}
                                                                    </NavLink>
                                                                </NavItem>
                                                            );
                                                        })}
                                                    </Nav>
                                                    <TabContent activeTab={(sCat.id)}>
                                                        {activeCategory.subCategories.map((subCategory, subCatIndex) => {
                                                            return (
                                                                <TabPane tabId={subCategory.id} key={subCategory.id}>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                            <h4 className="subcat-header">{changeIndexToNepali(activeCategory.id)}.{changeIndexToNepali(subCatIndex + 1)} {subCategory.name}</h4>
                                                                            {subCategory.questions.map((question, qnIndex) => {
                                                                                let selectedOption = question.selected_option;
                                                                                let extraStyle = {};
                                                                                if(qnIndex === 5) {
                                                                                  extraStyle = {
                                                                                    marginTop: 350
                                                                                  }
                                                                                }
                                                                                // let documentDetail = question.documents;
                                                                                // let submittedDoc = answerDocs && answerDocs[documentDetail.document_id] || null;
                                                                                return (
                                                                                    <FormGroup tag="fieldset" key={question.id} style={extraStyle}>
                                                                                        <Row>
                                                                                            <Col sm="12">
                                                                                                {/* {qnIndex === 0 && <h5 className="subcat-subheader">सूचक क्षेत्र</h5>} */}
                                                                                                <legend className="form-qn" >{question.question}</legend>

                                                                                                {/* {qnIndex === 0 && <h5 className="subcat-subheader">अबस्था </h5>} */}
                                                                                                {question.options.map((option, opIndex) => {
                                                                                                    let checked = (selectedOption && selectedOption === option.id) ? true : false;
                                                                                                    // if (checked) {
                                                                                                    //     isCompleted[subCategory.id] = true;
                                                                                                    // }

                                                                                                    return (
                                                                                                        <FormGroup check key={option.id}>
                                                                                                            <Label check>
                                                                                                                <img src={checked?radioChecked:radioUnchecked} alt="radio button" className={checked?'radio-check':'radio-uncheck'}/>
                                                                                                                {option.name}
                                                                                                            </Label>
                                                                                                        </FormGroup>
                                                                                                    );
                                                                                                })}
                                                                                                <div className="proof">
                                                                                                    <legend className="form-qn">प्रमाणीकरणको आधार:</legend>
                                                                                                    <div className="proofs">
                                                                                                        <div className="doc-left">
                                                                                                          {this._renderDocumentListFromOption(question)}
                                                                                                        </div>
                                                                                                        <div className="doc-right">
                                                                                                            <div className="file-upload-wrapper">
                                                                                                            {/* { submittedDoc && submittedDoc.url?
                                                                                                                (<div className="file-preview">
                                                                                                                    <FilePreview url={submittedDoc.url} onRemove={this._onRemove} dId={question.documents.document_id} />
                                                                                                                </div>)
                                                                                                                :
                                                                                                                (<div className="file-drop">
                                                                                                                    <FileUpload catId={activeCategory.id} qId={question.id} documentId={question.documents.document_id} setAnwerDocs={this._setAnwerDocs} />
                                                                                                                </div>)
                                                                                                            } */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='remarks-wrapper'>
                                                                                                            <label>
                                                                                                                <input type="checkbox" checked={question.remarks} onChange={()=>console.log('Print Checkbox')}/>
                                                                                                                &nbsp;&nbsp;&nbsp;कैफियत <br />
                                                                                                                {/* <span className="remarks-info"> ( कुनै कागजात नभएको वा वेबसाइटको लिङ्क राख्नुपरेको खण्डमा अनिवार्य रुपमा भरिनु पर्ने )</span> */}
                                                                                                            </label>
                                                                                                            {answerDocs && question.remarks  && 
                                                                                                                <textarea name="textarea" value={question.remarks?question.remarks:''} onChange={(e)=>this._handleTextAreaChange(e, question.id, activeCategory.id, question.documents.document_id)} ></textarea>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </Row>

                                                                                    </FormGroup>
                                                                                );
                                                                            })}

                                                                            {/* {(activeCategoryIndex === 0 && activeTab === firstTab) ? ''
                                                                                :
                                                                                <Button className="previous" onClick={this._onPrevClick}><p>अघिल्लो</p></Button>
                                                                            }
                                                                            <div className="right-button">
                                                                                {(activeCategoryIndex === categories.length - 1 && activeTabIndex === 2) ?
                                                                                    <Button className="next" onClick={this._onFormSubmit}><p> प्रमाणित गर्नुहोस्</p></Button>
                                                                                    :
                                                                                    <Button className="next" onClick={this._onNextClick}><p>अर्को</p></Button>
                                                                                }
                                                                            </div> */}
                                                                        </Col>

                                                                    </Row>
                                                                </TabPane>
                                                            );
                                                        })}
                                                    </TabContent>
                                                </div>
                                            </section>
                                        );
                                    })}
                            </section>
                        )
                    })}
            </div>
        );
    }
}

FormPrint = reduxForm({
    // a unique name for the form
    form: 'selfAssessment'
})(FormPrint);
const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

export default connect(mapStateToProps)(FormPrint);