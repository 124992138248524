import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import compose from "recompose/compose";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavLink,
} from "reactstrap";
import Map from "./../../components/Map";
import RadarChart from "./../../components/RadarChart";
import "./style.css";
import Api from "../../services/api";
import { FaCog, FaPaperclip } from "react-icons/fa";
import {
  changeIndexToNepali,
  changeNepaliIndexToEnglish,
  colorScale,
} from "../../utils";
import { Line } from "rc-progress";
import BarGraph from "../../components/BarChart";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ReactTable from "react-table";
import "react-toastify/dist/ReactToastify.css";
import HorizontalSteps from "../../components/HorizontalSteps";
import { NavLink as RRNavLink } from "react-router-dom";
import { FaCloudUploadAlt } from "react-icons/fa";
import ReactDropzone from "react-dropzone";
import { FaCheckCircle } from "react-icons/fa";
import store from "../../store";
import localMapJson from "../../assets/geoJson/local.json";
import * as authAction from "../../actions/authAction";
import MainLoader from "../../components/MainLoader";
import * as modalAction from "../../actions/modalAction";
import GradientLabel from "../../components/GradientLabel";
import { Helmet } from "react-helmet";

class LocalDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        categories: [],
        geoJson: null,
        confirmModal: false,
      },
      status: 0,
      showDocModal: false,
      documents: [],
      isLoading: true,
      total: 0,
      mainCategories: [],
      subCategories: [],
      anubodanFile: null,
      stepData: [
        {
          date: "2021/01/01",
          title: "प्रारम्भिक नतिजा तयार हुदैँ",
        },
        {
          date: "2021/01/01",
          title: "पृष्ठपोषणका लागी पेश",
        },
        {
          date: "2021/01/01",
          title: "पृष्ठपोषण सम्पन्न",
        },
        {
          date: "2021/01/01",
          title: "अनुमोदनका लागि पेश",
        },
        {
          date: "2021/01/01",
          title: "अन्तिम नतिजा सार्वजनिक",
        },
        {
          date: "2021/01/01",
          title: "गुणस्तर परिक्षण",
        },
      ],
      submitForInReviewModal: false,
      disabled: false,
    };
    this._renderCategoriesList = this._renderCategoriesList.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  _openDocumentModal = () => {
    const { onDocumentsShow } = this.props;
    onDocumentsShow();
  };

  _loadDashboard = async () => {
    const { user } = this.props;
    let { subCategories } = this.state;
    let data, status;
    try {
      data = await Api.getIndividualDashboard({
        fiscal_year_id: user.fiscal_year_id,
        gnid: user.gnid,
      });
      status = data.response.status;
    } catch (e) {
      status = 0;
    }
    if (status > 0) {
      let geoJson = localMapJson.response.filter((palika) => {
        return user.gnid === palika.gnid;
      });
      geoJson = geoJson[0];
      let modifiedData = [];
      let totalGlobal = 0;
      let obtainedGlobal = 0;
      subCategories = [];
      data.response.detail.map((cat) => {
        let totalMarks = parseFloat(cat.marks);
        let obtainedMarks = parseFloat(cat.score);
        modifiedData.push({
          question: cat.category,
          marks: totalMarks,
          score: obtainedMarks,
          percentage: cat.percentage,
          isMainCat: true,
          isCat: true,
        });
        totalGlobal += totalMarks;
        obtainedGlobal += obtainedMarks;
        let sortedQn = cat.questions.sort(
          (a, b) =>
            parseFloat(changeNepaliIndexToEnglish(a.indicator)) -
            parseFloat(changeNepaliIndexToEnglish(b.indicator))
        );
        cat.questions.map((qn) => {
          modifiedData.push(qn);
        });

        //set total subcategory score
        cat.subCategories.map((subCategory) => {
          if (subCategories[subCategory.name]) {
            subCategories[subCategory.name] = {
              name: subCategory.name,
              marks:
                subCategories[subCategory.name].marks +
                parseFloat(subCategory.marks),
              score:
                subCategories[subCategory.name].score +
                parseFloat(subCategory.score),
            };
          } else {
            subCategories[subCategory.name] = {
              name: subCategory.name,
              marks: parseFloat(subCategory.marks),
              score: parseFloat(subCategory.score),
            };
          }
        });
      });
      let subCategoriesIndexArray = Object.keys(subCategories).map(
        (sCatKey) => subCategories[sCatKey]
      );
      subCategoriesIndexArray.map((sCat) => {
        sCat.percentage = ((sCat.score / sCat.marks) * 100).toFixed(2);
      });

      modifiedData.splice(0, 0, {
        question: "जम्मा",
        marks: totalGlobal,
        score: obtainedGlobal,
        isCat: true,
      });
      let columns = this._getColumns(modifiedData);
      let total = modifiedData[0];
      let mainCategories = modifiedData.filter((data) => {
        return !!data.isMainCat;
      });
      this.setState({
        data: modifiedData,
        geoJson: geoJson.geometry,
        columns: columns,
        status: status,
        isLoading: false,
        total,
        mainCategories,
        detailCatData: data.response.detail,
        subCategories: subCategoriesIndexArray,
      });
    } else {
      this.setState({
        status: status,
        isLoading: false,
      });
    }
  };

  async componentDidMount() {
    await this._loadDashboard();
  }

  _renderCategoriesList() {
    const { mainCategories } = this.state;
    return mainCategories.map((category, catIndex) => {
      return (
        <div className="main-list" key={catIndex}>
          <span className="dash-cat-name">
            <img
              src={require(`../../assets/images/${catIndex + 1}.svg`)}
              className="cat-icon"
              alt="cat-icon"
            />
            {category.question}
          </span>
          {/* <span className='dash-cat-rank'>{changeIndexToNepali(category.rank)}</span> */}
          <span
            className="dash-cat-score"
            style={{
              color: "white",
              background: colorScale(category.percentage),
            }}
          >
            {changeIndexToNepali(category.percentage)}%
          </span>
        </div>
      );
    });
  }

  _getColumns = (data) => {
    // sn., title, total_mark, obtained_mark , remarks
    //      question, total_mark, obtained_mark, remarks

    let columns = [
      {
        Header: "क्र.सं.",
        id: "id",
        maxWidth: 50,
        Cell: (row) => {
          return changeIndexToNepali(row.index + 1);
        },
      },
      {
        id: "title",
        Header: "सूचक",
        maxWidth: 1000,
        accessor: (d) => {
          if (d.isCat) {
            return <b>{d.question}</b>;
          } else {
            return d.question;
          }
        },
      },
      {
        id: "marks",
        Header: "पूर्णाङ्क",
        maxWidth: 150,
        accessor: (d) => {
          return changeIndexToNepali(d.marks);
        },
      },
      {
        id: "score",
        Header: "प्राप्ताङ्क",
        maxWidth: 150,
        accessor: (d) => {
          return changeIndexToNepali(d.score);
        },
      },
      {
        id: "remarks",
        Header: "कैैफियत",
        maxWidth: 200,
        accessor: (d) => {
          return changeIndexToNepali(d.remarks);
        },
      },
    ];
    return columns;
  };

  toggle() {
    this.setState({
      confirmModal: !this.state.confirmModal,
    });
  }

  _toggleModal = () => {
    this.setState({
      submitForInReviewModal: !this.state.submitForInReviewModal,
    });
  };

  _onFormClick() {
    return (
      <Redirect
        to={{
          pathname: "/selfaccessmentform",
          state: { from: this.props.location },
        }}
      />
    );
  }

  _onFormSubmit = async () => {
    const { onFormStatusChange, user } = this.props;
    //check if all ans uploaded from client
    this.setState({ isLoading: true });
    let response = await Api.updateFormStatus({
      fiscal_year_id: user.fiscal_year_id,
      status: 1,
    });
    if (response.code === 200) {
      toast.success("प्रारम्भिक नतिजा प्रमाणीकरणका लागि पेश गर्न सफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      onFormStatusChange(1);
      this._toggleModal();
      await this._loadDashboard();
    } else {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    }
  };

  _onApproveClick = async () => {
    // let response = await Api.approveAnswers({fiscal_year_id: 1});
    const { user } = this.props;
    this.setState({ isLoading: true });
    let response = await Api.updateFormStatus({
      fiscal_year_id: user.fiscal_year_id,
      status: 3,
    });
    if (response.code === 200) {
      toast.success("अनुमोदनका लागि पेश गर्न सफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.props.onFormStatusChange(2);
      this.toggle();
      await this._loadDashboard();
    } else {
      toast.error("अनुमोदनका लागि पेश गर्न असफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    }
  };

  _onAnubodanClick = async () => {
    const { user } = this.props;
    this.setState({ isLoading: true });
    let {
      auth: { token },
    } = store.getState();
    let { anubodanFile } = this.state;
    // let response = await Api.approveAnswers({fiscal_year_id: 1});
    let response = await Api.updateFormStatusFinal(anubodanFile, {
      fiscal_year_id: user.fiscal_year_id,
      status: 4,
      user_token: token,
    });
    if (response.code === 200) {
      toast.success("नतिजा सार्वजनिक गर्न सफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.props.onFormStatusChange(3);
      this.toggle();
      await this._loadDashboard();
    } else {
      toast.error("अनुमोदनका लागि पेश गर्न असफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    }
  };

  onDrop = async (files) => {
    this.setState({ anubodanFile: files });
  };

  _renderDashboard = () => {
    const { user } = this.props;
    const {
      data,
      geoJson,
      confirmModal,
      showDocModal,
      documents,
      columns,
      status,
      isLoading,
      total,
      mainCategories,
      detailCatData,
      subCategories,
      anubodanFile,
    } = this.state;

    if (status === 0) {
      if (user.role !== "Palika Admin (Focal Person)") {
        return (
          <p>
            प्रारम्भिक नतिजा तयार भए, कृपया फारमको सबै सूचकहरूमा स्वमुल्याङ्कन
            भएको र आवश्यक सबै प्रमाणीकरणका आधारहरु रहेको रजु गरी, पालिका सम्पर्क
            व्यक्ति (Focal Person/ Palika Admin) लाई सम्पर्क गर्नुहोस्।
          </p>
        );
      } else {
        return (
          <>
            <p>
              प्रारम्भिक नतिजा प्राप्त भए, कृपया फारमको सबै सूचकहरूमा
              स्वमुल्याङ्कन भएको र आवश्यक सबै प्रमाणीकरणका आधारहरु रहेको रजु
              गर्नुहोस्। छलफल र प्रमाणीकरणका लागि तयार भए फाराम "पेश गर्नुहोस्"
              ।
            </p>
            <Button className="form-button" color="primary">
              <NavLink
                tag={RRNavLink}
                to={`/selfassessmentform/${user.gnid}`}
                style={{ color: "white" }}
              >
                फारम रुजु गर्नुहोस
              </NavLink>
            </Button>
            <Button
              color="success"
              onClick={() => {
                this._toggleModal();
              }}
              disabled={user.entry_status === 0}
            >
              पेश गर्नुहोस्
            </Button>
          </>
        );
      }
    }
    if (status == 2) {
      if (user.role !== "Palika Approver (CAO)") {
        return (
          <p>
            कृपया पालिकाको CAO प्रयोगकर्ताद्वारा प्रारम्भिक नतिजा प्रमाणीकरण
            गराउनुहोस्।
          </p>
        );
      } else {
        return (
          <>
            <div>
              प्रारम्भिक नतिजा प्रमाणीकरणका लागि पेश भएको छ। कृपया स्वमुल्याङ्कन
              प्रकृया र प्रमाणीकरणका आधारहरुको जाँच गरी प्राप्त प्रारम्भिक नतिजा
              छलफल र अनुमोदनका लागि "पेश गर्नुहोस्"
            </div>
            <div className="button-wrapper">
              <Button className="form-button" color="primary">
                <NavLink
                  tag={RRNavLink}
                  to={`/selfassessmentform/${user.gnid}`}
                  style={{ color: "white" }}
                >
                  फारम रुजु गर्नुहोस
                </NavLink>
              </Button>
              <Button
                color="success"
                className="save-form"
                onClick={() => this.toggle()}
                style={{ marginRight: 10, border: "none" }}
                disabled={user.entry_status === 0}
              >
                अनुमोदनका लागि पेश गर्नुहोस्
              </Button>
              <Modal
                isOpen={confirmModal}
                toggle={this.toggle}
                className="submit-modal"
              >
                <ModalHeader toggle={this.toggle}>
                  अनुमोदनका लागि पेश गर्नुहोस्
                </ModalHeader>
                <ModalBody>
                  के तपाईँ अनुमोदनका लागि पेश गर्न चाहनुहुन्छ?
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={this._onApproveClick}>
                    अनुमोदनका लागि पेश गर्नुहोस्
                  </Button>{" "}
                  <Button
                    color="danger"
                    disabled={user.entry_status === 0}
                    onClick={this.toggle}
                  >
                    रद्द गर्नुहोस्
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </>
        );
      }
    }
    if (status == 1) {
      return (
        <>
          <p>पृष्ठपोषण गर्नुहोस्।</p>
          <Button
            className="form-button"
            color="primary"
            disabled={user.entry_status === 0}
          >
            <NavLink
              tag={RRNavLink}
              to={`/selfassessmentform/${user.gnid}`}
              style={{ color: "white" }}
            >
              पृष्ठपोषण गर्नुहोस्।
            </NavLink>
          </Button>
        </>
      );
    }
    if (status == 3) {
      if (user.role !== "Palika Approver (CAO)") {
        return (
          <p>
            कृपया पालिकाको CAO प्रयोगकर्ताद्वारा कार्यपालिकाबाट अनुमोदन भएको
            नतिजा सुनिश्चित गरि सार्वजनिक गर्नुहोस्।
          </p>
        );
      } else {
        return (
          <>
            <div>
              कृपया कार्यपालिकाबाट अनुमोदन भएको नतिजा सुनिश्चित गरि सार्वजनिक
              गर्नुहोस्।
            </div>
            <div className="button-wrapper">
              <Button
                color="success"
                className="save-form"
                onClick={() => this.toggle()}
                style={{ marginRight: 10, border: "none" }}
              >
                नतिजा सार्वजनिक गर्नुहोस्
              </Button>
              <Modal
                isOpen={confirmModal}
                toggle={this.toggle}
                className="submit-modal"
              >
                <ModalHeader toggle={this.toggle}>
                  नतिजा सार्वजनिक गर्नुहोस्
                </ModalHeader>
                <ModalBody>
                  प्रमाणित गरिसके पश्चात, स्व-मूल्यांकनका सूचक वा नतिजा थप-घट
                  गर्न नपाईने, साथै सर्व-साधारणले यस प्रणाली मार्फत हेर्ने गरि
                  नतिजा खुला हुने भएकाले, निम्न कुरामा ध्यान दिनु हुन अनुरोध छ :
                  <br />
                  - स्वमूल्याङ्कनका प्रत्येक सूचकहरूमा प्रमाणिकरणको आधारहरू
                  (कागजातहरु) अनिवार्य रूपमा संलग्न गरी रुजु गरिसकिएको |<br />-
                  प्राप्त नतिजालाई कार्यपालिकाले स्वीकृत गरी सभामा छलफल र
                  अनुमोदन गर्ने व्यवस्था भईसकेको |
                  {anubodanFile ? (
                    <p>
                      <FaCheckCircle
                        className="check-circle-doc"
                        color="#28a745"
                      />
                      कार्यपालिकाले स्वीकृत गरी सभामा छलफल र अनुमोदन गरिएको पत्र
                    </p>
                  ) : (
                    <ReactDropzone
                      onDrop={this.onDrop}
                      className="upload-drop"
                      multiple={false}
                    >
                      <ToastContainer />
                      <div className="upload-box">
                        <FaCloudUploadAlt />
                        <span className="upload-text">अपलोड गर्नुहोस्</span>
                      </div>
                    </ReactDropzone>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="success" onClick={this._onAnubodanClick}>
                    नतिजा सार्वजनिक गर्नुहोस्
                  </Button>{" "}
                  <Button color="danger" onClick={this.toggle}>
                    रद्द गर्नुहोस्
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </>
        );
      }
    }
    if (status === 4) {
      if (true) {
        return (
          <>
            <p>
              प्रारम्भिक नतिजा प्राप्त भए, कृपया फारमको सबै सूचकहरूमा
              स्वमुल्याङ्कन भएको र आवश्यक सबै प्रमाणीकरणका आधारहरु रहेको रजु
              गर्नुहोस्। छलफल र प्रमाणीकरणका लागि तयार भए फाराम "पेश गर्नुहोस्"
              ।
            </p>
          </>
        );
      } else {
        return (
          <>
            <p>
              प्रारम्भिक नतिजा प्राप्त भए, कृपया फारमको सबै सूचकहरूमा
              स्वमुल्याङ्कन भएको र आवश्यक सबै प्रमाणीकरणका आधारहरु रहेको रजु
              गर्नुहोस्। छलफल र प्रमाणीकरणका लागि तयार भए फाराम "पेश गर्नुहोस्"
              ।
            </p>
            <Button className="form-button" color="primary">
              <NavLink
                tag={RRNavLink}
                to="/selfassessmentform"
                style={{ color: "white" }}
              >
                फारम रुजु गर्नुहोस
              </NavLink>
            </Button>
            <Button
              color="success"
              onClick={() => {
                this._toggleModal();
              }}
            >
              पेश गर्नुहोस्
            </Button>
          </>
        );
      }
    }
  };

  _renderDetailDashboard = () => {
    const { user } = this.props;
    const {
      data,
      geoJson,
      confirmModal,
      showDocModal,
      documents,
      columns,
      status,
      isLoading,
      total,
      mainCategories,
      detailCatData,
      subCategories,
      anubodanFile,
    } = this.state;
    return (
      <>
        <div>
          <div className="header">
            <h1 className="heading">{user.palika_name}</h1>{" "}
            {/*TODO show , district name from map data*/}
            <GradientLabel />
          </div>
          <div className="content">
            <Row>
              <Col sm="9">
                <div className="card-layout">
                  <h3 className="sub-heading">जम्मा प्राप्ताङ्क</h3>
                  <div className="progress-section">
                    <Row>
                      <Col sm="2">
                        <h3
                          className="green-number"
                          style={{ color: colorScale(total.score) }}
                        >
                          {changeIndexToNepali(total.score)}%
                        </h3>
                        <p>औसत</p>
                      </Col>
                      <Col sm="8">
                        <div className="progress-box">
                          {subCategories.map((sCat, sIndex) => {
                            return (
                              <Row key={sIndex}>
                                <Col sm="2">
                                  <h3 className="progressbar-text">
                                    {sCat.name}
                                  </h3>
                                </Col>
                                <Col sm="10">
                                  <div
                                    style={{ marginLeft: `${sCat.score}%` }}
                                    className="progress-percent"
                                  >
                                    {changeIndexToNepali(sCat.percentage)}%
                                  </div>
                                  <Line
                                    percent={sCat.percentage}
                                    strokeWidth={1}
                                    strokeColor={colorScale(sCat.percentage)}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                {/* <div className="ranking-section">
                              <Row>
                                  <Col sm="2">
                                      <h3 className="red-number">{changeIndexToNepali(data.rank)} / {changeIndexToNepali(data.total)}</h3>
                                      <p>बरीयतक्रम </p>
                                  </Col>
                                  <Col sm="8">
                                      <div className="ranking-timeline">
                                          <span className="vertical-line"></span>
                                          <span className="circle" style={{ marginLeft: "100px" }}>{changeIndexToNepali(data.rank-3)}  <label>{data.rankNeighbours.prev3.name}</label></span>
                                          <span className="circle" style={{ marginLeft: "200px" }}>{changeIndexToNepali(data.rank-2)} <label>{data.rankNeighbours.prev2.name}</label></span>
                                          <span className="circle" style={{ marginLeft: "300px" }}>{changeIndexToNepali(data.rank-1)} <label>{data.rankNeighbours.prev1.name} </label> </span>
                                          <span className="circle-active" style={{ marginLeft: "400px" }}>{changeIndexToNepali(data.rank)} <label>{data.name} </label></span>
                                          <span className="circle" style={{ marginLeft: "500px" }}>{changeIndexToNepali(data.rank+1)} <label>{data.rankNeighbours.next1.name}  </label></span>
                                          <span className="circle" style={{ marginLeft: "600px" }}>{changeIndexToNepali(data.rank+2)} <label>{data.rankNeighbours.next2.name}  </label></span>
                                          <span className="circle" style={{ marginLeft: "700px" }}>{changeIndexToNepali(data.rank+3)} <label>{data.rankNeighbours.next3.name} </label></span>

                                      </div>
                                  </Col>
                              </Row>
                          </div> */}
              </Col>
              <Col sm="3">
                <div className="card-layout">
                  <h3 className="sub-heading2">नक्सा </h3>
                  <div className="leaflet-container">
                    <Map geoJson={geoJson} />
                  </div>
                  <p className="location">{data.name}</p>
                </div>
              </Col>
            </Row>
            <div className="chart-section">
              <Row>
                <Col sm="4">
                  <div className="cat-list card-layout">
                    <h3 className="sub-heading">
                      {" "}
                      स्व-मूल्याङ्कन विषयक्षेत्रहरु
                    </h3>
                    <div className="main-list">
                      <span className="dash-cat-name-title"></span>
                      {/* <span className='dash-cat-rank-title'>बरीयतक्रम</span> */}
                      <span className="dash-cat-score-title">
                        स्कोर प्रतिशत
                      </span>
                    </div>
                    {this._renderCategoriesList()}
                  </div>
                </Col>
                <Col sm="8">
                  <div className="card-layout">
                    <RadarChart
                      width={800}
                      height={500}
                      data={mainCategories}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bar-chart">
              <Row>
                <Col sm="12">
                  <div className="bar-chart">
                    <Row>
                      <Col sm="12">
                        <div className="card-layout bar-graph">
                          <BarGraph data={mainCategories} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="data-details">
              <Row>
                {detailCatData.map((category, catIndex) => {
                  return (
                    <Col sm="4" key={catIndex}>
                      <div className="details-box card-layout">
                        <div className="data-header">
                          <h3 className="data-name">
                            {category.category}
                            {/* <FaPaperclip className="cat-attatchments" onClick={() => this._getCatAttatchments(category.id)} color={'#898989'} /> */}
                          </h3>
                          <div className="rank-score">
                            <div className="score">
                              <h3
                                className="data-score"
                                style={{
                                  color: colorScale(
                                    mainCategories[catIndex].percentage
                                  ),
                                }}
                              >
                                {changeIndexToNepali(
                                  mainCategories[catIndex].percentage
                                )}
                                %
                              </h3>
                              <p> स्कोर प्रतिशत</p>
                            </div>
                          </div>
                        </div>
                        <div className="main-list main-list-header">
                          <span className="dash-cat-name">तहहरु</span>
                          <span className="dash-cat-score-title">स्कोर</span>
                        </div>
                        {category.subCategories.map((sCat, sCatIndex) => (
                          <div className="main-list" key={sCatIndex}>
                            <span className="dash-cat-name">{sCat.name}</span>
                            <span
                              className="dash-cat-score"
                              style={{
                                color: "white",
                                background: colorScale(sCat.percentage),
                              }}
                            >
                              {changeIndexToNepali(sCat.percentage)}%
                            </span>
                          </div>
                        ))}
                      </div>
                    </Col>
                  );
                })}
                <Modal
                  isOpen={showDocModal}
                  toggle={() => this._toggleCatAttatchmentModal()}
                  className="delete-modal"
                >
                  <ModalHeader>प्रमाणीकरणको आधार</ModalHeader>
                  <ModalBody>
                    {documents.map((document) => {
                      return (
                        <a
                          target="_blank"
                          href={document.url}
                          key={document.id}
                        >
                          <p>{document.name}</p>
                        </a>
                      );
                    })}
                  </ModalBody>
                </Modal>
              </Row>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          <div className="report-header">
            <h2>रिपोर्ट टेबल</h2>
            <div className="report-controls">
              <Button
                onClick={() => this._openDocumentModal()}
                className="uni-menu-item"
              >
                कागजातहरु हेर्नुहोस
              </Button>
            </div>
          </div>
          <ReactTable
            data={data}
            columns={columns}
            defaultPageSize={111}
            className="-striped -highlight"
          />
        </div>
      </>
    );
  };

  render() {
    const { user } = this.props;
    const {
      data,
      geoJson,
      confirmModal,
      showDocModal,
      documents,
      columns,
      status,
      stepData,
      isLoading,
      submitForInReviewModal,
    } = this.state;
    return (
      <MainLoader active={isLoading}>
        <Helmet>
          <title>LISA Nepal - Dashboard</title>
        </Helmet>
        <section className="dashboard">
          <ToastContainer />
          <HorizontalSteps activeStep={status} stepData={stepData} />
          {!isLoading && this._renderDashboard()}
          {status > 0 && this._renderDetailDashboard()}
          <Modal
            isOpen={submitForInReviewModal}
            toggle={this._toggleModal}
            className="delete-modal"
          >
            <ModalHeader toggle={this._toggleModal}>
              प्रारम्भिक नतिजा पेश
            </ModalHeader>
            <ModalBody>
              के तपाईँ यो प्रारम्भिक नतिजा पेश गर्न चाहनुहुन्छ?
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  this._onFormSubmit();
                }}
                disabled={user.entry_status === 0}
              >
                पेश गर्नुहोस्
              </Button>
              <Button color="red" onClick={this._toggleModal}>
                रद्द गर्नुहोस्
              </Button>
            </ModalFooter>
          </Modal>
        </section>
      </MainLoader>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  onFormStatusChange: (status) => {
    dispatch(authAction.setUserStatus(status));
  },
  onDocumentsShow: (modalProps) => {
    dispatch(
      modalAction.processModal(
        modalAction.SHOW_MODAL,
        modalAction.ModalTypes.SHOW_DOCUMENT,
        modalProps
      )
    );
  },
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LocalDashboard);
