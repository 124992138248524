import * as actions from '../actions/authAction';

let initialState = { 
    isAuthenticated: false,
    user: {
        
    },
    login: {
        fiscal_year_id: null
    },
    token: null
};
if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user")).isAuthenticated) {
    initialState =  JSON.parse(localStorage.getItem("user"));
}
export default  (state=initialState, action) => {
    
    switch(action.type) {
    case actions.LOGIN:
        localStorage.setItem("user", JSON.stringify({ ...state, isAuthenticated: true }));
        return { ...state, isAuthenticated: true };
    case actions.LOGOUT:
        localStorage.removeItem("user");
        let newInitialState = { 
            isAuthenticated: false,
            user: {
                
            },
            login: {
                fiscal_year_id: null
            },
            token: null
        };
        return { ...newInitialState };
    case actions.LOGIN_CRED:
        return { ...state, login: action.cred || {} };
    case actions.SET_USER:
        return { ...state, user: action.user || {} };
    case actions.SET_TOKEN:
        return { ...state, token: action.token || {} };
    case actions.SET_FORM_STATUS:
        return { ...state, user: {...state.user, form_status: action.form_status} || {} };
    default: 
        return state;
    }
};
