import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../services/api';
import './style.css';
import localMapJson from "../../assets/geoJson/local.json";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { BounceLoader } from "react-spinners";
import { ToastContainer } from 'react-toastify';
import logo from '../../assets/images/logo.svg';
import { FaPrint, } from 'react-icons/fa';
import auth from '../../reducers/auth';
import { connect, shallowEqual, useSelector } from 'react-redux'
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

const QaReport = ({ user }) => {

  const [questionLoading, setQuestionLoading] = useState(false)
  const [questionData, setQuestionData] = useState([])
  const [reportData, setReportData] = useState({})
  const [feedback1, setFeedback1] = useState('')
  const [feedback2, setFeedback2] = useState('')
  const [palikaName, setPalikaName] = useState('')

  let fiscalYear = user.fiscal_year_id;
  let gnId = window.location.search.substring(6);

  const initialCall = useCallback(
    async () => {
      setQuestionLoading(true);
      let response = await Api.getQaIndex(fiscalYear, gnId);
      if (response && response.code === 200) {
        setReportData(response.response)
        setQuestionData(response.response.questions)
        setFeedback1(response.response.qa_feedback)
        setFeedback2(response.response.qa_feedback_others)
      }
      setQuestionLoading(false);

      let geoJson = localMapJson.response.find((palika) => {
        return gnId === palika.gnid;
      });
      setPalikaName(geoJson.name)
    },
    [],
  )

  // const getReportData = async () => {
  //   if (gnId && fiscalYear) {
  //     const res = await Api.getQADashboard(fiscalYear);
  //     if (res.code === 200 && res.response && res.response.length > 0) {
  //       const data = res.response.find((item) => item.gnid == gnId);
  //       console.log(data)
  //       setReportData(data)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   getReportData()
  // }, [gnId, fiscalYear])

  useEffect(() => {
    initialCall()
  }, [initialCall])

  const internalControlRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => internalControlRef.current,
  });

  return (
    <>
      {questionLoading ? (
        <div className="w-100 d-flex justify-content-center my-5"> <BounceLoader color='#1f8cd5' /></div>
      ) : (<>
        {reportData && reportData.qa_status == 'complete' ? (


          <div className="manual-wrapper">
            <div className="pb-3 sticky-heading-qa-report">
              <div>
                <Button onClick={handlePrint} color="success" className="print-button">
                  <FaPrint className="print-icon" />
                  Print
                </Button>
              </div>
            </div>

            <div ref={internalControlRef}>
              <div className="print-header print-only-qa-report">
                <img src={logo} alt="logo" className="nav-logo-user" />
                <div style={{ color: '#e12a2a', marginLeft: 20, fontSize: 14 }}>
                  <span style={{ color: '#676767', fontSize: 20 }}>
                    {palikaName} <br />
                  </span>
                  <span style={{ color: '#2572bc', fontSize: 14 }}>-<br /></span>
                  Local Government Institutional Capacity Self-Assessment
                </div>
              </div>
              <div className="qa-report-head">
                <table style={{ width: '100%' }}>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '50%' }}><h4>स्थानीय तह: {palikaName}</h4></td>
                    <td style={{ width: '50%' }}><h4>जिल्ला: {reportData.district}</h4></td>
                  </tr>
                </table>
              </div>

              <table className="waffle" cellSpacing={5} style={{ borderCollapse: 'collapse', border: '0.5px solid' }} cellPadding={10} width={"100%"}>
                <thead className="table-heading-qa-report" style={{ border: '0.5px solid' }}>
                  <tr style={{ border: '0.5px solid' }}>
                    <td className="font-weight-bold text-right" style={{ minWidth: '75px', border: '0.5px solid' }}>सूचक नं.</td>
                    <td className="font-weight-bold" style={{ border: '0.5px solid' }}>विषय क्षेत्र र सूचक</td>
                    <td className="font-weight-bold" style={{ border: '0.5px solid' }}>प्रविष्ट भएको अङ्क</td>
                    <td className="font-weight-bold" style={{ border: '0.5px solid' }}>यथार्थमा कायम हुने अङ्क</td>
                    <td className="font-weight-bold" style={{ minWidth: '200px', border: '0.5px solid' }}>कैफियत</td>
                  </tr>
                </thead>
                <tbody>
                  {questionData.map((item) => (
                    <Fragment key={item.id}>
                      <tr className="table-bg-white" style={{ border: '0.5px solid' }}>
                        <td className="text-left" style={{ border: '0.5px solid' }}>{item.indicator}</td>
                        <td className="text-left" style={{ border: '0.5px solid' }}>{item.question}</td>
                        <td className="text-left" style={{ border: '0.5px solid' }}>{item.score}</td>
                        <td className="text-left" style={{ border: '0.5px solid' }}>{item.qa_score}</td>
                        <td className="text-left" style={{ border: '0.5px solid' }}>{item.qa_remarks}</td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
              <div className='pb-2'>
                <p><i>* कैफियत महलमा स्थानीय तहले प्रविष्ट गरे अनुसार अङ्क प्राप्त नहुने भएमा सो को कारण,  प्रमाण के हुनुपर्ने थियो र के राखिएको छ  सो उल्लेख गर्ने ।</i></p>
              </div>
              <div>
                <h4>निष्कर्ष:</h4>
                <h6>सम्बन्धित स्थानीय तहले गरेको स्वमूल्याङ्कन यथार्थपरक भए/ नभएको सम्वन्धमा सो को गुणस्तर मापन गर्दा निम्न अवस्था हुने देखिन्छ:</h6>
              </div>
              <div className='pb-3'>
                <table style={{ width: '100%' }}>
                  <tr style={{ width: '100%' }}>
                    <td style={{ width: '50%' }}><p>स्थानीय तहले प्रविष्टि गरेको अङ्क: &nbsp;<span style={{ borderStyle: 'solid', borderWidth: '0.5px', padding: '0 5px' }}>{reportData.score} </span></p></td>
                    <td style={{ width: '50%' }}><p>थप घट भई कायम हुने अङ्क: &nbsp;<span style={{ borderStyle: 'solid', borderWidth: '0.5px', padding: '0 5px' }}>{reportData.qa_score} </span></p></td>
                  </tr>
                </table>

              </div>
              <div className='pb-3'>
                <h5>स्थानीय तहलाई गुणस्तर परीक्षणर्ताको सुझाव (वुँदागत रुपमा):</h5>
                <p style={{ borderStyle: 'solid', borderWidth: '0.5px', padding: '5px 10px', minHeight: '90px' }}>{feedback1}</p>
                <h5>अन्य केही भए:</h5>
                <p style={{ borderStyle: 'solid', borderWidth: '0.5px', padding: '5px 10px', minHeight: '70px' }}>{feedback2}</p>
              </div>
              <div>
                <h4 className='pb-2'>गुणस्तर परीक्षणकर्ताको विवरण:</h4>
                <table style={{ width: '100%' }}>
                  <tr style={{ width: '100%' }}>
                    <td className='pb-3' style={{ width: '50%' }}><p>परीक्षणकर्ताको नाम: &nbsp; &nbsp;{user.name}</p></td>
                    <td className='pb-3' style={{ width: '50%' }}><p>हस्ताक्षर: ________________________________________</p></td>
                  </tr>
                </table>
                <p>मिति: {reportData.qa_submission_date}</p>
              </div>
            </div>
          </div>
        ) : (<h5>रिपोर्ट प्रकाशित भएको छैन</h5>)}
      </>
      )}

      <ToastContainer />
    </>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const enhance = compose(withRouter, connect(mapStateToProps, null));


export default enhance(QaReport);
