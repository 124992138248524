import React from 'react';
import ReactDOM from 'react-dom';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import Store from './store';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import ModalRoot from './components/modals/ModalRoot';
import "react-table/react-table.css";
import 'rc-steps/assets/index.css';
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import topbar from 'topbar'


topbar.config({
  autoRun: true,
  barThickness: 4,
  barColors: {
    '0': 'rgb(98, 214, 23)',
    '.25': 'rgba(100, 216, 93)',
    '.50': 'rgba(56, 180, 58)',
    '.75': 'rgba(43, 89, 146)',
    '1.0': 'rgba(5, 11, 194)'
  },
  shadowBlur: 12,
  shadowColor: 'rgba(0,   0,   0,   .6)'
})


ReactDOM.render(
  <Provider store={Store}>
    <>
      <Routes />
      <ModalRoot />
    </>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
