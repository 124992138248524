import React, { useEffect, useState, useMemo } from "react";
import './style.css';
import Api from '../../services/api';
import store from '../../store';
import Table from '../../components/Table';
import MainLoader from '../../components/MainLoader';
import HeaderTable from "../../components/DataTable/HeaderTable";
import PaginationComponent from "../../components/DataTable/Pagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SearchTable from "../../components/DataTable/Search";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { changeIndexToNepali } from '../../utils';
import { Helmet } from "react-helmet";
import { FaSpinner } from "react-icons/fa";


function QADashboard({ user }) {
  const [data, setData] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const ITEMS_PER_PAGE = 20;


  // const postStatus = async (gnid, status_id) => {
  //   if (status_id == 2) {
  //     setApproveLoading(true)
  //   } else {
  //     setSaveLoading(true)
  //   }
  //   let response = await Api.changeStatus({
  //     fiscal_year_id: user.fiscal_year_id,
  //     status: status_id,
  //     gnid: gnid
  //   });

  //   setSaveLoading(false);
  //   setApproveLoading(false)

  //   if (response.code === 200) {
  //     toast.success(response.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     if (status_id == 2) {
  //       toggle2()
  //     } else {
  //       toggle()
  //     }
  //     await _loadDashboard();
  //   } else if (response.code === 201) {
  //     toast.error(response.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     if (status_id == 2) {
  //       toggle2()
  //     } else {
  //       toggle()
  //     }
  //   } else {
  //     toast.error(response.message, {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     if (status_id == 2) {
  //       toggle2()
  //     } else {
  //       toggle()
  //     }
  //   }
  // }


  const headers = [
    { name: "क्र.स.", field: "id", sortable: false },
    { name: "नगरपालिका/ गाउँपालिका", field: "name", sortable: false },
    { name: "जिल्ला", field: "district", sortable: false },
    { name: "प्रदेश", field: "province", sortable: false },
    { name: "परिक्षण सम्पन्न", field: "qa_submission_date", sortable: false },
    { name: "साविक अंक", field: "score", sortable: false },
    { name: "हालको अंक", field: "qa_score", sortable: false },
    { name: "कार्य  (फारम / प्रतिवेदन )", field: "action", sortable: false },
  ];


  const commentsData = useMemo(() => {
    let computedComments = data;

    if (search) {
      computedComments = computedComments.filter(
        comment =>
          comment.name.toLowerCase().includes(search.toLowerCase())
        // ||
        // comment.img.toLowerCase().includes(search.toLowerCase())
      );
    }
    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [data, currentPage, search, sorting]);


  const _loadDashboard = async () => {
    setLoading(true)
    const res = await Api.getQADashboard(user.fiscal_year_id);
    if (res.code === 200) {
      setData(res.response)
    }
    setLoading(false)
  }

  useEffect(() => {
    _loadDashboard()
  }, []);

  return (
    <MainLoader active={isLoading}>
      <Helmet>
        <title>LISA Nepal - QA Dashboard</title>
      </Helmet>
      {data &&
        <div className="card-layout">
          <ToastContainer />
          <div className="row">
            <div className="col mb-3 col-12 text-center">
              <div className="row table-heading">
                <div className="col-md-6">
                  {/* <h1 className="sub-heading">{title}</h1> */}
                </div>
                <div className="col-md-6 d-flex flex-row-reverse dataTables_search">
                  <SearchTable
                    onSearch={value => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              {commentsData == '' ? <h4>विवरण उपलब्ध छैन</h4> :
                <table className="table">
                  <HeaderTable
                    headers={headers}
                    onSorting={(field, order) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {commentsData.map((comment, i) => (
                      <tr>
                        <th scope="row" key={comment.id}>
                          {++i}
                        </th>
                        <td>
                          {comment.name}
                        </td>
                        <td>
                          {comment.district}
                        </td>
                        <td>
                          {comment.province}
                        </td>
                        <td>
                          {comment.qa_submission_date || 'नभएको'}
                        </td>
                        <td>
                          {comment.score}
                        </td>
                        <td>
                          {comment.qa_score}
                        </td>
                        <td>
                          <Link className="btn btn-sm btn-primary mr-2" to={`/qa?gnid=${comment.gnid}`}>फारम</Link>
                          {comment.qa_submission_date && <Link className="btn btn-sm btn-primary" to={`/qa-report?gnid=${comment.gnid}`}>रिपोर्ट</Link>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>}
            </div>
          </div>

        </div>

      }
    </MainLoader>
  )
}

export default QADashboard;
