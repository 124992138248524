import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentModal from '../DocumentModal';
import { ModalTypes } from '../../../actions/modalAction';


class ModalRoot extends Component {
    render() {
      
        const MODAL_COMPONENTS = {
            [ModalTypes.SHOW_DOCUMENT]: DocumentModal,
        };
        const { modalType, modalProps } = this.props.modal.activeModal;
        if (!modalType) {
            return null;
        }
        const SpecificModal = MODAL_COMPONENTS[modalType];
        return (<SpecificModal {...modalProps} />);
    }
}

const mapStateToProps = ({ modal }) => {
    return {
        modal: modal
    };
};

export default connect(mapStateToProps)(ModalRoot);