import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Input
} from 'reactstrap';
import * as modalAction from '../../../actions/modalAction';
import { changeIndexToNepali } from '../../../utils';
import './styles.css';
import Api from '../../../services/api';
import { FaCheckCircle, FaPrint, FaExclamationCircle, FaTrash } from 'react-icons/fa';
import ReactTable from "react-table";
import FileUpload from '../../FileUpload';
import MainLoader from '../../MainLoader';
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

class DocumentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      documents: null,
      originalData: null,
      columns: null,
      activeDocument: null,
      showDeleteModal: false,
      modalType: 'upload'
    };
    this._sendRemarks = _.debounce(this._sendRemarks, 1500);
    this._handleTextAreaChange = this._handleTextAreaChange.bind(this);
  }



  async componentDidMount() {
    const { modal } = this.props;
    if (modal && modal.activeModal && modal.activeModal.modalProps && modal.activeModal.modalProps.modalType) {
      this.setState({
        modalType: modal.activeModal.modalProps.modalType
      })
    }
    await this._loadDocs();
    this._scrollToDocument();

  }

  _toggleLoader = () => {
    this.setState({ isLoading: !this.state.isLoading });
  }

  _scrollToDocument = () => {
    let { clickedDocument } = this.props;
    if (clickedDocument) {
      this['docRef' + clickedDocument.id].scrollIntoView();
    }
  }

  _getCategoryDisabled = (document) => {
    const { user, modal } = this.props;
    // const modalType = modal.activeModal.modalProps.modalType || 'upload'
    // this.setState({
    //   modalType: modalType
    // })
    let disabled = true;
    if (user.role === 'Palika Data Entry') {
      let allowedCategories = user.categories;
      document.questions && document.questions.length > 0 && document.questions.map(question => {
        if (allowedCategories.includes(question.category_id)) {
          disabled = false;
        }
      });
    } else {
      disabled = false;
    }
    return disabled;
  }

  _loadTable = async () => {
    const { user } = this.props;
    let enabled = (user.role === 'Palika Approver (CAO)' || user.role === 'Palika Data Entry');
    if (user.form_status > 1 && user.role === 'Palika Data Entry') {
      enabled = false;
    }
    let columns = [{
      Header: 'क्र.सं.',
      maxWidth: 50,
      id: 'id',
      Cell: (row) => {
        return changeIndexToNepali(row.index + 1);
      }
    }, {
      Header: 'नाम',
      id: 'name',
      maxWidth: 300,
      accessor: d => {
        let refName = 'docRef' + d.id;
        this[refName] = React.createRef();
        return (
          <span id={refName} ref={el => (this[refName] = el)} className="lg-name">{d.name}</span>
        );
      },
    }, {
      Header: 'सन्दर्भ',
      id: 'indicator',
      accessor: d => {
        let indicators = d.questions && d.questions.length > 0 && d.questions.map(q => q.indicator + ' ' + q.name);
        return (
          <span className="lg-name">{indicators && indicators.length > 0 && indicators.join(', \n')}</span>
        );
      },
    }, {
      Header: 'फाइल',
      id: 'file',
      maxWidth: 300,
      accessor: d => {
        if (d.path) {
          return (
            <a href={d.path} target="_blank"><p className="document-name" key={d.id}>&bull; {d.name}<FaCheckCircle className="check-circle-doc" color='#28a745' /></p></a>
          )
        }
        else {
          let catDisabled = this._getCategoryDisabled(d);
          if (enabled && !catDisabled && this.state.modalType !== 'view') {
            return (
              <>
                <FileUpload document={d} onUploaded={this._onUploaded} toggleLoader={this._toggleLoader} />
                <FaExclamationCircle className="check-circle-doc" color='#e12a2a' />
              </>
            )
          }
          else {
            return "-"
          }
        }
      },
    }, {
      Header: 'कैफियत',
      id: 'remarks',
      accessor: d => {
        let remarksEnabled = enabled;
        if (!d.path) remarksEnabled = false;
        let catDisabled = this._getCategoryDisabled(d);
        if (catDisabled) {
          remarksEnabled = !catDisabled;
        }
        return (
          <div className='document-remarks-wrapper'>
            <textarea name="textarea" disabled={!remarksEnabled || this.state.modalType === 'view'} value={d.remarks || ''} onChange={(e) => this._handleTextAreaChange(e, d)} ></textarea>
          </div>
        )
      },
    }, {
      Header: 'अपलोडकर्ता',
      id: 'uploaded_by',
      maxWidth: 200,
      accessor: (d) => {
        if (d.path && d.uploaded_by) {
          return (
            <span className={"uploaded_by"}>{d.uploaded_by} {new Date(d.uploaded_on).toLocaleDateString('ne-NP', { day: 'numeric', month: 'short', year: 'numeric' })} </span>
          )
        } else {
          return false;
        }

      },
    }];
    if (enabled && this.state.modalType !== 'view') {
      columns.push({
        Header: 'कार्य',
        maxWidth: 50,
        id: 'action',
        accessor: (d) => {
          let catDisabled = this._getCategoryDisabled(d);
          if (d.path && enabled && !catDisabled) {
            return (
              <FaTrash onClick={async () => await this._toggleDeleteModal(d)} className="remove-doc" color='#e12a2a' />
            )
          } else {
            return false;
          }

        },
      });
    }
    this.setState({ columns: columns });
  }

  _loadDocs = async () => {
    const { user, gnid, fyId } = this.props;
    // let fyId = user.fiscal_year_id || localStorage.getItem('fiscalYear');
    let fiscalId = fyId || user.fiscal_year_id;
    console.log(fiscalId, user.fiscal_year_id, localStorage.getItem('fiscalYear'))
    let palikaGnid = user.gnid ? user.gnid : gnid;
    let location = window.location.href
    let gnId = location.substring(location.length - 5)
    let response = await Api.getDocumentsList({ fiscal_year_id: fiscalId, gnid: gnId });
    this.setState({ documents: response.response, isLoading: false });
    this._loadTable();
  }

  _handleTextAreaChange = async (e, editedDocument) => {
    let { documents } = this.state;
    documents && documents.length > 0 && documents.map(document => {
      if (document.id === editedDocument.id) {
        document.remarks = e.target.value;
        editedDocument.remarks = e.target.value;
      }
    });
    this.setState({ documents });
    this._loadTable();
    //update category
    // this.setState({activeRemarksText: e.target.value});


    // this.setState({ answerDocs });
    this._sendRemarks(editedDocument);
  }

  async _sendRemarks(document) {
    const { user } = this.props;
    let response = await Api.setDocumentRemarks({
      document_id: document.id,
      fiscal_year_id: user.fiscal_year_id,
      remarks: document.remarks
    });
    if (response.code !== 200) {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  _onRemove = async () => {
    let { activeDocument, documents } = this.state;
    const { user } = this.props;
    this.setState({ isLoading: true });
    let response = await Api.removeMov({ fiscal_year_id: user.fiscal_year_id, document_id: activeDocument.id, path: activeDocument.path });
    if (response.code !== 200) {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      this.setState({ isLoading: false });
    } else {
      documents && documents.length > 0 && documents.map(document => {
        if (document.id === activeDocument.id) {
          document.path = '';
          document.remarks = '';
        }
      });
      this.setState({ documents, isLoading: false });
      this._loadTable();
      this._toggleDeleteModal();
      this.props.onDocumentRemoved(activeDocument)
    }
  }

  _onUploaded = async (uploadedDocument, response) => {
    let { documents } = this.state;
    if (response.code !== 200) {
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.success("कागजात अपलोड सफल।", {
        position: toast.POSITION.TOP_CENTER,
      });
      documents && documents.length > 0 && documents.map(document => {
        if (document.id === uploadedDocument.id) {
          document.path = response.response.path;
        }
      });
      this.setState({ documents });
      this._loadTable();
      this.props.onDocumentUploaded(uploadedDocument, response);
    }


    this.setState({ isLoading: false });

  }

  _closeModal = () => {
    this.props.closeModal();
  }

  _toggleDeleteModal = (d) => {
    if (d) {
      this.setState({
        showDeleteModal: !this.state.showDeleteModal,
        activeDocument: d
      });
    } else {
      this.setState({
        showDeleteModal: !this.state.showDeleteModal,
        activeDocument: null
      });
    }
  }

  _import2Excel = () => {
    // const {data, columns} = this.state;
    // let filteredData = this._getFilteredData();
    // filteredData.map(sData=>{
    //   Object.keys(sData.questions).map((question, qIndex)=>{
    //     sData[sData.categories[categoryId].name] = parseFloat(sData.categories[categoryId].score);
    //   });
    // })
    // let excelColumns = [
    //   <ExcelColumn label="स्थानीय तह" value="name"/>,
    //   <ExcelColumn label="प्रदेश" value="province"/>,
    //   <ExcelColumn label="जिल्ला" value="district"/>,
    //   <ExcelColumn label="जम्मा प्राप्ताङ्क" value="score"/>,
    // ];
    // Object.keys(filteredData[0].categories).map((categoryId, catIndex)=>{
    //   excelColumns.push(<ExcelColumn label={filteredData[0].categories[categoryId].name} value={filteredData[0].categories[categoryId].name} />)
    // });
    // return (
    //   <ExcelFile element={<Button color="primary" style={{marginRight: 10}}>डाउनलोड</Button>}>
    //       <ExcelSheet data={filteredData} name="स्थानीय तह स्कोर टेबल">
    //         {excelColumns}
    //       </ExcelSheet>
    //   </ExcelFile>
    // );
  }

  _getFilteredData = () => {
    const { documents, searchInput, columns } = this.state;
    const searchKeys = ['indicator', 'name', 'remarks', 'uploaded_by'];
    if (searchInput) {
      let filteredData = documents.filter(d => {
        let found = false;
        searchKeys.map(searchKey => {
          if (searchKey === 'indicator') {
            d.questions && d.questions.length > 0 && d.questions.map(question => {
              if (question.indicator.includes(searchInput) || question.name.includes(searchInput)) found = true;
            })
          } else {
            if (d[searchKey].toString().includes(searchInput)) found = true;
          }
        });
        return found;
      });
      return filteredData;
    }
    return documents;

  }

  _onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value });
  }

  render() {
    // const {clickedDocument} = this.props;
    const { documents, columns, isLoading, showDeleteModal } = this.state;
    return (
      <MainLoader active={isLoading}>
        <Modal centered size='lg' isOpen={true} toggle={this._closeModal} className='menu-modal document-modal' id='document-modal'>
          <ModalHeader toggle={this._closeModal}>जरुरी कागजातहरुखोजी</ModalHeader>
          <ModalBody style={{ overflow: 'scroll' }}>
            {/* <ToastContainer /> */}

            {documents && documents.length > 0 && columns &&
              <>
                <div className="control-wrapper">
                  <Input className="search-input" type="text" name="search" placeholder="खोजी" value={this.state.searchInput || ""} onChange={this._onSearchInputChange} />
                  {this._import2Excel()}
                </div>
                <ReactTable
                  data={this._getFilteredData()}
                  columns={columns}
                  defaultPageSize={documents.length}
                  style={{ height: '90%' }}
                  className="-striped -highlight"
                />
              </>
            }
          </ModalBody>
        </Modal>
        <Modal isOpen={showDeleteModal} toggle={this._toggleDeleteModal} className="delete-modal">
          <ModalHeader
            charCode="Y"
            toggle={this.toggle}
          >
            फाईल हटाउनुहोस्
          </ModalHeader>
          <ModalBody>
            <p> यो LISA र FRA को लागि साझा कागजात हो। यसमा गरिएको सम्पादनले LISA र FRA दुवैमा असर गर्छ । के तपाई सम्पादन गर्न चाहनु हुन्छ?</p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="mr-2" onClick={() => { this._onRemove(); }}>फाईल हटाउनुहोस्</Button>
            <Button color="danger" outline onClick={this._toggleDeleteModal}>रद्द गर्नुहोस्</Button>
          </ModalFooter>
        </Modal>
      </MainLoader>
    );
  }
}

const mapStateToProps = ({ auth, modal }) => ({
  user: auth.user,
  modal: modal
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(modalAction.closeModal());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentModal);

