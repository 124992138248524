import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import logo from "../../assets/images/logo.svg";
import { FaSpinner } from "react-icons/fa";
import Api from "../../services/api";
import "./style.css";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    province_id: "",
    district_id: "",
    palika_id: "",
    desired_province_id: "",
  });

  const [error, setError] = useState("");
  const [provinceList, setProvinceList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [palikaList, setPalikaList] = useState([]);
  const [regLoading, setRegLoading] = useState(false);

  const handleFormChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    const data = { ...formData, [key]: value };
    setFormData(data);
  };

  const getProvinceList = async () => {
    const provinceListRes = await Api.getProvinceList();
    if (provinceListRes && provinceListRes.response) {
      setProvinceList(provinceListRes.response);
    }
  };

  const getDistriceList = async (provinceId) => {
    const districtListRes = await Api.getDistrictList(provinceId);
    if (districtListRes && districtListRes.response) {
      setDistrictList(districtListRes.response);
    }
  };

  const getPalikaList = async (districtId) => {
    const palikaListRes = await Api.getPalikaList(districtId);
    if (palikaListRes && palikaListRes.response) {
      setPalikaList(palikaListRes.response);
    }
  };

  const onClickRegister = async () => {
    setRegLoading(true);
    if (formData.name == "") {
      setError("पूरा नाम आवश्यक छ!");
    } else if (formData.email == "") {
      setError("इमेल आवश्यक छ!");
    } else if (formData.phone == "") {
      setError("फोन आवश्यक छ!");
    } else if (formData.province_id == "") {
      setError("प्रदेश आवश्यक छ!");
    } else if (formData.district_id == "") {
      setError("जिल्ला आवश्यक छ!");
    } else if (formData.palika_id == "") {
      setError("स्थानीय तह आवश्यक छ!");
    } else if (formData.desired_province_id == "") {
      setError("चाहेको प्रदेश आवश्यक छ!");
    } else {
      setError("");

      const response = await Api.register(formData);

      if (response.code === 200) {
        toast.success(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setFormData({
          name: "",
          email: "",
          phone: "",
          province_id: "",
          district_id: "",
          palika_id: "",
          desired_province_id: "",
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setRegLoading(false);
    }
  };

  useEffect(() => {
    getProvinceList();
  }, []);

  return (
    <div>
      <div className="register-container">
        <div className="register-wrapper center-container">
          <img src={logo} alt="logo" className="nav-logo-user" />
          <div className="register-header">दर्ता गर्नुहोस्</div>
          <div className="register-form">
            <ToastContainer />
            {error && <div className="text-danger mb-2">{error}</div>}
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label for="name">पुरा नाम</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="पुरा नाम"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label for="email">इमेल</Label>
                  <Input
                    type="text"
                    name="email"
                    placeholder="इमेल"
                    value={formData.email}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="phone">फोन</Label>
                  <Input
                    type="text"
                    name="phone"
                    placeholder="फोन"
                    value={formData.phone}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="province_id">प्रदेश (ठेगाना)</Label>
                  <Input
                    type="select"
                    name="province_id"
                    value={formData.province_id}
                    onChange={(e) => {
                      handleFormChange(e);
                      getDistriceList(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      --प्रदेश छान्नुहोस्--
                    </option>
                    {provinceList &&
                      provinceList.length > 0 &&
                      provinceList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="district_id">जिल्ला (ठेगाना)</Label>
                  <Input
                    type="select"
                    name="district_id"
                    value={formData.district_id}
                    onChange={(e) => {
                      handleFormChange(e);
                      getPalikaList(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      --जिल्ला छान्नुहोस्--
                    </option>
                    {districtList &&
                      districtList.length > 0 &&
                      districtList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="palika_id">स्थानीय तह (ठेगाना)</Label>
                  <Input
                    type="select"
                    name="palika_id"
                    value={formData.palika_id}
                    onChange={handleFormChange}
                  >
                    <option value="" disabled>
                      --स्थानीय तह छान्नुहोस्--
                    </option>
                    {palikaList &&
                      palikaList.length > 0 &&
                      palikaList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>
              <p
                style={{
                  height: "1px",
                  width: "100%",
                  border: "1px solid #D8DAE5",
                  marginTop: "7px",
                }}
              />
              {/* <Col sm={6}>
                <FormGroup>
                  <Label for="name">प्रयोगकर्ता नाम</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="प्रयोगकर्ता नाम"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col> */}
              <Col sm={6}>
                <FormGroup>
                  <Label for="desired_province_id">चाहेको प्रदेश</Label>
                  <Input
                    type="select"
                    name="desired_province_id"
                    value={formData.desired_province_id}
                    onChange={handleFormChange}
                  >
                    <option value="" disabled>
                      --चाहेको प्रदेश छान्नुहोस्--
                    </option>
                    {provinceList &&
                      provinceList.length > 0 &&
                      provinceList.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </Input>
                </FormGroup>
              </Col>

              {/* <Col sm={6}>
                <FormGroup>
                  <Label for="name">पासवर्ड</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="*********"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col>

              <Col sm={6}>
                <FormGroup>
                  <Label for="name">पासवर्ड सुनिश्चित गर्नुहोस</Label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="*********"
                    value={formData.name}
                    onChange={handleFormChange}
                  />
                </FormGroup>
              </Col> */}
            </Row>
            <Button
              className="register-button"
              disabled={regLoading}
              onClick={onClickRegister}
            >
              दर्ता गर्नुहोस् &nbsp;{" "}
              {regLoading && <FaSpinner className="rotating" />}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
