import React, { useCallback, useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import api from "../../services/api";
import topbar from "topbar";
import debounce from "../../utils/debounce";

const Actions = ({ item, fiscal_year_id, gnid, isCompleted }) => {
  const [remarksValue, setRemarksValue] = useState(item.qa_remarks || "");
  const [qa_option, setQaOption] = useState(item.qa_option || 0);
  const [qa_option_text, setQaOptionText] = useState("");

  const [selected, setSelected] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const toggle = () => setOpenModal(!openModal);

  const handleChangeOptions = async (e) => {
    topbar.show();
    const value = e;
    setQaOption(value);
    await submitQAData(value, remarksValue);
    topbar.hide();
    setOpenModal(false);
  };

  const handleRemarksChange = (e) => {
    const value = e.target.value;
    setRemarksValue(value);
    optimizedDebounce(e.target.value);
  };

  const handleRemarksSubmit = async (value) => {
    console.log(qa_option, value, "here");
    topbar.show();
    await submitQAData(qa_option, value);
    topbar.hide();
  };

  const submitQAData = async (option, remarks) => {
    const reqBody = {
      fiscal_year_id: fiscal_year_id,
      gnid: gnid,
      question_id: item.id,
      qa_option: option,
      qa_remarks: remarks,
    };
    const res = await api.submitQA(reqBody);
    if (res && res.code === 200) {
    }
  };

  const optimizedDebounce = useCallback(
    debounce((remarksValue) => handleRemarksSubmit(remarksValue), 1000),
    [qa_option]
  );

  useEffect(async () => {
    const opt = await item.options.find((it) => it.id == qa_option);
    if (opt) {
      setQaOptionText(opt.name);
    }
  }, [qa_option]);

  return (
    <>
      <td>
        <FormGroup className="no-print-internal">
          {/* <Input
            type="select"
            placeholder=""
            disabled={isCompleted}
            name="qa_option"
            id="qa_option"
            value={qa_option}
            onChange={handleChangeOptions}
          >
            <option value={0} disabled selected></option>
            {item.options.length > 0 &&
              item.options.map((option, index) => {
                return (
                  <option
                    key={option.id}
                    value={option.id}
                    style={{ maxWidth: "10px" }}
                  >
                    {option.name}
                  </option>
                );
              })}
          </Input> */}

          {/* filter name of the option drom items */}

          {qa_option_text && (
            <>
              <p
                className="text-justify m-0"
                onClick={() => setOpenModal(true)}
              >
                {qa_option_text}
              </p>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => setOpenModal(true)}
              >
                विकल्प
              </button>
            </>
          )}

          <div className="d-flex justify-content-center">
            {!qa_option_text && (
              <button
                className="btn btn-primary btn-sm mt-2 "
                onClick={() => setOpenModal(true)}
              >
                विकल्प
              </button>
            )}

            <Modal
              isOpen={openModal}
              toggle={toggle}
              size="sm"
              style={{ maxWidth: "500px" }}
              centered
            >
              <ModalHeader toggle={toggle}>विकल्प छान्नुहोस</ModalHeader>
              <ModalBody>
                {item.options.length > 0 &&
                  item.options.map((option, index) => (
                    <div className="d-flex">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          checked={qa_option === option.id}
                          className="mr-2"
                          onChange={() => handleChangeOptions(option.id)}
                        ></input>
                      </div>
                      <div>
                        <p>{option.name}</p>
                      </div>
                    </div>
                  ))}
              </ModalBody>
            </Modal>
          </div>
        </FormGroup>
        {qa_option_text && (
          <p className="border rounded p-2 bg-white print-only-internal">
            {qa_option_text}
          </p>
        )}
      </td>
      <td>
        <div className="no-print-internal">
          <textarea
            disabled={isCompleted}
            className="form-control w-100"
            value={remarksValue}
            name={`remarks${item.id}`}
            onChange={handleRemarksChange}
          />
        </div>
        {remarksValue && (
          <p className="border rounded p-2 bg-white print-only-internal">
            {remarksValue}
          </p>
        )}
      </td>
    </>
  );
};

export default Actions;
