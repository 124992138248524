import React, { useEffect, useState, useMemo } from "react";
import  HeaderTable from "../DataTable/HeaderTable";
import  PaginationComponent from "../DataTable/Pagination";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SearchTable from "../DataTable/Search";
import Api from '../../services/api';
import "./style.css";
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';


export default function DetailTable({comments, title, categories, user}) {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [refresh, setRrefresh] = useState(false);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 20;
    const [data, setData] = useState("");
    console.log(comments)

    const headers = [
        { name: "क्र.स.", field: "id", sortable: false },
        { name: "सूचक", field: "title", sortable: false },
        { name: "पूर्णाङ्क", field: "action", sortable: false },
        { name: "प्राप्ताङ्क", field: "action", sortable: false },
        { name: "कैैफियत", field: "action", sortable: false },
    ];
    const commentsData = useMemo(() => {
        let computedComments = comments.questions;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.question.toLowerCase().includes(search.toLowerCase()) 
                    // ||
                    // comment.img.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting]);

    return (
        <div className="card-layout">
            <ToastContainer />
            <div className="row">
                <div className="col mb-3 col-12 text-center">
                    <div className="row table-heading">
                        <div className="col-md-6">
                            <h1 className="sub-heading">{title}</h1>
                        </div>
                        <div className="col-md-6 d-flex flex-row-reverse dataTables_search">
                            <SearchTable
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                           </div>
                    </div>
                    {commentsData == ''? <h4>विवरण उपलब्ध छैन</h4> : 
                    <table className="table">
                        <HeaderTable
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                        {commentsData.map((comment, i) => (
                                <tr>
                                    <th scope="row" key={i}>
                                        {/* {++i} */}
                                        {comment.indicator}
                                    </th>
                                    <td style={{ textAlign: "left" }}>{comment.question}</td>
                                    <td>{comment.marks}</td>
                                    <td>{comment.score}</td>
                                    <td>{comment.remarks}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>
            
        </div>
    )
}
