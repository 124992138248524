import React from 'react';
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import './styles.css';
export default function Loader({ active, children, text }) {
  return (
    <LoadingOverlay
      active={active}
      spinner={<BounceLoader color='#1f8cd5' />}
      text={text || 'लोड हुँदैछ...'}
      className="main-loader"
    >
      {children}
    </LoadingOverlay>
  )
}