
import React from 'react';
import L from 'leaflet';
import './style.css';
class Map extends React.Component {
  componentDidMount() {
    const { geoJson } = this.props;
    // create map
    const geoJsonStyle = {
      "color": "#c0e9b0",
      "weight": 2,
    };
    this.map = L.map('map', {
      zoom: 16,
      zoomControl: false,
      layers: [

      ]
    });
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    let geojsonLayer = L.geoJSON(geoJson, {
      style: geoJsonStyle
    }).addTo(this.map);
    this.map.fitBounds(geojsonLayer.getBounds());
  }
  render() {
    return <div id="map"></div>
  }
}
export default Map;