import React, { Component } from "react";

import {
    BarChart, CartesianGrid , XAxis, YAxis,
    Tooltip, Legend, Bar, Label, Text, ResponsiveContainer, LabelList
} from 'recharts';
import {changeIndexToNepali} from '../../utils';
class CustomizedAxisTick extends Component{
    render () {
      const {x, y, payload} = this.props;
          
         return <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    }
};
export default class RadarChartComponent extends Component {
    render() {
        const { data } = this.props;
        return (
            <ResponsiveContainer width="100%" height={600} className="responsive">
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis dataKey="question" type="category" 
                        tick={<CustomizedAxisTick />} 
                        height={100} 
                        interval={0}
                    />
                    <YAxis type="number" tickFormatter={(tickItem) => changeIndexToNepali(tickItem)}/>            
                    <Tooltip 
                        // labelFormatter={()=>'abc'} 
                        formatter={(value, name, props) => (`${changeIndexToNepali(value)}%`)}/>
                    <Legend />
                    <Bar dataKey="percentage" fill="#82ca9d" barSize={50}>
                        <LabelList formatter={(value, name, props) => `${changeIndexToNepali(value)}%`} dataKey="percentage" position="top" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}

