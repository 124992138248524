import React, { Component } from 'react';
import './style.css';

class Manual   extends Component {
   
    render() {
        return (
          <div className="manual-wrapper">
            <iframe src="https://docs.google.com/document/d/1FKqUy8O0nnzx6tmEXMX1sfpqHLX32Sz47zGTMu_J7NE/edit?usp=sharing"></iframe>
          </div>
        );
    }
}

export default (Manual);
