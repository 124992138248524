import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import fy from './fy';
import modal from './modal';

export default combineReducers({
    form: formReducer,
    auth,
    fy,
    modal
});
