import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Button, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, NavLink } from 'reactstrap';
import Map from './../../components/Map';
import RadarChart from './../../components/RadarChart';
import './style.css';
import Api from '../../services/api';
import { FaCog, FaPaperclip } from 'react-icons/fa';
import { changeIndexToNepali, changeNepaliIndexToEnglish, colorScale } from '../../utils';
import { Line } from 'rc-progress';
import BarGraph from '../../components/BarChart';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import ReactTable from "react-table";
import 'react-toastify/dist/ReactToastify.css';
import HorizontalSteps from '../../components/HorizontalSteps';
import { NavLink as RRNavLink } from 'react-router-dom';
import { FaCloudUploadAlt } from 'react-icons/fa';
import ReactDropzone from "react-dropzone";
import { FaCheckCircle } from 'react-icons/fa';
import store from '../../store';
import localMapJson from '../../assets/geoJson/local.json';
import * as authAction from '../../actions/authAction';
import MainLoader from '../../components/MainLoader';
import * as modalAction from '../../actions/modalAction';
import GradientLabel from '../../components/GradientLabel';
import DistrictDashboard from './DistrictDashboard';
import LocalDashboard from './LocalDashboard';
import QADashboard from './QADashboard';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                categories: [],
                geoJson: null,
                confirmModal: false,
            }
        };
    }

    render() {
        const { user } = this.props;
        console.log(user, "Users")
        return (
            user.role_level === 'district' ?
                <DistrictDashboard user={user} />
                :
                user.role_level === 'qa' ? <QADashboard user={user} />
                    :
                    <LocalDashboard />

        );
    }
}

const mapStateToProps = ({ auth }) => ({
    user: auth.user
});

const mapDispatchToProps = (dispatch) => ({
    onFormStatusChange: (status) => {
        dispatch(authAction.setUserStatus(status));
    },
    onDocumentsShow: (modalProps) => {
        dispatch(modalAction.processModal(modalAction.SHOW_MODAL, modalAction.ModalTypes.SHOW_DOCUMENT, modalProps));
    },
});

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(Dashboard);