import React, { Component } from "react";
import Steps from 'rc-steps';
import './style.css';


export default class HorizontalSteps extends Component {
    
    render() {
      const {stepData, activeStep } = this.props;
        return (
          <div className="hr-steps">
              <Steps current={activeStep}>
                {stepData.map((sData, index)=>{
                  return (
                    <Steps.Step title={sData.title} key={index} />
                  )
                })}
            </Steps>
          </div>
        );
    }
}

